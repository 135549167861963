import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { PmdTransclude } from './ng-transclude.directive';
import { PmdTabLabel } from './tab-heading.directive';
import { PmdTab} from './tab.directive';
import { PmdTabGroup } from './tab-group.component';
import { PmdTabGroupConfig } from './tabset.config';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PmdTransclude,
    PmdTab,
	  PmdTabGroup,
    PmdTabLabel
  ],
  exports: [
    PmdTab,
	  PmdTabGroup,
    PmdTabLabel,
    PmdTransclude
  ]
})

export class PmdTabsModule {
  static forRoot(): ModuleWithProviders<PmdTabsModule> {
    return {
      ngModule: PmdTabsModule,
      providers: [PmdTabGroupConfig]
    };
  }
}
