import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

import { AuthService, IBreadcrumb, PageMetaService } from '@app/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  breadcrumbs: BehaviorSubject<IBreadcrumb[]> = this.pageMetaService.breadcrumbs;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(private pageMetaService: PageMetaService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      switchMap(async () => this.buildBreadcrumb(this.activatedRoute.root))
    ).subscribe(breadcrumbs => this.breadcrumbs.next(breadcrumbs));

    this.breadcrumbs$ = this.breadcrumbs.asObservable().pipe(
      filter((breadcrumbs: IBreadcrumb[]) => !(breadcrumbs && breadcrumbs.length &&
        (breadcrumbs[0].label.toLowerCase() === 'dashboard' ||
          (typeof breadcrumbs[0]?.url === 'string' && breadcrumbs[0]?.url?.toLowerCase() === 'dashboard') ||
          (typeof breadcrumbs[0]?.url !== 'string' && breadcrumbs[0]?.url[0]?.toLowerCase() === 'dashboard')))),
      map((breadcrumbs: IBreadcrumb[]) => !this.authService.user.isAdmin ? [{ label: 'Dashboard', url: '/' }, ...breadcrumbs] : [...breadcrumbs])
    );
  }

  /**
 * Recursively build breadcrumb according to activated route.
 * @param route
 * @param url
 * @param breadcrumbs
 */
  buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      // label = route.snapshot.params[paramName];
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = url + (path ? '/' + path : '');

    const breadcrumb: IBreadcrumb = {
      label: label,
      url: nextUrl
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadcrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}