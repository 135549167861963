import { Entity, UserRole } from "@app/core";
import { IInvoice, DocumentType, InvoiceStatus } from "@modules/invoice/interfaces";

export class Invoice extends Entity<IInvoice> {
    get invoiceProps() {
        return this.props;
    }

    get id() {
        return this.props.id;
    }

    get sources() {
        return this.props.sources;
    }

    get pageCount() {
        return this.props.sources?.reduce((pageCount, source) => pageCount + (source.pageCount || 1), 0);
    }

    get isMultiPage() {
        return this.pageCount > 1;
    }

    getPage(i: number, j: number) {
        return this.props.sources.slice(0, i).reduce((pageCount, source) => pageCount + source.pageCount, 0) + (this.props.sources[i].pageCount > j ? j : 0);
    }

    getSource(i: number) {
        let page = 0;

        return this.props.sources.findIndex(source => (page += source.pageCount) > i);
    }

    getSourcePage(i: number) {
        return i - this.getPage(this.getSource(i), 0);
    }

    get name() {
        return this.props.sources?.[0]?.name ?? '';
    }

    nameAtIndex(i: number) {
        return this.props.sources?.[i]?.name ?? '';
    }

    nameAtPage(i: number) {
        return this.props.sources?.[this.getSource(i)]?.name ?? '';
    }

    get path() {
        return this.props.sources?.[0]?.path ?? '';
    }

    pathAtIndex(i: number) {
        return this.props.sources?.[i]?.path ?? '';
    }

    pathAtPage(i: number) {
        return this.props.sources?.[this.getSource(i)]?.path ?? '';
    }

    get thumbnail() {
        return this.props.sources?.[0]?.thumbnail || 'assets/images/invoice.jpg';
    }

    thumbnailAtIndex(i: number) {
        return this.props.sources?.[i]?.thumbnail || 'assets/images/invoice.jpg';
    }

    thumbnailAtPage(i: number) {
        return this.props.sources?.[this.getSource(i)]?.thumbnail || 'assets/images/invoice.jpg';
    }

    get type() {
        return this.props.sources?.[0]?.type ?? '';
    }

    typeAtIndex(i: number) {
        return this.props.sources?.[i]?.type ?? '';
    }

    typeAtPage(i: number) {
        return this.props.sources?.[this.getSource(i)]?.type ?? '';
    }

    get size() {
        return this.props.sources?.[0]?.size ?? '';
    }

    sizeAtIndex(i: number) {
        return this.props.sources?.[i]?.size ?? '';
    }

    sizeAtPage(i: number) {
        return this.props.sources?.[this.getSource(i)]?.size ?? '';
    }

    get reasonId() {
        return this.props.reasonId;
    }

    get otherReason() {
        return this.props.otherReason ?? '';
    }

    get reason() {
        return this.props.reason ?? '';
    }

    get originalInvoice() {
        return this.props.originalInvoice ?? '';
    }

    get status() {
        return this.props.invoiceStatus;
    }

    get statusName() {
        let status: string;

        switch (this.props.invoiceStatus) {
            case InvoiceStatus.PROCESSED:
                status = 'Ready for review';
                break;

            case InvoiceStatus.INPROCESS:
                status = 'In Process';
                break;

            case InvoiceStatus.DRAFT:
                status = 'Reviewed Draft';
                break;

            default:
                status = this.props.invoiceStatus;
        }

        return status;
    }

    private statusNameByRole(role: UserRole) {
        const roleStatus = this.props.statuses.find(status => status.role === role);
        let status: string;

        switch (roleStatus?.invoiceStatus) {
            case InvoiceStatus.PROCESSED:
                status = 'Ready for review';
                break;

            case InvoiceStatus.INPROCESS:
                status = 'In Process';
                break;

            case InvoiceStatus.DRAFT:
                status = 'Reviewed Draft';
                break;

            default:
                status = roleStatus?.invoiceStatus;
        }

        return status;
    }

    get statuses() {
        return this.props.statuses;
    }

    get outletStatusName() {
        return this.statusNameByRole(UserRole.Outlet);
    }

    get outletStatus() {
        return this.props.statuses.find(status => status.role === UserRole.Outlet);
    }

    get outletName() {
        return this.props.statuses.find(status => status.role === UserRole.Outlet)?.updatedBy ?? '';
    }

    get ownerStatusName() {
        return this.statusNameByRole(UserRole.Owner);
    }

    get ownerStatus() {
        return this.props.statuses.find(status => status.role === UserRole.Owner);
    }

    get ownerName() {
        return this.props.statuses.find(status => status.role === UserRole.Owner)?.updatedBy ?? '';
    }

    get adminStatusName() {
        return this.statusNameByRole(UserRole.Admin);
    }

    get adminStatus() {
        return this.props.statuses.find(status => status.role === UserRole.Admin);
    }

    get adminName() {
        return this.props.statuses.find(status => status.role === UserRole.Admin)?.updatedBy ?? '';
    }

    private isRoleChecked(role: UserRole) {
        const roleStatus = this.props.statuses.find(status => status.role === role);

        return roleStatus.invoiceStatus === InvoiceStatus.REVIEWED || roleStatus.invoiceStatus === InvoiceStatus.REJECTED;
    }

    get isAdminChecked() {
        return this.isRoleChecked(UserRole.Admin);
    }

    get isOwnerChecked() {
        return this.isRoleChecked(UserRole.Owner);
    }

    get isOutletChecked() {
        return this.isRoleChecked(UserRole.Outlet);
    }

    get statusMessage() {
        let message: string;

        switch (this.props.invoiceStatus) {
            case InvoiceStatus.UPLOADED:
                message = 'This invoices is uploaded';
                break;
            case InvoiceStatus.INPROCESS:
                message = 'This invoices is in process';
                break;
            case InvoiceStatus.FAILED:
                message = 'This invoices is failed to process';
                break;
            case InvoiceStatus.PROCESSED:
                message = 'This invoices is Ready for review';
                break;
            case InvoiceStatus.REVIEWED:
                message = 'This invoices is already reviewed';
                break;
            case InvoiceStatus.REJECTED:
                message = 'This invoices is rejected';
                break;
            case InvoiceStatus.DRAFT:
                message = 'This invoices is reviewed drafted';
                break;
            default: message = '';
        }

        return message;
    }

    get createdBy() {
        return this.props.createdBy;
    }

    get updatedBy() {
        return this.props.updatedBy ?? '';
    }

    private roleUpdatedByMessage(role: UserRole) {
        const roleStatus = this.props.statuses.find(status => status.role === role);
        let message: string;

        switch (roleStatus?.invoiceStatus) {
            case InvoiceStatus.UPLOADED:
                message = roleStatus.updatedBy + ' has uploaded this invoice';
                break;
            case InvoiceStatus.INPROCESS:
                message = roleStatus.updatedBy + ' has uploaded this invoice';
                break;
            case InvoiceStatus.FAILED:
                message = roleStatus.updatedBy + ' has uploaded this invoice';
                break;
            case InvoiceStatus.PROCESSED:
                message = roleStatus.updatedBy + ' has uploaded this invoice and Ready for review';
                break;
            case InvoiceStatus.REVIEWED:
                message = roleStatus.updatedBy + ' has already reviewed this invoice';
                break;
            case InvoiceStatus.REJECTED:
                message = roleStatus.updatedBy + ' has rejected this invoice';
                break;
            case InvoiceStatus.DRAFT:
                message = roleStatus.updatedBy + ' has reviewed drafted this invoice';
                break;
            default: message = '';
        }

        return message;
    }

    get outletUpdatedByMessage() {
        return this.roleUpdatedByMessage(UserRole.Outlet);
    }

    get ownerUpdatedByMessage() {
        return this.roleUpdatedByMessage(UserRole.Owner);
    }

    get adminUpdatedByMessage() {
        return this.roleUpdatedByMessage(UserRole.Admin);
    }

    get createdAt() {
        return this.props.createdAt;
    }

    get updatedAt() {
        return this.props.updatedAt;
    }

    get documentType() {
        return this.props.documentType ?? DocumentType.TAX_INVOICE;
    }

    get number() {
        return this.props.invoiceNo ?? '';
    }

    get date() {
        return this.props.invoiceDate ?? null;
    }

    get buyer() {
        return this.props.buyer;
    }

    get buyerName() {
        return this.props.buyer?.companyName ?? '';
    }

    get seller() {
        return this.props.seller;
    }

    get sellerName() {
        return this.props.seller?.companyName ?? '';
    }

    get companyNames() {
        return this.props.companyNames ?? [];
    }

    get validGstNos() {
        return this.props.validGstNos ?? [];
    }

    get invalidGstNos() {
        return this.props.invalidGstNos ?? [];
    }

    get products() {
        return this.props.products ?? [];
    }

    get productFieldOrders() {
        return this.props.productFieldOrders;
    }

    set productFieldOrders(value) {
        this.props.productFieldOrders = value;
    }

    get productPageLengths() {
        return this.props.productPageLengths;
    }

    get total() {
        return this.props.total;
    }

    get totalFieldOrder() {
        return this.props.totalFieldOrder;
    }

    get tds() {
        return this.props.tds;
    }

    get tcs() {
        return this.props.tcs;
    }

    get suggestedTcs() {
        return this.props.suggestedTcs;
    }

    get reviewFlags() {
        return this.props.reviewFlags;
    }

    get isUploaded() {
        return this.props.invoiceStatus === InvoiceStatus.UPLOADED;
    }

    get isInprocess() {
        return this.props.invoiceStatus === InvoiceStatus.INPROCESS;
    }

    get isFailed() {
        return this.props.invoiceStatus === InvoiceStatus.FAILED;
    }

    get isProcessed() {
        return this.props.invoiceStatus === InvoiceStatus.PROCESSED;
    }

    get isReviewed() {
        return this.props.invoiceStatus === InvoiceStatus.REVIEWED;
    }

    get isRejected() {
        return this.props.invoiceStatus === InvoiceStatus.REJECTED;
    }

    get isDraft() {
        return this.props.invoiceStatus === InvoiceStatus.DRAFT;
    }

    get isDuplicate() {
        return this.props.isDuplicate;
    }

    get isMarkedDuplicate() {
        return this.props.invoiceStatus === InvoiceStatus.DUPLICATE;
    }

    get isTaxInvoice() {
        return this.props.documentType === DocumentType.TAX_INVOICE;
    }

    get isCreditNote() {
        return this.props.documentType === DocumentType.CREDIT_NOTE;
    }
    get creditNoteInvoices() {
        return this.props.creditNoteInvoices ?? [];
    }

    get creditNoteNo() {
        return this.props.creditNoteNo ?? '';
    }

    get statusClass() {
        let statusClass: string;

        switch (this.props.invoiceStatus) {
            case InvoiceStatus.UPLOADED:
                statusClass = 'bg-primary status-uploaded';
                break;
            case InvoiceStatus.INPROCESS:
                statusClass = 'pmd-badge-warning status-in-process';
                break;
            case InvoiceStatus.FAILED:
                statusClass = 'pmd-badge-danger status-failed';
                break;
            case InvoiceStatus.PROCESSED:
                statusClass = 'bg-secondary status-ready-to-reviewed';
                break;
            case InvoiceStatus.REVIEWED:
                statusClass = 'bg-primary status-reviewed';
                break;
            case InvoiceStatus.REJECTED:
                statusClass = 'pmd-badge-dark status-rejected';
                break;
            case InvoiceStatus.DRAFT:
                statusClass = 'pmd-badge-secondary status-reviewed-draft';
                break;
            case InvoiceStatus.DUPLICATE:
                statusClass = 'pmd-badge-warning status-duplicate';
                break;
            default: statusClass = '';
        }

        return statusClass;
    }

    get statusClass2() {
        let statusClass: string;

        switch (this.props.invoiceStatus) {
            case InvoiceStatus.UPLOADED:
                statusClass = 'status-in-process';
                break;
            case InvoiceStatus.INPROCESS:
                statusClass = 'status-in-process';
                break;
            case InvoiceStatus.FAILED:
                statusClass = 'status-failed';
                break;
            case InvoiceStatus.PROCESSED:
                statusClass = 'status-ready-to-reviewed';
                break;
            case InvoiceStatus.REVIEWED:
                statusClass = 'status-reviewed';
                break;
            case InvoiceStatus.REJECTED:
                statusClass = 'status-rejected';
                break;
            case InvoiceStatus.DRAFT:
                statusClass = 'status-reviewed-draft';
                break;
            default: statusClass = '';
        }

        return statusClass;
    }

    get missingBasicInformation() {
        let missingBasicInformations = [];

        if (!this.props?.seller?.companyName) {
            missingBasicInformations.push('Company name');
        }

        if (!this.props?.seller?.gstNo) {
            missingBasicInformations.push('GST number');
        }

        if (!this.props?.seller?.pan) {
            missingBasicInformations.push('PAN');
        }

        return missingBasicInformations.join(', ');
    }

    get missingProductInformation() {
        let missingProductInformations = [];

        if (this.props?.products?.length) {
            const missingProductsWithData = this.props.products.filter(product =>
                !product.description || !product.quantity || !product.rate || !product.taxableAmount || !product.unit
            );

            if (missingProductsWithData.some(product => !product.description)) {
                missingProductInformations.push('Description');
            }

            if (missingProductsWithData.some(product => !product.quantity)) {
                missingProductInformations.push('Quantity');
            }

            if (missingProductsWithData.some(product => !product.rate)) {
                missingProductInformations.push('Rate');
            }

            if (missingProductsWithData.some(product => !product.taxableAmount)) {
                missingProductInformations.push('Taxable amount');
            }

            if (missingProductsWithData.some(product => !product.unit)) {
                missingProductInformations.push('Unit');
            }
        } else {
            missingProductInformations.push('Products');
        }

        return missingProductInformations.join(', ');
    }

    get missingTotalInformation() {
        let missingTotalInformations = [];

        if (!this.isCGSTEmpty() && this.isSGSTEmpty()) {
            missingTotalInformations.push('SGST');
        } else if (this.isCGSTEmpty() && !this.isSGSTEmpty()) {
            missingTotalInformations.push('CGST');
        } else if (this.isCGSTEmpty() && this.isSGSTEmpty() && this.isIGSTEmpty()) {
            missingTotalInformations = ['SGST', 'CGST', 'IGST'];
        }

        if (!this.props.total?.grandTotal) {
            missingTotalInformations.push('Grand total');
        }

        if (!this.props.total?.taxableAmount) {
            missingTotalInformations.push('Taxable amount');
        }

        return missingTotalInformations.join(', ');
    }

    get missingTDSInformation() {
        let missingTDSInformation = [];

        if (this.isEmpty(this.props.tds?.netAmount)) {
            missingTDSInformation.push('Gross Amount');
        }

        if (this.isEmpty(this.props.tds?.nature)) {
            missingTDSInformation.push('Section/Nature');
        }

        if (this.isEmpty(this.props.tds?.rate)) {
            missingTDSInformation.push('TDS Rate');
        }

        if (this.isEmpty(this.props.tds?.amount)) {
            missingTDSInformation.push('TDS Amount');
        }

        if (this.isEmpty(this.props.tds?.payable)) {
            missingTDSInformation.push('Payable Amount');
        }

        return missingTDSInformation.join(', ');
    }

    get missingTCSInformation() {
        let missingTCSInformation = [];

        if (this.isEmpty(this.props.tcs?.taxableAmount)) {
            missingTCSInformation.push('Taxable Amount');
        }

        if (this.isEmpty(this.props.tcs?.tax)) {
            missingTCSInformation.push('Taxes');
        }

        if (this.isEmpty(this.props.tcs?.netAmount)) {
            missingTCSInformation.push('Gross Amount');
        }

        if (this.isEmpty(this.props.tcs?.rate)) {
            missingTCSInformation.push('TCS Rate');
        }

        if (this.isEmpty(this.props.tcs?.amount)) {
            missingTCSInformation.push('TCS Amount');
        }

        if (this.isEmpty(this.props.tcs?.payable)) {
            missingTCSInformation.push('Payable Amount');
        }

        return missingTCSInformation.join(', ');
    }

    get isValid() {
        return !this.missingBasicInformation && !this.missingProductInformation && !this.missingTotalInformation;
    }

    get creditNoteDate() {
        return this.props.creditNoteDate ?? '';
    }

    isEmpty(value) {
        return value === undefined || value === null || (typeof value === 'string' && value.trim() === '');
    }

    isCGSTEmpty() {
        return !this.props.total?.cgst && !this.props.total?.cgst14percent && !this.props.total?.cgst2point5percent && !this.props.total?.cgst6percent && !this.props.total?.cgst9percent;
    }

    isSGSTEmpty() {
        return !this.props.total?.sgst && !this.props.total?.sgst14percent && !this.props.total?.sgst2point5percent && !this.props.total?.sgst6percent && !this.props.total?.sgst9percent;
    }

    isIGSTEmpty() {
        return !this.props.total?.igst && !this.props.total?.igst5percent && !this.props.total?.igst12percent && !this.props.total?.igst18percent && !this.props.total?.igst28percent;
    }

    get reviewedAt() {
        return this.props.reviewedAt ?? null;
    }

    get reviewedBy() {
        return this.props.reviewedBy ?? '';
    }

    get reviewDuration() {
        return this.props.reviewDuration;
    }

    get spreadSheetData() {
        return this.props.spreadSheetData ?? null;
    }

    set spreadSheetData(value) {
        this.props.spreadSheetData = value;
    }

    get lastAccessedFromSheet() {
        return !!this.props.lastAccessedFromSpreadSheet;
    }

    get isFirstReview() {
        return this.props.isFirstReview;
    }

    set lastAccessedFromSheet(value) {
        this.props.lastAccessedFromSpreadSheet = value;
    }

    get companyGstMapping() {
        return this.props.companyGstMapping ?? null;
    }

    get extraProductFieldOrders() {
        return this.props.extraProductFieldOrders ?? null;
    }

    get extraProducts() {
        return this.props.extraProducts ?? null;
    }

    get extraProductPageLengths() {
        return this.props.extraProductPageLengths ?? [];
    }


}