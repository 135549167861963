import { Component } from '@angular/core';
import { AuthService } from '@app/core';

@Component({
  selector: 'app-layout-login',
  templateUrl: './login-layout.component.html',
  host: {}
})
export class LoginLayoutComponent {

  constructor(private authService: AuthService){}
  get currentYear() {
    return (new Date()).getFullYear();
  }

  /**
   * This method will logout the user
   */
  onLoginLinkClick(){
    this.authService.logout();
  }
}