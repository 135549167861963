import { NgModule, ModuleWithProviders } from '@angular/core';

import {  PmdCollapse, PmdCollapseAccordion } from './collapse.directive';

@NgModule({
  declarations: [ PmdCollapse, PmdCollapseAccordion],
  exports: [ PmdCollapse, PmdCollapseAccordion]
})
export class PmdCollapseModule {
  static forRoot(): ModuleWithProviders<PmdCollapseModule> {
    return { ngModule: PmdCollapseModule, providers: [] };
  }
}
