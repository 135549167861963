import { Injectable, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import * as cryptoJS from 'crypto-js';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  sideBarToggle = new EventEmitter<boolean>();
  cancelAPICalls$ = new Subject<void>(); // Subject to cancel API calls being called while user logs out

  constructor(private toastr: ToastrService) { }

  encrypt(message: string) {
    if (!message) return null;

    const key = cryptoJS.enc.Base64.parse(environment.encryptionKey);
    const iv = cryptoJS.enc.Base64.parse(environment.encryptionIv);

    return cryptoJS.AES.encrypt(message.trim(), key, {
      iv,
      mode: cryptoJS.mode.CBC,
      padding: cryptoJS.pad.Pkcs7
    }).toString();
  }

  decrypt(message: string) {
    try {
      if (!message) return null;

      const key = cryptoJS.enc.Base64.parse(environment.encryptionKey);
      const iv = cryptoJS.enc.Base64.parse(environment.encryptionIv);

      return cryptoJS.AES.decrypt(message.trim(), key, {
        iv,
        mode: cryptoJS.mode.CBC,
        padding: cryptoJS.pad.Pkcs7
      }).toString(cryptoJS.enc.Utf8);
    } catch (e) {
      return null;
    }
  }

  successAlert(message?: string) {
    this.toastr.success(message, '', {
      closeButton: true,
      timeOut: 3000,
      toastClass: `pmd-toastr toast-success ngx-toastr`
    });
  }

  errorAlert(message?: string) {
    this.toastr.error(message, '', {
      closeButton: true,
      timeOut: 3000,
      toastClass: `pmd-toastr toast-Error ngx-toastr`
    });
  }

  toggleSideBar() {
    this.sideBarToggle.emit();
  }

  isFieldDirty(field: AbstractControl, isSubmitAttempted: boolean) {
    return field.dirty || field.touched || isSubmitAttempted;
  }

  pmdStatusClass(field: AbstractControl, isSubmitAttempted: boolean) {
    return (field.dirty || field.touched || isSubmitAttempted) &&
      { 'pmd-textfield-is-invalid': field.errors };
  }

  statusClass(field: AbstractControl, isSubmitAttempted: boolean) {
    return (field.dirty || field.touched || isSubmitAttempted) &&
      { 'is-invalid': field.errors };
  }

  hasErrors(field: AbstractControl, isSubmitAttempted: boolean) {
    return (field.dirty || field.touched || isSubmitAttempted) && field.errors;
  }

  private successMessageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  successMessage$ = this.successMessageSubject.asObservable();
  setSuccessMessage(message: string) {
    this.successMessageSubject.next(message);
  }

  /**
    * allow number only
    * @param event 
    * @returns 
    */
  numberOnly(event) {
    if (event.type == 'keyup' && /\D/g.test(event.target.value)) {
      event.target.value = event.target.value.replace(/\D/g, '');
    } else if (event.type == 'paste') {
      let text = (event.originalEvent || event).clipboardData.getData('text/plain');
      let trimText = text.replace(/\D/g, '');
      event.clipboardData.setData('text/plain', (trimText));
      event.preventDefault();
      document.execCommand('insertHTML', false, trimText);
    } else if (event.type == 'keypress' && /\D/g.test(event.target.value) == false) {
      const charCode = (event.which) ? event.which : event.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }

      return true;
    }
  }

  numberOnlyWithDot(event) {
    if (event.type == 'keyup' && /\D/g.test(event.target.value)) {
      event.target.value = event.target.value.replace(/\D/g, '');
    } else if (event.type == 'paste') {
      let text = (event.originalEvent || event).clipboardData.getData('text/plain');
      let trimText = text.replace(/\D/g, '');
      event.clipboardData.setData('text/plain', (trimText));
      event.preventDefault();
      document.execCommand('insertHTML', false, trimText);
    } else if (event.type == 'keypress' && /\D/g.test(event.target.value) == false) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode == 46) {
        return true;
      }

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }

      return true;
    }
  }


  /**
   * The formatNumber function is designed to format a given number, along with an optional currency symbol, and return the formatted value as a 
   * string with comma-separated thousands.
   * @author Darshan Malani - 2nd Aug 2023, 10:AM PM
   * @param
   */
  formatNumber(number: number, isCurrency = false, minimumFractionDigits = 0, maximumFractionDigits = 0, isStrict = false) {
    number = number || 0;

    if (isNaN(number)) {
      return '0';
    }

    const options = {
      minimumFractionDigits: (isStrict && number % 1) ? maximumFractionDigits : minimumFractionDigits,
      maximumFractionDigits
    };

    if (isCurrency) {
      options['style'] = 'currency';
      options['currency'] = 'INR';
    }

    const currencyFormatter = new Intl.NumberFormat('en-IN', options);

    return currencyFormatter.format(number);
  }

  roundDown(number) {
    if (!number) {
      return 0;
    }

    number = Math.floor(number);
    const tenthCount = Math.floor(Math.log10(Math.abs(number)));
    const lowestSameDigit = Math.pow(10, tenthCount);

    return Math.floor(number / lowestSameDigit) * lowestSameDigit;
  }

  /** 
  * So, when you call roundup(345678), it will return 360000. nearest integer greater than or equal to the result.
  * @author Darshan Malani - 2nd Aug 2023, 10:AM PM
  * @param 
  */
  roundUp(number) {
    if (!number) {
      return 0;
    }

    number = Math.floor(number);
    const tenthCount = Math.floor(Math.log10(Math.abs(number)));
    const lowestSameDigit = Math.pow(10, tenthCount);
    const lowestSameMinusOneDigit = Math.pow(10, tenthCount - 1);

    return Math.floor(number / lowestSameDigit) * lowestSameDigit + (Math.ceil(number % lowestSameDigit / lowestSameMinusOneDigit) + 1) * lowestSameMinusOneDigit;
  }

  getDateString = (date: Date) =>
    date.toLocaleDateString('en-US', { month: 'short' }) + ' ' +
    date.toLocaleDateString('en-US', { day: '2-digit' }) + ', ' +
    date.toLocaleDateString('en-US', { year: 'numeric' });

  getTimeIntervals = (startDate, endDate) => {
    // Ensure start is the earlier date;
    if (startDate > endDate) {
      const t = endDate;

      endDate = startDate;
      startDate = t;
    }

    // Copy input start date do don't affect original
    startDate = new Date(startDate);
    let startInterval;
    let endInterval;
    let intervals;

    startInterval = this.getDateString(startDate);
    endInterval = this.getDateString(endDate);
    intervals = [startInterval];

    // List quarters from start to end
    while (startInterval != endInterval) {
      startDate.setDate(startDate.getDate() + 1);
      startInterval = this.getDateString(startDate);
      intervals.push(startInterval);
    }

    return intervals;
  }

  /** 
  * Capitalizes the first letter of each word in the input string.
  * @param {string} string - The input string to be capitalized.
  * @returns {string} The capitalized string.
  * @author Darshan Malani - 10th Aug 2023, 02:18 PM
  */
  capitalize(string: string): string {
    return string && typeof string === 'string' ? string.toLowerCase().replace(/(^\w)|(\s+\w)/g, letter => letter.toUpperCase()) : '' + string;
  }

  getObjectPropertyCount(o) {
    let count = 0;

    for (const k in o) {
      if (o.hasOwnProperty(k)) {
        count++;
      }
    }

    return count;
  }

  isEqualObjects(v1, v2) {
    if (typeof (v1) !== typeof (v2)) {
      return false;
    }

    if (typeof (v1) === 'function') {
      return v1.toString() === v2.toString();
    }

    if (v1 instanceof Object && v2 instanceof Object) {
      if (this.getObjectPropertyCount(v1) !== this.getObjectPropertyCount(v2)) {
        return false;
      }

      for (const k in v1) {
        if (!this.isEqualObjects(v1[k], v2[k])) {
          return false;
        }
      }

      return true;
    } else {
      return v1 === v2;
    }
  }

  shortName(name: string) {
    return name.split(' ').reduce((combinedNamePart, namePart) => combinedNamePart + namePart[0], '')?.toUpperCase();
  }

  toNumber(string, isRoundOffRequired = true, roundOffDigit = 2) {
    string = '' + (string ?? 0);
    const roundOff = Math.pow(10, roundOffDigit);
    let number = string.replace(/\s/g, '')
      .replace(/rs\.?/ig, '')
      .replace(/\.+$/, '')
      .replace(/\(([^)]*[^\d.,)][^)]*)\)/g, '')
      .replace(/(?<=\d+)[^\d.,](?=\d+$)/, '.')
      .replace(/[^\d.]/ig, '')
      .replace(/\.\.+/g, '.')
      .replace(/(?<=\d*)\.(?=\d*\.\d+)/g, '');
    number = string[0] === '-' ? -number : +number;
    return number ? isRoundOffRequired ? Math.round(number * roundOff) / roundOff : number : 0;
  };

  removeEmptyFields(object): any {
    for (let key in object) {
      if (object[key] === null || object[key] === undefined || object[key] === '') {
        delete object[key];
      }
    }

    return object;
  }

  copyToClipboard(text: string) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  escapeRegExp(string: string): string {
    return string.replace(/[-/.*+:?^${}()|[\]\\]/g, '\\$&');
  }

  focusOut(IdSelected) {
    document.getElementById('product-name-' + IdSelected).style.opacity = '0';
    document.querySelectorAll('.khr-select-in-table .ng-value').forEach(element => {
      element.classList.add('showing-lable');
    });
  }

  focusIn(IdSelected) {
    document.getElementById('product-name-' + IdSelected).style.opacity = '1';

    const ngSelectElement = document.getElementById('product-item-' + IdSelected);
    if (ngSelectElement) {
      const ngValueElement = ngSelectElement.querySelector('.showing-lable');
      if (ngValueElement) {
        ngValueElement.classList.remove('showing-lable');
      }
    }
  }

  suggestedProductsUniq(productSItems = []) {
    const exist = new Set();
    const uniqueProductSItems = [];

    const filterProducts = productSItems
      .map(product => product?.trim().replace(/\s+/g, ' ').toLowerCase())
      .filter(product => product);

    filterProducts.forEach(Products => {
      const lowerCaseItem = Products.toLowerCase();
      if (!exist.has(lowerCaseItem)) {
        exist.add(lowerCaseItem);
        uniqueProductSItems.push(Products);
      }
    });

    return uniqueProductSItems;
  }

  humanizeSeconds(s: number) {
    if (s < 0) {
      return '0:00';
    }

    const years = Math.floor(s / 31536000);
    const days = Math.floor((s % 31536000) / 86400);
    const hours = Math.floor((s % 86400) / 3600);
    const minutes = Math.floor((s % 3600) / 60);
    const seconds = s % 60

    return (years ? years + ' years ' : '') +
      (days ? days + ' days ' : '') +
      (hours ? `${hours}`.padStart(2, '0') + ':' : '') +
      `${minutes}`.padStart(2, '0') + ':' +
      `${seconds}`.padStart(2, '0');
  }
}