import { ModuleWithProviders, NgModule } from '@angular/core';
import { ComponentLoaderFactory } from '../component-loader';

import { PositioningService } from '../positioning';
import { PmdDropdownContainer } from './dropdown-container.component';
import { PmdDropdownMenu } from './dropdown-menu.directive';
import { PmdDropdownToggle } from './dropdown-toggle.directive';
import { PmdDropdownConfig } from './dropdown.config';

import { PmdDropdown } from './dropdown.directive';
import { PmdDropdownState } from './dropdown.state';


@NgModule({
  declarations: [
    PmdDropdownMenu,
    PmdDropdownToggle,
    PmdDropdownContainer,
    PmdDropdown,
  ],
  exports: [
    PmdDropdownMenu,
    PmdDropdownToggle,
    PmdDropdown,
  ],
  entryComponents: [PmdDropdownContainer]
})
export class PmdDropdownModule {
  static forRoot(config?: any): ModuleWithProviders<PmdDropdownModule> {
    return {
      ngModule: PmdDropdownModule,
      providers: [
        ComponentLoaderFactory,
        PositioningService,
        PmdDropdownState,
        {
          provide: PmdDropdownConfig,
          useValue: config ? config : { autoClose: true }
        }
      ]
    };
  }
}
