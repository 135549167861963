import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { AppGlobals } from '@app/app.global';
import { AuthService, SharedService, User } from '@app/core';
import { SidebarService } from './sidebar.service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { VoucherService } from '@app/modules/voucher/services'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isWithTags: any = false;
  isTaggingPending: boolean = false;
  url$: Observable<string> = new Observable<string>();
  currentURL: string;
  profileSidebarUrl: string[] = ['/profile', '/entity', '/voucher'];
  loggedinUser: User;
  showPushToPetpooja: boolean = false;
  isSidebarOpen = false;

  constructor(private sharedService: SharedService,
    public appGlobals: AppGlobals,
    public authService: AuthService,
    private sidebarService: SidebarService,
    private voucherService: VoucherService,
    private router: Router,
    private cdRef: ChangeDetectorRef) {
    this.loggedinUser = this.authService.user;
    this.url$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url));
    this.url$.subscribe((resp) => {
      this.currentURL = resp;
    });
  }

  ngOnInit(): void {
    if (!this.authService.user?.isAdmin) {
      this.sidebarService.getProductTags({
        isWithTags: this.isWithTags
      }).subscribe(
        res => {
          this.isTaggingPending = !!res?.count;
        }
      );
      this.getCompanyDetails();
    } else {
      this.showPushToPetpooja = true;
    }

    const sideBarFlag = JSON.parse(localStorage.getItem('isSidebarOpen'));
    this.isSidebarOpen = sideBarFlag;
    console.log("sideBarFlag", sideBarFlag);

    if (this.isSidebarOpen) {
      const sideBarElement = document.getElementById('leftSidebarMenu');

      if (sideBarElement) {
        document.body.classList.add('pmd-body-open');
        sideBarElement.classList.add('pmd-sidebar-open');
        this.cdRef.detectChanges();
      }
    }
  }

  getCompanyDetails() {
    this.voucherService.getCompanyDetails().subscribe(res => {
      if (res["restaurantSyncCode"]) {
        this.showPushToPetpooja = true;
      }
    })
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.sharedService.sideBarToggle.emit(this.isSidebarOpen);
    localStorage.setItem('isSidebarOpen', JSON.stringify(this.isSidebarOpen));
  }

  // hideSideBar(){
  //   if(this.isSidebarOpen){
  //     this.sharedService.sideBarToggle.emit();
  //     this.isSidebarOpen = false;
  //   }
  // }
}