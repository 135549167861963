import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { PmdBar } from './bar.component';
import { PmdProgressbar } from './progressbar.component';
import { PmdProgressbarConfig } from './progressbar.config';

@NgModule({
  imports: [CommonModule],
  declarations: [PmdBar, PmdProgressbar],
  exports: [PmdBar, PmdProgressbar]
})
export class PmdProgressbarModule {
  static forRoot(): ModuleWithProviders<PmdProgressbarModule> {
    return { ngModule: PmdProgressbarModule, providers: [PmdProgressbarConfig] };
  }
}
