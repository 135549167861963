import {
  AfterViewInit,
  Component,
  ChangeDetectionStrategy,
  Renderer2,
  ElementRef
} from '@angular/core';
import { PmdTooltipConfig } from './tooltip.config';
import { isBs3 } from '../utils';

@Component({
  selector: 'pmd-tooltip-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]':
      '"tooltip " + "pmd-tooltip bs-tooltip-" + placement + " " + containerClass',
      '[attr.id]': 'this.id',
    role: 'tooltip fade',
    '[class.show]': 'pmdtooltipClass',
 //   '[class.pmd-tooltip]': '!isBs3',
  },
  template: `
    <div class="tooltip-arrow"></div>
    <div class="tooltip-inner"><ng-content></ng-content></div>
    `
})
export class PmdTooltipContainer implements AfterViewInit {
	
  classMap: any;
  placement: string;
  containerClass: string;
  animation: boolean;
  arrow:string;
  pmdtooltipClass:boolean = false
  id: string;
  
  get isBs3(): boolean {
    return isBs3();
  }

  constructor(config: PmdTooltipConfig, private _renderer: Renderer2, private _elementRef: ElementRef,) {
    Object.assign(this, config);
  }

  ngAfterViewInit(): void {
    this.classMap = { in: false, fade: false };
    this.classMap[this.placement] = true;
    this.classMap[`tooltip-${this.placement}`] = true;

    this.classMap.in = true;
    if (this.animation) {
      this.classMap.fade = true;
    }

    if (this.arrow) {
      this._elementRef.nativeElement.classList.add('pmd-arrow');
    }  
    
    setTimeout( () =>{
      this._elementRef.nativeElement.classList.add('show');
    },10)

    if (this.containerClass) {
      this.classMap[this.containerClass] = true;
    }
  }
}
