import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@core/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(e => {
            if ([401, 403].includes(e.status) && this.authService.isLoggedIn) {
                // auto logout if 401 or 403 response returned from api
                if (request.url.indexOf('logout') === -1) {
                    this.authService.logout();
                } else {
                    this.authService.clearCredentials(true, this.authService.user.isDemo);
                }
            } else {
                const error = e?.error?.message || e.statusText;

                console.log(e);

                return throwError(error);
            }
        }));
    }
}