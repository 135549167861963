<div class="pmd-content errorpage error-blank-page d-flex justify-content-center align-items-center">
    <div class="text-center error-page-w">
        <div class="mb-3 pmd-avatar pmd-avatar-dark pmd-avatar-xl d-inline-flex"
            aria-hidden="true">
            <svg version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 40 40"
                enable-background="new 0 0 40 40"
                xml:space="preserve">
                <g>
                    <path d="M34.4,2.9H5.6C3.6,2.9,2,4.5,2,6.5v27c0,2,1.6,3.6,3.6,3.6h28.7c2,0,3.6-1.6,3.6-3.6v-27C38,4.5,36.4,2.9,34.4,2.9z
		 M26.4,6.2h5.6c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9h-5.6c-0.5,0-0.9-0.4-0.9-0.9S25.9,6.2,26.4,6.2z M15,5.8
		c0.7,0,1.3,0.6,1.3,1.3S15.7,8.4,15,8.4s-1.3-0.6-1.3-1.3C13.7,6.4,14.3,5.8,15,5.8z M11.2,5.8c0.7,0,1.3,0.6,1.3,1.3
		s-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3C9.9,6.4,10.5,5.8,11.2,5.8z M7.4,5.8c0.7,0,1.3,0.6,1.3,1.3S8.1,8.4,7.4,8.4
		S6.1,7.8,6.1,7.1C6.1,6.4,6.7,5.8,7.4,5.8z M35.7,33.5c0,0.8-0.6,1.4-1.4,1.4H5.6c-0.8,0-1.4-0.6-1.4-1.4V11.4h31.5L35.7,33.5
		L35.7,33.5z" />
                    <circle cx="15.7"
                        cy="20.2"
                        r="2.1" />
                    <circle cx="24.3"
                        cy="20.2"
                        r="2.1" />
                    <path d="M15.1,27.3c-0.3,0.5-0.2,1.3,0.3,1.6c0.5,0.3,1.3,0.2,1.6-0.3c0.6-0.9,1.7-1.4,3-1.4s2.4,0.5,3,1.4
		c0.3,0.5,1.1,0.7,1.6,0.3c0.5-0.3,0.7-1.1,0.3-1.6c-1-1.6-2.9-2.5-5-2.5S16.1,25.7,15.1,27.3z" />
                </g>
            </svg>
        </div>
        <h1 tabindex="0">Oops! Page not found</h1>
        <p class="mb-4"
            tabindex="0">
            The page you are looking for might have been removed, had its name changes, or is temporarily unavailable.
        </p>
        <button type="button"
            pmdRipple
            color="primary"
            pmd-btn-raised
            aria-label="Press enter back to home"
            [routerLink]="homePath">
            Back to Home
        </button>
    </div>
</div>