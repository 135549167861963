<!-- days calendar view mode -->
<div class="pmd-datepicker" [ngClass]="containerClass" *ngIf="viewMode | async" [class.pmd-daterangepicker]="(daysCalendar | async)?.length > 1">
  <!--custom dates or date ranges picker-->
  
  <div class="pmd-datepicker-custom-range" *ngIf="customRanges?.length > 0">
    <pmd-custom-date-view
      [selectedRange]="chosenRange"
      [ranges]="customRanges"
      (onSelect)="setRangeOnCalendar($event)">
    </pmd-custom-date-view>
  </div>

  <div class="pmd-datepicker-container"
    [@datepickerAnimation]="animationState"
    (@datepickerAnimation.done)="positionServiceEnable()">
    <!--calendars-->
    <div class="pmd-calendar-container" [ngSwitch]="viewMode | async" role="application">
      <!--days calendar-->
      <div *ngSwitchCase="'day'" class="pmd-media-container">
        <pmd-days-calendar-view
          *ngFor="let calendar of daysCalendar | async"
          [class.pmd-datepicker-multiple]="(daysCalendar | async)?.length > 1"
          [calendar]="calendar"
          [options]="options | async"
          (onNavigate)="navigateTo($event)"
          (onViewMode)="setViewMode($event)"
          (onHover)="dayHoverHandler($event)"
          (onHoverWeek)="weekHoverHandler($event)"
          (onSelect)="daySelectHandler($event)">
        </pmd-days-calendar-view>
      </div>

      <!--months calendar-->
      <div *ngSwitchCase="'month'" class="pmd-media-container">
        <pmd-month-calendar-view
          *ngFor="let calendar of monthsCalendar | async"
          [class.pmd-datepicker-multiple]="(daysCalendar | async)?.length > 1"
          [calendar]="calendar"
          (onNavigate)="navigateTo($event)"
          (onViewMode)="setViewMode($event)"
          (onHover)="monthHoverHandler($event)"
          (onSelect)="monthSelectHandler($event)">
        </pmd-month-calendar-view>
      </div>

      <!--years calendar-->
      <div *ngSwitchCase="'year'" class="pmd-media-container">
        <pmd-years-calendar-view
          *ngFor="let calendar of yearsCalendar | async"
          [class.pmd-datepicker-multiple]="(daysCalendar | async)?.length > 1"
          [calendar]="calendar"
          (onNavigate)="navigateTo($event)"
          (onViewMode)="setViewMode($event)"
          (onHover)="yearHoverHandler($event)"
          (onSelect)="yearSelectHandler($event)">
        </pmd-years-calendar-view>
      </div>
    </div>

    <!--applycancel buttons-->
    <div class="pmd-datepicker-buttons" *ngIf="false">
      <button class="btn btn-success" type="button">Apply</button>
      <button class="btn btn-default" type="button">Cancel</button>
    </div>

    <!-- <pmd-footer-buttons></pmd-footer-buttons> -->
    <!-- <ng-content select="pmd-footer-buttons"></ng-content> -->

    <div class="pmd-datepicker-buttons" *ngIf="showTodayBtn">
      <div class="btn-today-wrapper"
           [class.today-left]="todayPos === 'left'"
           [class.today-right]="todayPos === 'right'"
           [class.today-center]="todayPos === 'center'">
        <button class="btn btn-success" (click)="setToday()">Today</button>
      </div>
    </div>

  </div>
</div>
