<ng-container *ngIf="path">
  <div class="d-flex justify-content-between khr-page-title align-items-center mt-2 pt-1">
    <span class="invoiceSource"
      container="body"
      *ngIf="name"
      [pmdTooltip]="name">
      {{name}}
    </span>
    <div class="text-end position-relative m-1"
      [ngClass]="{'invoice-review-btn-wrap-zoom': name, 'btn-wrap-zoom': !name}">

      <button class="me-1 d-none d-xl-flex"
        *ngIf="showFullScreenBtn"
        type="button"
        (click)="toggleFullScreen()"
        color="dark"
        pmdRipple
        pmd-btn
        size="xs">
        <i class="material-icons">
          {{ isFullScreen ? 'close_fullscreen' : 'open_in_full' }}
        </i>
      </button>
      <button id="zoom-out"
        class="me-1"
        type="button"
        color="dark"
        pmdRipple
        pmd-btn
        size="xs"
        (click)="zoomOut()">
        <i class="material-icons">remove</i>
      </button>
      <button class="me-1"
        id="zoom-in"
        type="button"
        pmd-btn
        pmdRipple
        color="dark"
        pmdRipple
        size="xs"
        (click)="zoomIn()">
        <i class="material-icons">add</i>
      </button>
      <button class="me-1"
        pmd-btn
        pmdRipple
        color="dark"
        pmdRipple
        size="xs"
        (click)="rotate()">
        <i class="material-icons">rotate_right</i>
      </button>
      <button class="me-1"
        pmd-btn
        pmdRipple
        color="dark"
        pmdRipple
        size="xs"
        (click)="antiRotate()">
        <i class="material-icons">rotate_left</i>
      </button>
      <button class="me-1"
        *ngIf="downlaodVisible"
        type="button"
        (click)="downloadFilesAsZip()"
        pmd-btn
        color="dark"
        pmdRipple
        class="bg-dark border-dark"
        size="xs">
        <i class="material-icons text-white">download</i>
      </button>
      <button *ngIf="showCloseIcon"
        pmd-btn
        color="dark"
        pmdRipple
        size="xs"
        aria-label="Close"
        (click)="onClose()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <div class="pdf-image-container bg-light"
    (mouseleave)="leave()">
    <small *ngIf="isDuplicate"
      class="on-badge status-duplicate duplicate-indicator pmd-badge badge rounded-pill border bg-white">
      Duplicate
    </small>
    <pdf-viewer [src]="path"
      [render-text]="true"
      [original-size]="true"
      [fit-to-page]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [rotation]="rotation"
      [zoom]="zoom"
      [show-all]="!page && page !== 0"
      [page]="page" 
      [style.left.px]="posX"
      [style.top.px]="posY"
      class="w-100 pdf_viewer_block">
    </pdf-viewer>
  </div>
</ng-container>