
export enum DocumentType {
    TAX_INVOICE = 'Tax Invoice',
    CREDIT_NOTE = 'Credit Note'
};

export enum FieldType {
    PURCHASE = 'Purchase',
    EXPENSE = 'Expense'
};

export enum InvoiceStatus {
    UPLOADED = 'Uploaded',
    INPROCESS = 'Inprocess',
    PROCESSED = 'Processed',
    FAILED = 'Failed',
    REVIEWED = 'Reviewed',
    REJECTED = 'Rejected',
    DRAFT = 'Draft',
    DUPLICATE = 'Duplicate',
    ALL = 'All'
};

interface IProduct {
    __type: FieldType;
    isTdsApplicable?: boolean;
    isTcsApplicable?: boolean;
    srNo: string;
    hsn: string;
    description: string;
    quantity: string;
    rate: string;
    unit: string;
    taxableAmount: string;
    totalAmount: string;
    extras: { name: string, value: string }[]
};

interface ITotal {
    taxableAmount: string;
    totalTaxGst: string;
    cgst: string;
    cgst2point5percent: string;
    cgst6percent: string;
    cgst9percent: string;
    cgst14percent: string;
    sgst: string;
    sgst2point5percent: string;
    sgst6percent: string;
    sgst9percent: string;
    sgst14percent: string;
    igst: string;
    igst5percent: string;
    igst12percent: string;
    igst18percent: string;
    igst28percent: string;
    discount: string;
    roundOff: string;
    tds: string;
    tcs: string;
    cess: string;
    otherCharges: string;
    grandTotal: string;
    freightCharges: string;
    vat: string;
    excise: string;
    extras: { name: string, value: string }[]
};

export interface IInvoice {
    id: string;
    sources: {
        name: string;
        path: string;
        thumbnail: string;
        type: ('application/pdf' | 'image/png' | 'image/jpg');
        size: number;
        pageCount: number;
    }[];
    createdBy: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
    reasonId?: string;
    reason: string;
    otherReason: string;
    invoiceStatus: InvoiceStatus;
    statuses?: any[];
    documentType?: DocumentType;
    invoiceNo?: string;
    originalInvoice?: string;
    invoiceDate?: Date;
    buyer?: { gstNo: string, companyName: string };
    seller?: { gstNo: string, companyName: string, pan?: string };
    companyNames?: string[];
    validGstNos?: string[];
    invalidGstNos?: string[];
    companyGstMapping?: Object;
    products?: IProduct[];
    productFieldOrders?: { field: string, name: string, isMandatory: boolean }[][];
    productPageLengths?: number[];
    total?: ITotal;
    totalFieldOrder?: { field: string, name: string, isMandatory: boolean, type?: FieldType }[];
    tds?: {
        netAmount: number;
        rate: string;
        nature: string;
        amount: number;
        payable: number;
    };
    tcs?: {
        taxableAmount: number;
        tax: number;
        otherCharge: number;
        netAmount: number;
        rate: string;
        nature: string;
        amount: number;
        payable: number;
    };
    suggestedTcs?: any;
    reviewFlags?: {
        seller: boolean,
        invoiceNo: boolean,
        invoiceDate: boolean,
        products: boolean,
        grandTotal: boolean
    };
    creditNoteInvoices?: {
        id: string;
        invoiceDate: string;
        invoiceNo: string
    }[];
    creditNoteNo?: string;
    isDuplicate?: boolean;
    creditNoteDate?: Date;
    reviewedAt?: Date;
    reviewedBy?: string;
    reviewDuration?: { invoice: number; products: number; tds?: number; tcs?: number; total: number; };
    spreadSheetData?: {
        products?: IProduct[];
        productFieldOrders?: { field: string, name: string, isMandatory: boolean, isSystemGenerated?: boolean }[][];
        total?: ITotal;
        totalFieldOrder?: { field: string, name: string, isMandatory: boolean, type?: FieldType }[];
        productPageLengths?: number[];
        tds?: {
            netAmount: number;
            rate: string;
            nature: string;
            amount: number;
            payable: number;
        };
        tcs?: {
            taxableAmount: number;
            tax: number;
            otherCharge: number;
            netAmount: number;
            rate: string;
            nature: string;
            amount: number;
            payable: number;
        };
    };
    lastAccessedFromSpreadSheet: boolean;
    isFirstReview: boolean;
    extraProductFieldOrders?: { field: string, name: string, isMandatory: boolean, isSystemGenerated?: boolean }[][];
    extraProducts?: IProduct[];
    extraProductPageLengths?: number[];
};

export enum IpTracingSubject {
    DELETE_INVOICE = 'Delete Invoice'
};