import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from '@app/app.global';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(
    private http: HttpClient,
    private appGlobals: AppGlobals
  ) { }

  generateInvoiceVoucherNumber(body) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.metadata}invoice-voucher`, body);
  }

  getCompanyDetails(company = null) {
    if (company) {
      return this.http.get(`${this.appGlobals.apiUrl.metadata}company/details?company=${company}`);
    } else {
      return this.http.get(`${this.appGlobals.apiUrl.metadata}company/details`);
    }
  }
}
