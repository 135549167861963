export const environment = {
  production: false,
  name: 'staging',
  encryptionKey: 'Tjcjavr2TPMiixGSUrbqySC7qagyY0IWlIrev4RZnvsEJPBjFoRsDpBmuD2WxxKL',
  encryptionIv: 'sbWIbGr25EOItOzCz2mlliu7ZEgRaPu7klKEEFELJjQ=',
  authentication: {
    xApiKey: 'qxYFvkMoQy7a2oEERaaBd4DYtA0wLZebMkUJZR47',
    url: 'https://3qrwybtakk.execute-api.ap-south-1.amazonaws.com',
    path: '/staging/authentication/'
  },
  dashboard: {
    xApiKey: 'qxYFvkMoQy7a2oEERaaBd4DYtA0wLZebMkUJZR47',
    url: 'https://zlx4n7hui3.execute-api.ap-south-1.amazonaws.com',
    path: '/staging/dashboard/'
  },
  invoice: {
    xApiKey: 'qxYFvkMoQy7a2oEERaaBd4DYtA0wLZebMkUJZR47',
    bypassUrl: 'https://stagingapi.kharcha.com:3001',
    bypassPath: '/api/web/v1/',
    url: 'https://n4zcrlk9g7.execute-api.ap-south-1.amazonaws.com',
    path: '/staging/invoice/'
  },
  metadata: {
    xApiKey: 'qxYFvkMoQy7a2oEERaaBd4DYtA0wLZebMkUJZR47',
    url: 'https://5fyke5rxs0.execute-api.ap-south-1.amazonaws.com',
    path: '/staging/metadata/'
  }
};