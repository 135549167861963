/**
 * Propeller Pro v1.0.0 (https://pro.propeller.in/)
 * Copyright 2016-2018 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

import {
	Component,
	ElementRef,
	Input,
	Renderer2,
	OnInit,
} from '@angular/core';

/**
 * List of classes to add to pmd-btn instances based on host attributes to
 * style as different variants.
 */
const BUTTON_HOST_ATTRIBUTES = [
  'pmd-btn',
  'pmd-btn-flat',
  'pmd-btn-raised',
  'pmd-btn-fab',
  'pmd-btn-outline',
  'pmd-btn-rounded'
];

/**
 * Propeller button.
 */
@Component({
  selector: `[pmd-btn], [pmd-btn-flat], [pmd-btn-raised],
             [pmd-btn-fab], [pmd-btn-outline], [pmd-btn-rounded]`,
  exportAs: 'pmdButton',
  host: {
 	'class': 'btn',
  },
  templateUrl: 'button.html',
})

export class PmdButton implements OnInit{
    
	@Input() color: string = '';
	@Input() size: string = '';
	
	constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
	
	ngOnInit(){
		const colorClass = 'btn-' + this.color;
		const sizeClass = 'btn-' + this.size;
		const outLineClass = 'btn-outline-' + this.color;
		for (const attr of BUTTON_HOST_ATTRIBUTES) {
		  if (this._hasHostAttributes(attr)) {
			  if (attr !== ('pmd-btn-outline')) {
				  (this.elementRef.nativeElement as HTMLElement).classList.add(attr);
				  if (this.color !== '') {
					  this.renderer.addClass(this._getHostElement(), colorClass);
				  }
			  } else {
					(this.elementRef.nativeElement as HTMLElement).classList.remove(colorClass);
				  (this.elementRef.nativeElement as HTMLElement).classList.add(outLineClass);
			  }
		  }
		}
		if (this.size) {
		  this.renderer.addClass(this._getHostElement(), sizeClass);
		}
	}

 	_hasHostAttributes(...attributes: string[]) {
    	return attributes.some(attribute => this._getHostElement().hasAttribute(attribute));
	 }
	 
	_getHostElement() {
    	return this.elementRef.nativeElement;
	 }
	 
}