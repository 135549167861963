/**
 * Propeller Pro v1.0.0 (https://pro.propeller.in/)
 * Copyright 2016-2018 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { PmdRippleEffect } from './ripple-effect';

@NgModule({
  imports: [CommonModule],
  declarations: [
	  PmdRippleEffect,
  ],
  exports: [
	  PmdRippleEffect,
  ]
})
export class PmdRippleEffectModule {
  static forRoot(): ModuleWithProviders<PmdRippleEffectModule> {
    return { ngModule: PmdRippleEffectModule };
  }
}
