<input #input
  type="radio"
  class="form-check-input"
  [ngClass]="{ 
      'filled-in': filledIn || rounded}"
  [id]="id"
  [checked]="state == 'checked'"
  [disabled]="state == 'disabled'"
  [required]="required"
  [indeterminate]="indeterminate"
  [attr.name]="name"
  [attr.value]="value"
  [tabIndex]="tabIndex"
  (click)="onCheckboxClick($event)"
  (change)="onCheckboxChange($event)"
  (focus)="onRadioFocus($event)">
<label class="form-check-label"
  [ngClass]="{
      'pmd-checkbox-ripple-effect':pmdClass,
      'label-before': checkboxPosition === 'right', 
      'checkbox-rounded': rounded,
      'disabled': disabled }"
  [attr.for]="id">
  <ng-content></ng-content>
</label>