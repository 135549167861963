import { EventEmitter, Injectable, OnDestroy, Output } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, fromEvent } from "rxjs";

import { AppGlobals } from "@app/app.global";

interface IReviewDuration {
    section: string;
    duration: number;
    lastActive: Date;
}

@Injectable({
    providedIn: 'root'
})
export class ReviewDurationService implements OnDestroy {
    @Output() isIdleEvent: EventEmitter<boolean> = new EventEmitter(false);
    private events: string[] = ['keyup', 'click', 'wheel', 'mousemove'];
    private acitivityTimer = {};
    private reviewerDurations: BehaviorSubject<IReviewDuration[]>;
    public reviewerDurations$: Observable<IReviewDuration[]>;
    subscriptions: Subscription[] = [];

    constructor(private appGlobals: AppGlobals) {
        this.reviewerDurations = new BehaviorSubject<IReviewDuration[]>([]);
        this.reviewerDurations$ = this.reviewerDurations.asObservable();
    }

    public setUp(section: string, duration: number) {
        const sectionElement = section === 'invoice' ? document : document.getElementById(section);

        if (!sectionElement) {
            return;
        }

        const reviewDurations = this.reviewerDurations.value;

        if (reviewDurations.every(reviewDuration => reviewDuration.section !== section)) {
            reviewDurations.push({
                section,
                duration,
                lastActive: new Date()
            });
            this.reviewerDurations.next(reviewDurations);
        }

        this.subscriptions.push(...this.events.map(event => fromEvent(sectionElement, event).subscribe(_ => this.recordReviewDuration(section, duration))));
    }

    private recordReviewDuration(section: string, duration: number) {
        const reviewDurations = this.reviewerDurations.value;
        const reviewDurationIndex = reviewDurations.findIndex(reviewDuration => reviewDuration.section === section);

        if (reviewDurationIndex > -1) {
            reviewDurations[reviewDurationIndex].lastActive = new Date();
        } else {
            reviewDurations.push({
                section,
                duration,
                lastActive: new Date()
            });
        }

        this.startActivityTracker(section);
    }

    private startActivityTracker(section: string) {
        if (section in this.acitivityTimer) {
            return;
        }

        const reviewDurations = this.reviewerDurations.value;
        const reviewDurationIndex = reviewDurations.findIndex(reviewDuration => reviewDuration.section === section);

        this.acitivityTimer[section] = setInterval(() => {
            reviewDurations[reviewDurationIndex].duration += 5;
            this.reviewerDurations.next(reviewDurations);

            if ((new Date()).getTime() - reviewDurations[reviewDurationIndex].lastActive.getTime() > (section === 'invoice' ? this.appGlobals.idleReviewTimeMS : 1000)) {
                this.stopActivityTracker(section);

                if (section === 'invoice') {
                    this.isIdleEvent.emit(true);
                }
            }
        }, 5);
    }

    public stopActivityTracker(section: string) {
        if (this.acitivityTimer[section]) {
            clearInterval(this.acitivityTimer[section]);
            delete this.acitivityTimer[section];
        }
    }

    public unsubscribe() {
        Object.keys(this.acitivityTimer)?.map(async section => this.stopActivityTracker(section));
        this.subscriptions?.map(subscription => subscription.unsubscribe());
        this.reviewerDurations.next([]);
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }
}