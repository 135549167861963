import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { User } from '@core/entities';
import { IUser, UserRole } from '@core/interfaces';
import { AppGlobals } from '@app/app.global';
import { EntityService } from './entity.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _tokenSubject: BehaviorSubject<string>;
  private _loggedInUserSubject: BehaviorSubject<User>;
  private _expirySubject: BehaviorSubject<Date>;

  constructor(private http: HttpClient,
    private router: Router, private appGlobals: AppGlobals,
    private entityService: EntityService,
    private sharedService: SharedService) {
    this._tokenSubject = new BehaviorSubject<string>(null);
    this._loggedInUserSubject = new BehaviorSubject<User>(null);
    this._expirySubject = new BehaviorSubject<Date>(new Date());

    this.setCredentials();
  }

  public get token(): string {
    return this._tokenSubject.value;
  }

  public get isLoggedIn(): boolean {
    const isUserExists = !!localStorage.getItem('kharchaUser') || !!sessionStorage.getItem('kharchaUser');
    const isTokenExists = !!localStorage.getItem('kharchaToken') || !!sessionStorage.getItem('kharchaToken');

    return !!this.token && !!this.user && isUserExists && isTokenExists;
  }

  public get user(): User {
    return this._loggedInUserSubject.value;
  }

  public set user(user: User) {
    if (!user || user.id !== this.user.id) {
      this.logout();
      return;
    }

    if (localStorage.getItem('kharchaUser')) {
      localStorage.setItem('kharchaUser', this.sharedService.encrypt(JSON.stringify(user)));
    } else {
      sessionStorage.setItem('kharchaUser', this.sharedService.encrypt(JSON.stringify(user)));
    }

    this._loggedInUserSubject.next(user);
  }

  public get expiry(): Date {
    return this._expirySubject.value;
  }

  refreshToken() {
    if (localStorage.getItem('temporaryUser')) {
      return null;
    }

    if (!this.token || !this.user || !this.expiry) {
      this.clearCredentials(false);
      return null;
    }

    this.startTokenTimer(this.expiry);
  }

  login(username: string, password: string, recaptchaToken: string, rememberMe: boolean) {
    const credentials = this.sharedService.encrypt(JSON.stringify({ username, password }));

    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}login`, { credentials, recaptchaToken })
      .pipe(
        map(res => this.authenticate({ ...res, user: JSON.parse(this.sharedService.decrypt(res.user)), expiry: new Date(res.expiry) }, rememberMe)),
        switchMap(() => this.user.isOwner && this.user.isOnboarded ? this.entityService.fetchEntities() : of(null)),
        tap(entities => entities ? this.entityService.setInitialEntities(entities, this.user) : of(null))
      );
  }

  isEmailExists(email: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.authentication}is-email-exists`, {
      params: { email }
    });
  }

  isMobileExists(mobile: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.authentication}is-mobile-exists`, {
      params: { mobile }
    });
  }

  signup(user: { name: string, email: string, mobile: string, password: string }) {
    const credentials = this.sharedService.encrypt(JSON.stringify(user));

    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}signup`, { credentials }).pipe(
      map(res => ({ ...res, user: new User(JSON.parse(this.sharedService.decrypt(res.user))) }))
    );
  }

  reSendOtp(token: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.authentication}resend-otp`, {
      headers: { Authorization: `Bearer ${token}` }
    }).pipe(
      map(res => ({ ...res, user: new User(JSON.parse(this.sharedService.decrypt(res.user))) }))
    );
  }

  verifyOtp(otp: string, token: string) {
    const credentials = this.sharedService.encrypt(JSON.stringify({ otp }));

    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}verify-otp`, { credentials }, {
      headers: { 'Authorization': `Bearer ${token}` }
    }).pipe(
      map(res => ({ ...res, user: new User(JSON.parse(this.sharedService.decrypt(res.user))) }))
    );
  }

  getDomains(token: string): Observable<any[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.authentication}get-domain-list`, { headers: { 'Authorization': `Bearer ${token}` } });
  }

  addCompanyDetails(token: string, company) {
    const credentials = this.sharedService.encrypt(JSON.stringify(company));
    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}add-company-details`, { credentials }, { headers: { Authorization: `Bearer ${token}` } });
  }

  getPANDetail(pan, token: string) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}get-pan-detail`, { pan }, { headers: { 'Authorization': `Bearer ${token}` } });
  }

  forgotPassword(username: string) {
    const credentials = this.sharedService.encrypt(JSON.stringify({ username }));

    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}forgot-password`, { credentials }).pipe(
      map(res => ({ ...res, user: new User(JSON.parse(this.sharedService.decrypt(res.user))) }))
    );
  }

  reSendForgotOtp(token: string) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.authentication}resend-forgot-otp`, {
      headers: { Authorization: `Bearer ${token}` }
    }).pipe(
      map(res => ({ ...res, user: new User(JSON.parse(this.sharedService.decrypt(res.user))) }))
    );
  }

  verifyForgotOtp(otp: string, token: string) {
    const credentials = this.sharedService.encrypt(JSON.stringify({ otp }));

    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}verify-forgot-otp`, { credentials }, {
      headers: { 'Authorization': `Bearer ${token}` }
    }).pipe(
      map(res => ({ ...res, user: new User(JSON.parse(this.sharedService.decrypt(res.user))) }))
    );
  }

  createNewPassword(password: string, token: string) {
    const credentials = this.sharedService.encrypt(JSON.stringify({ password: password }));

    return this.http.post<any>(`${this.appGlobals.apiUrl.authentication}create-new-password`, { credentials }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
  }

  clearCredentials(navigateToLogin = true, isDemoUser = false) {
    this._tokenSubject.next(null);
    this._loggedInUserSubject.next(null);
    this._expirySubject.next(null);
    this.stopTokenTimer();
    localStorage.clear();
    sessionStorage.clear();

    if (navigateToLogin) {
      this.router.navigate(['/' + (isDemoUser ? 'demo-login' : 'login')]);
    }
  }

  logout() {
    const isDemoUser = this.user?.isDemo;

    this.clearCredentials(true, isDemoUser);
  }

  // helper methods
  private tokenTimeout//: NodeJS.Timeout;

  private startTokenTimer(expiry: Date) {
    this.tokenTimeout = setTimeout(() => this.logout(), expiry.getTime() - Date.now());
  }

  private stopTokenTimer() {
    clearTimeout(this.tokenTimeout);
  }


  rememberCredentials(res: { user: User, token: string; expiry: Date; }, rememberMe?: boolean) {
    if (rememberMe && rememberMe.toString() === 'true' || localStorage.getItem('kharchaToken')) {
      localStorage.setItem('kharchaToken', this.sharedService.encrypt(res.token));
    } else {
      sessionStorage.setItem('kharchaToken', this.sharedService.encrypt(res.token));
    }

    if (rememberMe && rememberMe.toString() === 'true' || localStorage.getItem('kharchaUser')) {
      localStorage.setItem('kharchaUser', this.sharedService.encrypt(JSON.stringify(res.user)));
    } else {
      sessionStorage.setItem('kharchaUser', this.sharedService.encrypt(JSON.stringify(res.user)));
    }

    if (rememberMe && rememberMe.toString() === 'true' || localStorage.getItem('kharchaExpiry')) {
      localStorage.setItem('kharchaExpiry', this.sharedService.encrypt(res.expiry.toString()));
    } else {
      sessionStorage.setItem('kharchaExpiry', this.sharedService.encrypt(res.expiry.toString()));
    }
  }

  private setCredentials() {
    let token = this.sharedService.decrypt(localStorage.getItem('kharchaToken') ?
      localStorage.getItem('kharchaToken') :
      sessionStorage.getItem('kharchaToken'));

    this._tokenSubject.next(token);

    let user = this.sharedService.decrypt(localStorage.getItem('kharchaUser') ?
      localStorage.getItem('kharchaUser') :
      sessionStorage.getItem('kharchaUser'));

    this._loggedInUserSubject.next(user ? new User(JSON.parse(user)?.props) : null);

    let expiry = this.sharedService.decrypt(localStorage.getItem('kharchaExpiry') ?
      localStorage.getItem('kharchaExpiry') :
      sessionStorage.getItem('kharchaExpiry'));

    this._expirySubject.next(expiry ? new Date(expiry) : null);
  }

  authenticate(res: { user: IUser, token: string; expiry: Date; }, rememberMe?: boolean) {
    const user: User = new User(res.user);

    this._tokenSubject.next(res.token);
    this._loggedInUserSubject.next(user);
    this._expirySubject.next(res.expiry);

    this.startTokenTimer(res.expiry);
    this.rememberCredentials({ ...res, user }, rememberMe);

    if (user.isOnboarded && [UserRole.Owner, UserRole.Outlet, UserRole.Admin].includes(user.role)) {
      window.location.reload();
    }

    return user;
  }
}