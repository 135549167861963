import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

export interface BsCustomDates {
  label: string;
  value: Date | Date[];
}

@Component({
  selector: 'pmd-custom-date-view',
  template: `
    <div class="pmd-datepicker-predefined-btns">
      <h6 class="dropdown-header">Date selection</h6>
      <a class="dropdown-item" *ngFor="let range of ranges"
        (click)="selectFromRanges(range)"
        [class.selected]="range.value === selectedRange">
        {{ range.label }}
      </a>
      <a class="dropdown-item"
        (click)="selectFromRanges(customRange)"
        [class.selected]="!checkRange()">
        Custom Range
      </a>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BsCustomDatesViewComponent {
  @Input() ranges: BsCustomDates[];
  @Input() selectedRange: Date[];
  @Input() rangesTitle = '';
  @Output() onSelect = new EventEmitter<BsCustomDates>();

  customRangetitle: string;

  customRange: any = null;

  selectFromRanges(range: BsCustomDates) {
    this.onSelect.emit(range);
  }

  checkRange() {
    return this.ranges ? this.ranges.filter(range => range.value === this.selectedRange).length > 0 : false;
  }

}
