import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppGlobals } from '@app/app.global';

import { AuthService } from '@core/services';

@Injectable()
export class NoAuthGuard implements CanActivate {
    loading = false;

    constructor(private router: Router,
        private authService: AuthService,
        private appGlobals: AppGlobals) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.isLoggedIn || !this.authService.user.isOnboarded) {
            return true;
        } else {
            // logged in so redirect to home page
            this.router.navigate(this.authService.user.isAdmin ? this.appGlobals.adminPath : this.appGlobals.homePath)
            return false;
        }
    }
}