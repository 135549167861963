import { NgModule, ModuleWithProviders } from '@angular/core';

import { PmdButtonCheckbox } from './button-checkbox.directive';
import { PmdButtonRadio } from './button-radio.directive';
import { PmdButtonRadioGroup } from './button-radio-group.directive';

@NgModule({
  declarations: [PmdButtonCheckbox, PmdButtonRadio, PmdButtonRadioGroup],
  exports: [PmdButtonCheckbox, PmdButtonRadio, PmdButtonRadioGroup]
})
export class PmdButtonToggleModule {
  static forRoot(): ModuleWithProviders<PmdButtonToggleModule> {
    return { ngModule: PmdButtonToggleModule, providers: [] };
  }
}
