/**
 * Propeller Pro v1.0.0 (https://pro.propeller.in/)
 * Copyright 2016-2018 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

import {
	Directive,
	ElementRef,
	Renderer2
} from '@angular/core';

/**
* Ripple Effect 
*/

@Directive({
	selector: '[pmdRipple]',
	host: {
		'(click)': 'setOnClick($event)'
	}
})

export class PmdRippleEffect {

	ClassName = {
		ANIMATE: "animate",
		Pmd_Ripple:"pmd-ripple-effect"
	};
	span = this._renderer.createElement('span');

	constructor(private el: ElementRef, private _renderer: Renderer2) {	}

	setOnClick(event: any): void {
		if (!this.el.nativeElement.classList.contains('disabled')) {
			const button = this.el.nativeElement;
			if (button.querySelector('.ink')) {
				this._renderer.removeAttribute(button.querySelector('.ink'), 'style');
				button.querySelector('.ink').remove();
			}
			if (!button.classList.contains(this.ClassName.Pmd_Ripple)) {
				button.className += ' '+this.ClassName.Pmd_Ripple;
			}
			this.span.className += ' ink';
			const ripple = button.appendChild(this.span);
			ripple.classList.remove(this.ClassName.ANIMATE);
			if (!button.querySelector('.ink').offsetWidth && !button.querySelector('.ink').offsetHeight) {
				let d = Math.max(button.offsetWidth, button.offsetHeight);
				this._renderer.setStyle(button.querySelector('.ink'), 'width', d + 'px');
				this._renderer.setStyle(button.querySelector('.ink'), 'height', d + 'px');
				let x = event.clientX - button.getBoundingClientRect().left - d / 2;
				let y = event.clientY - button.getBoundingClientRect().top - d / 2;
				this._renderer.setStyle(button.querySelector('.ink'), 'top', y + 'px');
				this._renderer.setStyle(button.querySelector('.ink'), 'left', x + 'px');
				this._renderer.addClass(button.querySelector('span'), this.ClassName.ANIMATE);
			}
			this.removeRipple(button, this._renderer);
		}
	}

	removeRipple(button: any, renderer: any) {
		setTimeout(() => {
			if (button.querySelector('.ink')) {
				renderer.removeAttribute(button.querySelector('.ink'), 'style');
				button.querySelector('.ink').remove();
			}
		}, 250);
	}
}