import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppGlobals } from '@app/app.global';

import { AuthService } from '@core/services';

@Injectable()
export class OwnerEntityGuard implements CanActivate {
    constructor(private router: Router,
        private authService: AuthService,
        private appGlobals: AppGlobals) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.user?.isOwner || this.authService.user?.isOutlet) {
            return true;
        } else {
            this.router.navigate(this.appGlobals.homePath);
            return false;
        }
    }
}