import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { PageMetaService, EntityService, AuthService, User } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  user: User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    public entityService: EntityService,
    private pageMetaService: PageMetaService,
    private authService: AuthService,
  ) {
    this.user = this.authService.user;
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const route = this.getChild(this.route);

      route.data.subscribe(
        data => {
          this.titleService.setTitle('Invoice' + (data.title ? ' | ' + data.title : ''));
          this.pageMetaService.title.next(data.title);
          this.pageMetaService.backLink.next(data.backLink);
        }
      );
    });

    if (this.authService.isLoggedIn && this.user.isAdmin) {
      this.entityService.setCompanies().subscribe();
    }
  }

  getChild(route: ActivatedRoute) {
    return route.firstChild ? this.getChild(route.firstChild) : route;
  }
}