import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PmdTableHeaderFixed } from './fixed-header.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PmdTableHeaderFixed
  ],
  exports: [
    PmdTableHeaderFixed
  ]
})
export class PmdFixedHeaderModule {}
