import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { PmdDropdownState } from './dropdown.state';

@Directive({
  selector: '[pmdDropdownMenu]',
  exportAs: 'pmdDropdownMenu'
})
export class PmdDropdownMenu {
  constructor(
    _state: PmdDropdownState,
    _viewContainer: ViewContainerRef,
    _templateRef: TemplateRef<any>
  ){
    _state.resolveDropdownMenu({
      templateRef: _templateRef,
      viewContainer: _viewContainer
    });
  }
}
