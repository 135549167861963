import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from "@angular/common/http";

import { AppGlobals } from '@app/app.global';

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  constructor(private http: HttpClient,
    private appGlobals: AppGlobals) {
  }

  getProductTags(filters: HttpParams | { [params: string]: string | string[] } = {}): Observable<any> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.invoice}product-tags`, { params: filters });
  }
}