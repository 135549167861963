import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CaseService } from '@core/services';

@Injectable()
export class CaseInterceptor implements HttpInterceptor {
    constructor(private caseService: CaseService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({
            params: this.caseService.toSnakeCaseKeys(request.params),
            body: request.body instanceof FormData ? request.body : this.caseService.toSnakeCaseKeys(request.body)
        });

        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse &&
                (!event.headers.get('content-type') ||
                    event.headers.get('content-type').indexOf('application/json') !== - 1)) {
                return event.clone({ body: this.caseService.toCamelCaseKeys(event.body) });
            } else {
                return event;
            }
        }));
    }
}