import { EventEmitter, Injectable } from '@angular/core';
import { pmdComponentRef } from '../component-loader';

@Injectable()
export class PmdDropdownState {
  direction: 'down' | 'up' = 'down';
  autoClose: boolean;
  onChange = new EventEmitter<boolean>();
  isDisabledChange = new EventEmitter<boolean>();
  toggleClick = new EventEmitter<boolean>();
  counts: number;
  /**
   * Content to be displayed as popover.
   */
  dropdownMenu: Promise<pmdComponentRef<any>>;
  resolveDropdownMenu: (componentRef: pmdComponentRef<any>) => void;

  constructor() {
    this.dropdownMenu = new Promise(resolve => {
      this.resolveDropdownMenu = resolve;
    });
  }

}
