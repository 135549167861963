import { Component } from '@angular/core';

@Component({
  selector: 'pmd-calendar-layout',
  template: `
    <!-- current date, will be added in nearest releases -->
    <pmd-current-date title="hey there" *ngIf="false"></pmd-current-date>

    <!--navigation-->
    <div class="pmd-datepicker-head">
      <ng-content select="pmd-datepicker-navigation-view"></ng-content>
    </div>

    <div class="pmd-datepicker-body">
      <ng-content></ng-content>
    </div>

    <!--timepicker-->
    <pmd-timepicker *ngIf="false"></pmd-timepicker>
  `
})
export class BsCalendarLayoutComponent {}
