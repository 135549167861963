/**
* ------------------------------------------------------------------------
* Propeller Input Component
* ------------------------------------------------------------------------
*/

import {
  Component,
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

/**
* ------------------------------------------------------------------------
* Floating Label
* ------------------------------------------------------------------------
*/
@Directive({
  selector: '[pmd-floating-label], [pmdFloatingLabel]',
  host: {
    'class': 'pmd-textfield-floating-label'
  }
})
export class PmdFloatingLabel {}

/**
* ------------------------------------------------------------------------
* Textfield
* ------------------------------------------------------------------------
*/
@Directive({
  selector: 'pmd-textfield, [pmd-textfield], [pmdTextfield]',
  exportAs: 'pmdTextInput',
  host: {
    'class': 'pmd-textfield'
  }
})
export class PmdTextfield {}


/**
* ------------------------------------------------------------------------
* Input Group Icon
* ------------------------------------------------------------------------
*/
@Directive({
  selector: '[pmd-input-group-icon], [PmdInputGroupIcon]',
  host: {
    'class': 'pmd-input-group-icon'
  }
})
export class PmdInputGroupIcon {}

/**
* ------------------------------------------------------------------------
* Form Group
* ------------------------------------------------------------------------
*/

@Component({
  selector: 'pmd-form-group',
  templateUrl: 'pmd-form-group.html',
  styleUrls: ['input.component.css'],	
  host: {
	  'class': 'form-group pmd-textfield',
  },
  encapsulation: ViewEncapsulation.None,
})
export class PmdFormGroup {}


/**
* ------------------------------------------------------------------------
* Input Group
* ------------------------------------------------------------------------
*/
@Component({
  selector: 'pmd-input-group',
  templateUrl: 'pmd-input-group.html',
  host: {
	  'class': 'input-group pmd-input-group',
  },
  encapsulation: ViewEncapsulation.None,
})
export class PmdInputGroup {}

@Component({
  selector: 'pmd-input-group-outline',
  templateUrl: 'pmd-input-group.html',
  host: {
	  'class': 'input-group pmd-input-group-outline',
  },
  encapsulation: ViewEncapsulation.None,
})
export class PmdInputGroupOutline {}

@Component({
  selector: 'pmd-input-group-filled',
  templateUrl: 'pmd-input-group.html',
  host: {
	  'class': 'input-group pmd-input-group-filled',
  },
  encapsulation: ViewEncapsulation.None,
})
export class PmdInputGroupFilled {}


/**
* ------------------------------------------------------------------------
* Input Group Prepend
* ------------------------------------------------------------------------
*/

@Component({
  selector: 'pmd-input-group-prepend',
  templateUrl: 'pmd-input-group-addon.html',
  host: {
	  'class': 'input-group-prepend',
  },
  encapsulation: ViewEncapsulation.None,
})
export class PmdInputGroupPrepend {}


/**
* ------------------------------------------------------------------------
* Input Group Prepend
* ------------------------------------------------------------------------
*/

@Component({
  selector: 'pmd-input-group-append',
  templateUrl: 'pmd-input-group-addon.html',
  host: {
	  'class': 'input-group-append',
  },
  encapsulation: ViewEncapsulation.None,
})
export class PmdInputGroupAppend {}

/**
* ------------------------------------------------------------------------
* Input Group Prepend
* ------------------------------------------------------------------------
*/

@Component({
  selector: 'pmd-input-group-text',
  templateUrl: 'pmd-input-group-text.html',
  host: {
	  'class': 'input-group-text',
  },
  encapsulation: ViewEncapsulation.None,
})
export class PmdInputGroupText {}

