import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PmdCheckbox } from './checkbox.component';

export { PmdCheckboxValueAccessor, PmdCheckbox } from './checkbox.component';

@NgModule({
  declarations: [
    PmdCheckbox
  ],
  exports: [
    PmdCheckbox
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class PmdCheckboxModule { }
