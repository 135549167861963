import { 
  ChangeDetectorRef, 
  Directive, 
  forwardRef,
  Provider } from '@angular/core';
import { 
  ControlValueAccessor, 
  NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Provider Expression that allows pmdBtnRadioGroup to register as a ControlValueAccessor.
 */
export const PmdRadioControlValueAccessor: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PmdButtonRadioGroup),
  multi: true
};

/** Exclusive selection button radio group. */
@Directive({
  selector: '[pmdBtnRadioGroup]',
  providers: [PmdRadioControlValueAccessor]
})

export class PmdButtonRadioGroup implements ControlValueAccessor {
  onChange = Function.prototype;
  onTouched = Function.prototype;

  get value() {
    return this._value;
  }
  set value(value: string | null) {
    this._value = value;
  }

  private _value: string | null;

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(value: string | null): void {
    this._value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }
  
}
