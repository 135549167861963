import { Injectable } from '@angular/core';

@Injectable()
export class PmdTabGroupConfig {
  /** provides default navigation context class: 'tabs' or 'pills' */
  type = 'tabs';
  isKeysAllowed = true;
  /** aria label for tab list */
  ariaLabel = 'Tabs';
}
