import { Injectable } from '@angular/core';

/** Default values provider for tooltip */
@Injectable()
export class PmdTooltipConfig {
  /** tooltip placement, supported positions: 'top', 'bottom', 'left', 'right' */
  placement = 'bottom';
  /** array of event names which triggers tooltip opening */
  triggers = 'hover focus';
  /** a selector specifying the element the tooltip should be appended to. Currently only supports "body" */
  container: 'body';
}