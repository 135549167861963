import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { PmdAccordion} from './accordion.component';
import { PmdAccordionConfig } from './accordion.config';
import { PmdAccordionItem } from './accordion-group.component';
import { PmdAccordionItemHeader } from './accordion-header.component';
import { PmdCollapseModule } from '../collapse';

@NgModule({
  imports: [CommonModule, PmdCollapseModule],
  declarations: [PmdAccordion, PmdAccordionItemHeader, PmdAccordionItem],
  exports: [PmdAccordion, PmdAccordionItem, PmdAccordionItemHeader]
})

export class PmdAccordionModule {
  static forRoot(): ModuleWithProviders<PmdAccordionModule> {
    return { ngModule: PmdAccordionModule, providers: [PmdAccordionConfig] };
  }
}
