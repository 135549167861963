import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { AppGlobals } from '@app/app.global';
import { OEntity } from "@modules/entity/entities";
import { IEntity } from "@modules/entity/interfaces";

@Injectable({
  providedIn: "root",
})
export class NavbarService {
  constructor(private http: HttpClient,
    private appGlobals: AppGlobals) {
  }

  /**
   * Retrieves active sync codes from the server. This method sends an HTTP GET request to the server to fetch active sync codes based on the specified parameters such as status, pageSize, and page.
   * @author Darshan Malani - 6th Sep 2023, 02:00 PM
   * @param
   */
  getSyncCode(filters: { [params: string]: string | string[] } = {}): Observable<any[]> {
    return this.http.get<any[]>(`${this.appGlobals.apiUrl.invoice}sync-codes`, { params: filters });
  }

  /**
   * Creates a sync code on the server.
   * This method sends an HTTP POST request to the server to create a sync code
   * based on the provided filter.
   * @author Darshan Malani - 8th Sep 2023, 1:00 PM
   * @param filter The request filter containing data for creating the sync code.
   * @returns {Observable<any>} An observable that emits the response from the server.
   */
  createSyncCode(filter: any) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}create-sync-codes`, filter);
  }

  /**
 * deactive a sync code on the server.
 * This method sends an HTTP POST request to the server to deactive a sync code
 * based on the provided filter.
 * @author Darshan Malani - 8th Sep 2023, 2:00 PM
 * @param filter The request filter containing data for deactive the sync code.
 */
  deactivateSyncCode(filter: any) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}deactivate-sync-code`, filter);
  }

  getEntities(): Observable<OEntity[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.metadata}entity/list`).pipe(
      map((entities: IEntity[]) => entities.map((entity: IEntity) => new OEntity(entity)))
    );
  }
}