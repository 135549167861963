import { Injectable } from '@angular/core';
import JSZip from 'jszip/dist/jszip';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ZipDownloadService {
  constructor() { }

  // Function to download images and PDFs as ZIP
  async downloadFilesAsZip(sources) {
    const zip = new JSZip();
    const invoiceFolder = zip.folder('invoice');

    if (sources.length === 1) {
      this.downloadFile(sources[0].path, sources[0].name)
      return;
    }

    const filePromises = await sources.map(async (file, index) => {

      try {
        const response = await fetch(decodeURIComponent(file.path));
        const blob = await response.blob();
        const extension = file.name.split('.').pop()?.toLowerCase();
        const fileName = `${file.name.replace(/\.[^/.]+$/, '')}-${new Date().getTime()}.${extension}`;
        invoiceFolder?.file(fileName, blob);

      } catch (err) {
        console.log('Fail:: download', err.message);
      }
    });

    await Promise.all(filePromises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `invoice-${new Date().getTime()}.zip`);
      });
    });
  }

  async downloadFile(url, name) {
    try {
      const response = await fetch(decodeURIComponent(url));
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const extension = name.split('.').pop()?.toLowerCase();
      const fileName = `${name.replace(/\.[^/.]+$/, '')}-${new Date().getTime()}.${extension}`;

      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

    } catch (err) {
      console.log('Error downloading::', err.message)
    }
  }

  getFileNameAndType(url: string): { fileName: string; fileType: string } {
    const fileNameWithParams = url.split('/').pop() || '';
    const fileName = fileNameWithParams.split('?')[0];
    const extension = fileName.split('.').pop()?.toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];

    let fileType: string;
    if (extension === 'pdf') {
      fileType = 'pdf';
    } else if (imageExtensions.includes(extension || '')) {
      fileType = 'image';
    } else {
      fileType = '';
    }

    return { fileName, fileType };
  }

}
