import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppGlobals } from '@app/app.global';

@Injectable({
  providedIn: 'root'
})
export class SyncCodeService {
  constructor(private http: HttpClient,
    private appGlobals: AppGlobals) { }

  getSyncCode(filters: { [params: string]: string | string[] } = {}): Observable<any[]> {
    return this.http.get<any[]>(`${this.appGlobals.apiUrl.invoice}sync-codes`, { params: filters });
  }

  createSyncCode(filter: any) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}create-sync-codes`, filter);
  }

  deactivateSyncCode(filter: any) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.invoice}deactivate-sync-code`, filter);
  }
}