<!-- Navbar Toggler -->
<div class="d-flex align-items-center"
	[ngClass]="{'justify-content-between w-100' : loggedinUser.isAdmin}">
	<button pmd-btn
		pmdRipple
		pmd-btn-fab
		pmd-btn-flat
		size="xs"
		(click)="showSideBar()"
		class="pmd-sidebar-toggle d-xl-none pmd-btn-rounded ms-2"
		aria-label="Press enter to open the left sidebar navigation">
		<i class="material-symbols-outlined md-dark">menu</i>
	</button>
	<!-- Navbar brand -->


	<!-- Condition will be changed once APIs changes are up on dev (Multiple Outlet) -->
	<div class="align-items-center d-none d-md-flex me-4">
		<nav aria-label="breadcrumb mb-0"
			class="breadcrumb mb-0"
			*ngIf="dashboardText">
			<ol class="breadcrumb pmd-breadcrumb">
				<li [class]="'breadcrumb-item'"
					aria-current="page">
					<a [routerLink]="['/dashboard']" class="text-decoration-none">
						Dashboard
					</a>
				</li>
			</ol>
		</nav>
		<app-breadcrumb></app-breadcrumb>
	</div>

	<form class="form-inline w-100"
		autocomplete="off"
		*ngIf="loggedinUser.isAdmin && !['/push-to-petpooja', '/tally-config'].includes(router.url)"
		class="d-none d-lg-block">
		<div class="d-flex align-items-center">
			<div class="khr-header-entity-dropdown mb-3 mb-lg-0 px-lg-0 me-3">
				<pmd-textfield-outline pmd-floating-label
					class="h-100">
					<ng-select [searchable]="true"
						[clearable]="false"
						labelForId="company-name"
						[markFirst]="true"
						[items]="parentCompanyList"
						[formControl]="selectedCompany"
						bindLabel="name"
						bindValue="id"
						appearance="outline"
						class="pmd-select pmd-select-sm pmd-select-outline me-2 w-100"
						placeholder="Company Name"
						name="companyNameFilter"
						ng-click="active!= ng-select-opened">
					</ng-select>
				</pmd-textfield-outline>
			</div>
			<div class="khr-header-entity-dropdown khr-entity-dd header_entity-dd">
				<pmd-form-group pmd-floating-label
					class="h-100">
					<pmd-input-group-outline pmd-floating-label>
						<ng-select groupBy="group"
							id="select-entity"
							name="select-entity"
							[items]="entities"
							[formControl]="selectedEntities"
							appearance="outline"
							[multiple]="true"
							pmdTextField
							placeholder="Select Entity"
							[clearable]="false"
							[clearSearchOnAdd]="true"
							bindLabel="name"
							class="pmd-select pmd-select-outline"
							(close)="onEntitySelectionClose()"
							(change)="onEntityChange()"
							(open)="onSelectionOpen()"
							[closeOnSelect]="false">
							<ng-template ng-option-tmp
								let-item="item">
								<div class="pmd-checkbox-option"><span class="material-icons pmd-option-check"></span>
									{{item.name}}</div>
							</ng-template>
							<ng-template ng-option-tmp
								let-item="item">
								<div class="pmd-checkbox-option"><span class="material-icons pmd-option-check"></span>
									{{item.name}}</div>
							</ng-template>

							<ng-template ng-multi-label-tmp
								let-items="items"
								let-clear="clear">
								<div class="ng-value"
									*ngFor="let item of items | slice:0:1">
									<span class="ng-value-label">{{item.name}}</span>
								</div>
								<div class="ng-value ts-more"
									*ngIf="items.length >1 && items.length != entities.length">
									<span class="ng-value-label">+{{items.length - 1}} more...</span>
								</div>
							</ng-template>

						</ng-select>
					</pmd-input-group-outline>
				</pmd-form-group>
			</div>
		</div>
	</form>
</div>
<pmd-navbar-right-icon class="d-flex align-items-center pf-navbar-right-icon gap-2">
	<div class="khr-entity-dd navbar-multi-select-entity d-flex align-items-center gap-2"
		*ngIf="loggedinUser.isOwner">
		<label for="entities"
			class="d-none d-lg-block">Entity</label>
		<pmd-input-group-outline>
			<ng-select groupBy="group"
				id="select-entity"
				name="select-entity"
				[formControl]="selectedEntities"
				appearance="outline"
				[items]="entities"
				[multiple]="true"
				pmdTextField
				placeholder="Select Entity"
				[clearable]="false"
				[clearSearchOnAdd]="true"
				bindLabel="name"
				class="pmd-select pmd-select-outline"
				(close)="onEntitySelectionClose()"
				(change)="onEntityChange()"
				(open)="onSelectionOpen()"
				[closeOnSelect]="false">
				<ng-template ng-option-tmp
					let-item="item">
					<div class="pmd-checkbox-option"><span class="material-icons pmd-option-check"></span>
						{{item.name}}</div>
				</ng-template>
				<ng-template ng-option-tmp
					let-item="item">
					<div class="pmd-checkbox-option"><span class="material-icons pmd-option-check"></span>
						{{item.name}}</div>
				</ng-template>

				<ng-template ng-multi-label-tmp
					let-items="items"
					let-clear="clear">
					<div class="ng-value"
						*ngFor="let item of items | slice:0:1">
						<span class="ng-value-label">{{item.name}}</span>
					</div>
					<div class="ng-value ts-more"
						*ngIf="items.length >1 && items.length != entities.length">
						<span class="ng-value-label">+{{items.length - 1}} more...</span>
					</div>
				</ng-template>

			</ng-select>
		</pmd-input-group-outline>
	</div>
	<div class="divider-vertical d-none d-lg-block"
		*ngIf="loggedinUser.isOwner"></div>
	<div *ngIf="!loggedinUser.isAdmin && !router.url.includes('upload')"
		class="search-upload position-relative top-0 end-0">
		<button type="button"
			pmd-btn-raised
			[routerLink]="['/invoice/upload']"
			pmd-btn
			color="secondary"
			class="btn btn-upload-sm me-0">
			<svg _ngcontent-nra-c115=""
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="23.401"
				viewBox="0 0 26.934 23.401"
				class="upload-icon">
				<g _ngcontent-nra-c115=""
					id="upload"
					transform="translate(0 -0.999)">
					<path _ngcontent-nra-c115=""
						id="Path_306"
						data-name="Path 306"
						d="M.842,9.9a.842.842,0,0,1,.842.842V14.95a1.683,1.683,0,0,0,1.683,1.683h20.2a1.683,1.683,0,0,0,1.683-1.683V10.742a.842.842,0,1,1,1.683,0V14.95a3.367,3.367,0,0,1-3.367,3.367H3.367A3.367,3.367,0,0,1,0,14.95V10.742A.842.842,0,0,1,.842,9.9Z"
						transform="translate(0 6.083)"
						fill="#2980b9"></path>
					<path _ngcontent-nra-c115=""
						id="Path_307"
						data-name="Path 307"
						d="M9.8,1.246a.842.842,0,0,1,1.192,0l5.05,5.05a.843.843,0,1,1-1.192,1.192L11.234,3.874v14.8a.842.842,0,0,1-1.683,0V3.874L5.938,7.488A.843.843,0,0,1,4.746,6.3L9.8,1.246Z"
						transform="translate(3.075 0)"
						fill="#ffffff"></path>
				</g>
			</svg>
			<span class="d-none d-lg-block fs-14">Upload Invoice</span>
		</button>
	</div>
	<div class="divider-vertical d-none d-lg-block"
		*ngIf="!loggedinUser.isAdmin && !router.url.includes('upload')"></div>
	<div class="dropdown pmd-dropdown pmd-user-info p-0 "
		pmdDropdown>
		<a href="javascript:void(0);"
			class="btn-user dropdown-toggle d-flex align-items-center ps-0"
			pmdDropdownToggle>
			<span class="pmd-avatar bg-secondary text-white me-0 me-sm-2">
				{{loggedinUser.shortName?.slice(0, 3)}}
			</span>
			<div class="flex-grow-1 d-none d-md-block khr-logedin-user">
				<h6 class="mb-0 text-black text-truncate"
					title='{{ loggedinUser.name }}'>
					{{ loggedinUser.name }}
				</h6>
				<p class="mb-0 fs-12 text-truncate"
					title='{{ loggedinUser.company.name }}'>
					{{ loggedinUser.company.name }}
				</p>
			</div>
			<i class="ms-2 material-symbols-outlined pmd-icon-sm d-none d-md-block">expand_more</i>
		</a>
		<ul class="dropdown-menu dropdown-menu-right"
			*pmdDropdownMenu
			aria-labelledby="userNavbarDropdown"
			role="menu">
			<li>
				<a class="dropdown-item d-flex align-items-center"
					routerLinkActive='active'
					[routerLink]="loggedinUser.isOwner ? loggedinUser.isDemo ? ['/entity'] : ['/profile'] : loggedinUser.isOutlet ? ['/profile'] : null"
					pmdRipple
					aria-label="Press enter to view profile"
					role="menuitem"
					tabindex="0">
					<span class="material-symbols-outlined me-2"
						aria-hidden="true">
						person
					</span>
					My Profile
				</a>
			</li>
			<li>
				<div class="dropdown-item d-flex align-items-center justify-content-between"
					pmdRipple
					aria-label="Press enter to logout from the tool"
					*ngIf="!loggedinUser.isAdmin"
					role="menuitem"
					tabindex="0">
					<span>
						<span class="d-flex align-items-center pf-cursor-pointer"
							pmdTooltip="Sync code"
							container="body"
							placement="bottom">
							<span class="material-symbols-outlined me-2"
								aria-hidden="true">
								developer_mode
							</span>
							<span>
								{{ syncCode }}
							</span>
						</span>
					</span>
					<span class="d-flex align-items-center">
						<div class="text-center position-relative">
							<button class="me-1 my-0"
								type="button"
								pmdBtn
								color="dark"
								pmd-btn-fab
								pmdRipple
								size="xs"
								pmdTooltip="Copy sync code"
								container="body"
								placement="bottom"
								(click)="copySyncCodeToClipboard()">
								<i class="material-icons md-light pmd-icon-xs">content_copy</i>
							</button>
							<button class="me-1 my-0"
								type="button"
								pmd-btn
								pmdRipple
								pmdBtn
								color="dark"
								pmd-btn-fab
								pmdRipple
								size="xs"
								pmdTooltip="Regenerate sync code"
								container="body"
								placement="bottom"
								(click)="refreshSyncCode($event)">
								<i class="material-icons md-light pmd-icon-xs">refresh</i>
							</button>
						</div>
					</span>
				</div>
			</li>
			<li>
				<a class="dropdown-item d-flex align-items-center"
					routerLinkActive='active'
					(click)="logout()"
					pmdRipple
					aria-label="Press enter to logout from the tool"
					role="menuitem"
					tabindex="0">
					<span class="material-symbols-outlined me-2"
						aria-hidden="true">
						logout
					</span>
					Logout
				</a>
			</li>
		</ul>
	</div>
</pmd-navbar-right-icon>