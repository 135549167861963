import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IBreadcrumb } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PageMetaService {
  breadcrumbs: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject(null);
  title: BehaviorSubject<string> = new BehaviorSubject('');
  backLink: BehaviorSubject<string | any[]> = new BehaviorSubject(null);
}
