import { Component, HostBinding, HostListener, Input, OnDestroy, DoCheck, Renderer2, ElementRef } from '@angular/core';

import { PmdTab } from './tab.directive';
import { PmdTabGroupConfig } from './tabset.config';
// todo: add active event to tab


// todo: fix? mixing static and dynamic tabs position tabs in order of creation
@Component({
  selector: 'pmd-tab-group',
  exportAs: 'pmdTabGroup',
  templateUrl: './tab-group.component.html'
})
export class PmdTabGroup implements OnDestroy {

  @Input() theme: string = '';
  @Input() pmdCard: boolean;

  /** if true tabs will be placed vertically */
  @Input()
  get vertical(): boolean {
    return this._vertical;
  }
  set vertical(value: boolean) {
    this._vertical = value;
    this.setClassMap();
  }

  /** if true tabs fill the container and have a consistent width */
  @Input()
  get justified(): boolean {
    return this._justified;
  }
  set justified(value: boolean) {
    this._justified = value;
    this.setClassMap();
  }

  @Input()
  get fullwidth(): boolean {
    return this._fullwidth;
  }
  set fullwidth(value: boolean) {
    this._fullwidth = value;
    this.setClassMap();
  }

  /** navigation context class: 'tabs' or 'pills' */
  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
    this.setClassMap();
  }

  get isKeysAllowed(): boolean {
    return this._isKeysAllowed;
  }

  set isKeysAllowed(value: boolean) {
    this._isKeysAllowed = value;
  }


  @HostBinding('class.pmd-tab-container') clazz = true;
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {
    let wrapperEl = this.elementRef.nativeElement.querySelector(".pmd-tabs");
    let childEl = this.elementRef.nativeElement.querySelector(".nav");
    this.appendulwidth();
  }

  tabs: PmdTab[] = [];
  classMap: any = {};

  /** aria label for tab list */
  ariaLabel: string;

  protected isDestroyed: boolean;
  protected _vertical: boolean;
  protected _justified: boolean;
  protected _fullwidth: boolean;
  protected _type: string;
  protected _isKeysAllowed: boolean;

  activeTabSlider = this.renderer.createElement('div');

  ClassName = {
    PMD_TAB: 'pmd-tabs',
    NAV_JUSTIFIED: 'nav-fill',
    PREV_TAB: 'prev-tab',
    LAST_TAB: 'last-tab',
    PM_INI: "pm-ini",
    SCROLL: "pmd-tabs-scroll",
    SCROLL_CONTAINER: 'pmd-tabs-scroll-container',
    ACTIVE_BAR: 'pmd-tab-active-bar'
  };

  Selector = {
    PARENT_SELECTOR: '',
    PMD_TAB: '.' + this.ClassName.PMD_TAB,
    UL_NAV_TABS: 'ul.nav-tabs',
    LI: 'li',
    SCROLL_CONTAINER: '.' + this.ClassName.SCROLL_CONTAINER,
    NAV_TAB: '.nav-tabs',
    SCROLL_RIGHT: '.pmd-tabs-scroll-right',
    SCROLL_LEFT: '.pmd-tabs-scroll-left',
    UL_LI_ACTIVE: 'ul li .nav-link.active',
    ACTIVE_BAR: '.' + this.ClassName.ACTIVE_BAR,
    NAV: '.nav',
    UL: 'ul',
    UL_LI: 'ul li',
    NAV_TABS_LI: '.nav-tabs li',
    LAST_TAB: '.' + this.ClassName.LAST_TAB,
    PREV_TAB: '.' + this.ClassName.PREV_TAB,
    PM_INI: '.' + this.ClassName.PM_INI
  };

  constructor(
    config: PmdTabGroupConfig,
    private renderer: Renderer2,
    public elementRef: ElementRef) {
    Object.assign(this, config);
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  // ngDoCheck(): void{
  //   if(this.theme!=='bootstrap4'){
  //     this.sliderLoad();
  //   }
  // }

  ngAfterViewInit() {
    this.activeTabSlider.className += 'pmd-tab-active-bar';
    let parent = this.elementRef.nativeElement.querySelector('.pmd-tabs');
    let refChild = this.elementRef.nativeElement.querySelector('.nav-tabs');
    this.renderer.insertBefore(parent, this.activeTabSlider, refChild);
    if (this.theme !== 'bootstrap4') {
      this.sliderLoad();
      setTimeout(() => {
        this.appendulwidth();
      })
    }
  }

  addTab(tab: PmdTab): void {
    this.tabs.push(tab);
    tab.active = this.tabs.length === 1 && typeof tab.active === 'undefined';
  }

  removeTab(
    tab: PmdTab,
    options = { reselect: true, emit: true }
  ): void {
    const index = this.tabs.indexOf(tab);
    if (index === -1 || this.isDestroyed) {
      return;
    }
    // Select a new tab if the tab to be removed is selected and not destroyed
    if (options.reselect && tab.active && this.hasAvailableTabs(index)) {
      const newActiveIndex = this.getClosestTabIndex(index);
      this.tabs[newActiveIndex].active = true;
    }
    if (options.emit) {
      tab.removed.emit(tab);
    }
    this.tabs.splice(index, 1);
    if (tab.elementRef.nativeElement.parentNode) {
      this.renderer.removeChild(
        tab.elementRef.nativeElement.parentNode,
        tab.elementRef.nativeElement
      );
    }
  }

  keyNavActions(event: KeyboardEvent, index: number) {
    if (!this.isKeysAllowed) {
      return;
    }
    const list: HTMLElement[] = Array.from(this.elementRef.nativeElement.querySelectorAll('.nav-link'));
    if (!event.shiftKey && (event.keyCode === 9 || event.key === 'Tab')) {
      event.preventDefault();
      // event.stopPropagation();
      setTimeout(() => {
        let activePane = this.elementRef.nativeElement.querySelectorAll('[class="active tab-pane"]')
        if (activePane.length == 0) {
          activePane = this.elementRef.nativeElement.querySelectorAll('[class="tab-pane active"]')
        }
        let firstElement = activePane[0].querySelectorAll('[tabindex="0"]');
        firstElement[0].focus();
      }, 0)
      for (let l of list) {
        if (!l.className.includes('active')) {
          l.setAttribute('tabindex', '-1')
        }
      }
    } else {
      for (let l of list) {
        l.setAttribute('tabindex', '0')
      }
    }
    // const activeElList = list.filter((el: HTMLElement) => !el.classList.contains('disabled'));
    if (event.keyCode === 13 || event.key === 'Enter' || event.keyCode === 32 || event.key === 'Space') {
      event.preventDefault();
      const currentTab = list[(index) % list.length];
      currentTab.click();
      return;
    }
    if (event.keyCode === 39 || event.key === 'RightArrow') {
      let nextTab: HTMLElement;
      let shift = 1;
      do {
        nextTab = list[(index + shift) % list.length];
        shift++;
      } while (nextTab.classList.contains('disabled'));
      nextTab.focus();
      return;
    }

    if (event.keyCode === 37 || event.key === 'LeftArrow') {
      let previousTab: HTMLElement;
      let shift = 1;
      let i = index;

      do {
        if ((i - shift) < 0) {
          i = list.length - 1;
          previousTab = list[i];
          shift = 0;
        } else {
          previousTab = list[i - shift];
        }

        shift++;
      } while (previousTab.classList.contains('disabled'));

      previousTab.focus();

      return;
    }

    if (event.keyCode === 36 || event.key === 'Home') {
      event.preventDefault();

      let firstTab: HTMLElement;
      let shift = 0;

      do {
        firstTab = list[shift % list.length];

        shift++;
      } while (firstTab.classList.contains('disabled'));

      firstTab.focus();

      return;
    }

    if (event.keyCode === 35 || event.key === 'End') {
      event.preventDefault();

      let lastTab: HTMLElement;
      let shift = 1;
      let i = index;

      do {
        if ((i - shift) < 0) {
          i = list.length - 1;
          lastTab = list[i];
          shift = 0;
        } else {
          lastTab = list[i - shift];
        }

        shift++;
      } while (lastTab.classList.contains('disabled'));

      lastTab.focus();

      return;
    }

    if (event.keyCode === 46 || event.key === 'Delete') {
      if (this.tabs[index].removable) {
        this.removeTab(this.tabs[index]);

        if (list[index + 1]) {
          list[(index + 1) % list.length].focus();

          return;
        }

        if (list[list.length - 1]) {
          list[0].focus();
        }
      }
    }
  }

  protected getClosestTabIndex(index: number): number {
    const tabsLength = this.tabs.length;
    if (!tabsLength) {
      return -1;
    }

    for (let step = 1; step <= tabsLength; step += 1) {
      const prevIndex = index - step;
      const nextIndex = index + step;
      if (this.tabs[prevIndex] && !this.tabs[prevIndex].disabled) {
        return prevIndex;
      }
      if (this.tabs[nextIndex] && !this.tabs[nextIndex].disabled) {
        return nextIndex;
      }
    }

    return -1;
  }

  protected hasAvailableTabs(index: number): boolean {
    const tabsLength = this.tabs.length;
    if (!tabsLength) {
      return false;
    }
    for (let i = 0; i < tabsLength; i += 1) {
      if (!this.tabs[i].disabled && i !== index) {
        return true;
      }
    }
    return false;
  }

  protected setClassMap(): void {
    this.classMap = {
      'nav-stacked': this.vertical,
      'flex-column': this.vertical,
      'nav-justified': this.justified,
      'nav-fill': this.fullwidth,
      [`nav-${this.type}`]: true
    };
  }


  widthOfList() {
    var mainElement = this.elementRef.nativeElement;
    var childEl = mainElement.querySelector(this.Selector.UL).children;
    var itemsWidth = 0;
    this.tabs.forEach(function (tab, index) {
      var itemWidth = childEl[index].getBoundingClientRect().width;
      itemsWidth += itemWidth;
    });
    return itemsWidth;
  }

  appendulwidth() {
    if (this.theme !== 'bootstrap4') {
      var mainElement = this.elementRef.nativeElement;
      if (mainElement.querySelector(this.Selector.UL_NAV_TABS)) {
        this.renderer.setStyle(mainElement.querySelector(this.Selector.UL_NAV_TABS), "width", this.widthOfList() + "px");
      }
    }
  }

  sliderLoad() {
    if (this.theme !== 'bootstrap4') {
      let mainElement = this.elementRef.nativeElement;
      let UL_LI_ACTIVE_Class = this.Selector.UL_LI_ACTIVE;
      let NAV_Class = this.Selector.NAV;
      let slider = this.activeTabSlider;
      let renderer = this.renderer;
      setTimeout(function () {
        let sliderActive = mainElement.querySelector(UL_LI_ACTIVE_Class),
          isX = sliderActive.offsetLeft,
          navX = mainElement.querySelector(NAV_Class).offsetLeft,
          wrapperLeft = slider.parentElement.offsetLeft,
          sliderLeft = isX - wrapperLeft,
          finalPossition = wrapperLeft - navX + isX - wrapperLeft;
        if (navX < wrapperLeft) {
          renderer.setStyle(slider, "width", sliderActive.offsetWidth + "px");
          renderer.setStyle(slider, "left", finalPossition + "px");
        } else {
          let ActiveTabWidth = sliderActive.closest('li').offsetWidth;
          renderer.setStyle(slider, "left", sliderLeft + "px");
          renderer.setStyle(slider, "width", ActiveTabWidth + "px");
        }
      })
    }
  }
}