import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentLoaderFactory } from '../component-loader';
import { PositioningService } from '../positioning';
import { PmdPopoverConfig } from './popover.config';
import { PmdPopover } from './popover.directive';
import { PmdPopoverContainer } from './popover-container.component';

@NgModule({
    imports: [CommonModule],
    declarations: [PmdPopover, PmdPopoverContainer],
    exports: [PmdPopover]
})
export class PmdPopoverModule {
  static forRoot(): ModuleWithProviders<PmdPopoverModule> {
    return {
      ngModule: PmdPopoverModule,
      providers: [PmdPopoverConfig, ComponentLoaderFactory, PositioningService]
    };
  }
}
