<ng-container *ngIf="path">
	<div class="d-flex justify-content-between khr-page-title align-items-center mt-2 pt-1 zoom-controller">
		<span class="invoiceSource"
			container="body"
			*ngIf="name"
			[pmdTooltip]="name">{{name}}</span>
		<div class="text-end position-relative m-1"
			[ngClass]="{'invoice-review-btn-wrap-zoom': name, 'btn-wrap-zoom': !name}">
			<button class="me-1 d-none d-xl-flex"
				*ngIf="showFullScreenBtn"
				type="button"
				(click)="toggleFullScreen()"
				color="dark"
				pmdRipple
				pmd-btn
				size="xs">
				<i class="material-icons">
					{{ isFullScreen ? 'close_fullscreen' : 'open_in_full' }}
				</i>
			</button>
			<button id="zoom-out"
				class="me-1"
				type="button"
				(click)="zoomOut()"
				color="dark"
				pmdRipple
				pmd-btn
				size="xs">
				<i class="material-icons">remove</i>
			</button>
			<button class="me-1"
				id="zoom-in"
				type="button"
				(click)="zoomIn()"
				pmd-btn
				pmdRipple
				color="dark"
				pmdRipple
				size="xs">
				<i class="material-icons">add</i>
			</button>
			<button class="me-1"
				(click)="antiRotate()"
				pmd-btn
				pmdRipple
				color="dark"
				pmdRipple
				size="xs">
				<i class="material-icons">rotate_left</i>
			</button>
			<button class="me-1"
				(click)="rotate()"
				pmd-btn
				pmdRipple
				color="dark"
				pmdRipple
				size="xs">
				<i class="material-icons">rotate_right</i>
			</button>
			<button class="me-1"
				*ngIf="downlaodVisible"
				id="zoom-in"
				type="button"
				(click)="downloadFilesAsZip()"
				pmd-btn
				color="dark"
				class="bg-dark border-dark"
				pmdRipple
				size="xs">
				<i class="material-icons text-white">download</i>
			</button>
			<button *ngIf="showCloseIcon"
				pmd-btn
				color="dark"
				pmdRipple
				size="xs"
				aria-label="Close"
				(click)="onClose()">
				<i class="material-icons">close</i>
			</button>
		</div>
	</div>
	<div class="image-container mt-2 pt-1 bg-light rounded"
		(mouseleave)="leave()">
		<small *ngIf="isDuplicate"
			class="on-badge status-duplicate duplicate-indicator pmd-badge badge rounded-pill border bg-white">Duplicate</small>
		<img #image
			class="zoom-image"
			[src]="path"
			loading="lazy"
			alt="Zoomable Image">
	</div>
</ng-container>