import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { PmdAlertConfig } from './alert.config';
import { OnChange } from '../utils';

@Component({
  selector: 'pmd-alert',
  exportAs: 'pmdAlert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"alert " + "alert-" + type',
  },
})
export class PmdAlert implements OnInit {

  /** Alert type.
   * Provides one of eight bootstrap supported contextual classes:
   * `primary`, `secondary`, `success`, `danger`, `warning`, `info`, `light` and `dark`
   */
  @Input() type = 'warning';

  /** If set, displays an inline "Close" button */
  @OnChange() @Input() dismiss = false;

  /** Number in milliseconds, after which alert will be closed */
  @Input() delay: number | string;


  /** Is alert visible */
  @Input() isOpen = true;



  /** This event fires immediately after close instance method is called,
   * $event is an instance of Alert component.
   */
  @Output() onClose = new EventEmitter<PmdAlert>();

  /** This event fires when alert closed, $event is an instance of Alert component */
  @Output() onClosed = new EventEmitter<PmdAlert>();

  classes = '';
  dismissChange = new EventEmitter<boolean>();

  constructor(_config: PmdAlertConfig, private changeDetection: ChangeDetectorRef) {
    Object.assign(this, _config);
    this.dismissChange.subscribe((dismiss: boolean) => {
      this.classes = this.dismiss ? 'alert-dismiss' : '';
      this.changeDetection.markForCheck();
    });
  }

  ngOnInit(): void {
    if (this.delay) {
      // if delay used as attr without binding, it will be a string
      setTimeout(
        () => this.close(),
        parseInt(this.delay as string, 10)
      );
    }
  }

  // todo: animation ` If the .fade and .in classes are present on the element,
  // the alert will fade out before it is removed`
  /**
   * Closes an alert by removing it from the DOM.
   */
  close(): void {
    if (!this.isOpen) {
      return;
    }
    this.onClose.emit(this);
    this.isOpen = false;
    this.changeDetection.markForCheck();
    this.onClosed.emit(this);
  }
}
