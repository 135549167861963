import { 
  Component, 
  OnInit, 
  forwardRef, 
  ViewChild, 
  Input, 
  EventEmitter, 
  Output, 
  OnChanges, 
  SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export const PmdCheckboxValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PmdCheckbox),
  multi: true
};

export enum TransitionCheckState {
  /** The initial state of the component before any user interaction. */
  Init,
  /** The state representing the component when it's becoming checked. */
  Checked,
  /** The state representing the component when it's becoming unchecked. */
  Unchecked,
  /** The state representing the component when it's becoming indeterminate. */
  Indeterminate
}

let defaultIdNumber = 0;

export class PmdCheckboxChange {
  element: PmdCheckbox;
  checked: boolean;
}

@Component({
  selector: 'pmd-checkbox',
  exportAs: 'pmdCheckbox',
  templateUrl: './checkbox.component.html',
  host: {
    'class': 'form-check',
    '[class.form-check-inline]': 'inline',
    '[class.pmd-checkbox]': 'theme!== "bootstrap4"',
  },
  providers: [ PmdCheckboxValueAccessor]
})
export class PmdCheckbox implements OnInit, OnChanges {

  @ViewChild('input', {static: true}) inputEl: any;

  private defaultId = `pmd-checkbox-${++defaultIdNumber}`;

  @Input() class: string;
  @Input() pmdClass: boolean;
  @Input() id: string = this.defaultId;
  @Input() required: boolean;
  @Input() name: string;
  @Input() value: string;
  @Input() checked = false;
  @Input() filledIn = false;
  @Input() indeterminate = false;
  @Input() disabled: boolean;
  @Input() rounded = false;
  @Input() checkboxPosition = 'left';
  @Input() state:string;
  @Input() inline = false;
  @Input() tabIndex: number;
  @Input() theme:string = '';
  @Input() ariaLabel: string = '';


  @Output() change: EventEmitter<PmdCheckboxChange> = new EventEmitter<PmdCheckboxChange>();

  constructor() { }

  ngOnInit() {
    if (this.indeterminate && !this.filledIn && !this.rounded) {
      this.inputEl.indeterminate = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('checked')) {
      this.checked = changes.checked.currentValue;
    }
  }

  get changeEvent() {
    const newChangeEvent = new PmdCheckboxChange();
    newChangeEvent.element = this;
    newChangeEvent.checked = this.checked;
    return newChangeEvent;
  }

  toggle() {
    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    this.indeterminate = false;
    this.onChange(this.checked);
  }

  onCheckboxClick(event: any) {
    event.stopPropagation();
    this.toggle();
  }

  onCheckboxChange(event: any) {
    event.stopPropagation();
    this.change.emit(this.changeEvent);
  }

  onChange = (_: any) => { };
  onTouched = () => { };

  writeValue(value: any) {
    this.value = value;
    this.checked = !!value;
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

}
