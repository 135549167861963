import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PmdSwitch } from './switch.component';

export { PmdSwitchValueAccessor, PmdSwitch } from './switch.component';

@NgModule({
  declarations: [
    PmdSwitch
  ],
  exports: [
    PmdSwitch
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class PmdSwitchModule { }