import { 
  Component, 
  HostBinding, 
  Input 
} from '@angular/core';
import { 
  PmdProgressbarConfig 
} from './progressbar.config';
import { isBs3 } from '../utils';
import { PmdBar } from './bar.component';

@Component({
  selector: 'pmd-progressbar',
  exportAs: 'pmdProgressbar',
  templateUrl: './progressbar.component.html',
  styles: [
    `:host {
      width: 100%;
      display: flex;
    }`
  ],
  host: {
    'class': 'pmd-progress',
  }
})

export class PmdProgressbar {
  /** if `true` changing value of progress bar will be animated */
  @Input()
  set animate(value: boolean) {
    this._animate = value;
    this.bars.forEach((b: PmdBar) => {
      b.animate = value;
    });
  }
  /** If `true`, striped classes are applied */
  @Input()
  set striped(value: boolean) {
    this._striped = value;
    this.bars.forEach((b: PmdBar) => {
      b.striped = value;
    });
  }

  /** provide one of the four supported contextual classes: `success`, `info`, `warning`, `danger` */
  @Input() type: string;
  /** current value of progress bar. Could be a number or array of objects
   * like {"value":15,"type":"info","label":"15 %"}
   */
  @Input()
  set value(value: number | any[]) {
    this.isStacked = Array.isArray(value);
    this._value = value;
  }
  isStacked = false;
  _striped: boolean;
  _animate: boolean;
  _value: number | any[];
  get isBs3(): boolean {
    return isBs3();
  }

  /** maximum total value of progress element */
  @HostBinding('attr.max')
  @Input()
  get max(): number {
    return this._max;
  }

  set max(v: number) {
    this._max = v;
    this.bars.forEach((bar: PmdBar) => {
      bar.recalculatePercentage();
    });
  }



  @HostBinding('class.progress') addClass = true;

  bars: any[] = [];

  protected _max = 100;

  constructor(config: PmdProgressbarConfig) {
    Object.assign(this, config);
  }
  addBar(bar: PmdBar): void {
    bar.animate = this._animate;
    bar.striped = this._striped;

    this.bars.push(bar);
  }

  removeBar(bar: PmdBar): void {
    this.bars.splice(this.bars.indexOf(bar), 1);
  }
}
