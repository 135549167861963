<ul class="nav" 
	[ngClass]="classMap" 
	(click)="$event.preventDefault()" 
	*ngIf="theme=='bootstrap4'"
	[attr.aria-label]="ariaLabel"
    role="tablist">
	<li *ngFor="let tabz of tabs; let i = index" [ngClass]="['nav-item', tabz.customClass || '']" [class.active]="tabz.active" [class.disabled]="tabz.disabled" (keydown)="keyNavActions($event, i)" role="presentation">
		<button class="nav-link" 
			[attr.id]="tabz.id ? tabz.id + '-link' : ''" 
			[attr.aria-controls]="tabz.id ? tabz.id : ''"
       		[attr.aria-selected]="!!tabz.active"
			[class.active]="tabz.active" [class.disabled]="tabz.disabled" 
		  	(click)="tabz.active = true;" role="tab">
		  <span [ngTransclude]="tabz.headingRef">{{ tabz.label }}</span>
		  <span *ngIf="tabz.removable" (click)="$event.preventDefault(); removeTab(tabz);" class="bs-remove-tab"> &#10060;</span>
		</button>
	</li>
</ul>

<div class="pmd-tabs  sticky-tab" *ngIf="theme!=='bootstrap4'">
	<ul class="nav" [ngClass]="classMap" (click)="$event.preventDefault()" role="tablist">
		<li *ngFor="let tabz of tabs; let i = index" [ngClass]="['nav-item', tabz.customClass || '']" [class.active]="tabz.active" [class.disabled]="tabz.disabled" (keydown)="keyNavActions($event, i)" role="presentation">
			<button class="nav-link" 
				[attr.id]="tabz.id ? tabz.id + '-link' : ''" 
				[attr.aria-controls]="tabz.id ? tabz.id : ''"
       			[attr.aria-selected]="!!tabz.active"
				[class.active]="tabz.active" [class.disabled]="tabz.disabled" 
				(click)="tabz.active = true;" role="tab">
			  <span [ngTransclude]="tabz.headingRef">{{ tabz.label }}</span>
			  <span *ngIf="tabz.removable" (click)="$event.preventDefault(); removeTab(tabz);" class="bs-remove-tab"> &#10060;</span>
			</button>
		</li>
		
	</ul>
</div>
<div class="tab-content">
	<ng-content></ng-content>
</div>