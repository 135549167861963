import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@core/services';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
        private authService: AuthService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn) {
            if (!this.authService.user.isOnboarded && state.url !== '/sign-up') {
                this.router.navigate(['/sign-up']);

                return false;
            }

            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate([this.authService.user?.isDemo ? 'demo-login' : 'login', state.url]);
            return false;
        }
    }
}