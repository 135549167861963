/**
* ------------------------------------------------------------------------
* Propeller Input Component
* ------------------------------------------------------------------------
*/

import {
	Directive,
	ElementRef,
	Renderer2,
	OnInit
} from '@angular/core';


const INPUT_HOST_ATTRIBUTES = [
	'pmd-textfield-outline',
	'pmd-textfield-filled'
];

/**
* ------------------------------------------------------------------------
* Directive Declaration
* ------------------------------------------------------------------------
*/

@Directive({
	selector: '[pmdTextField], [pmd-textfield-outline], [pmd-textfield-filled]',
	exportAs: 'pmdTextInput',
	host: {
		'(focus)': 'setInputFocus()',
		'(focusout)': 'setOnFocusOut()',
		'(change)': 'setChange()',
		'(input)': 'setInput()',
		'(ngModelChange)': 'setInput()'
	}
})

export class PmdInput implements OnInit {

	/**
	* ------------------------------------------------------------------------
	* Variables
	* ------------------------------------------------------------------------
	*/

	ClassName = {
		PMD_TEXTFIELD: 'pmd-textfield',
		FOCUS: 'pmd-textfield-focused',
		FLOATING_COMPLETE: 'pmd-textfield-floating-label-completed',
		FLOATING_ACTIVE: 'pmd-textfield-floating-label-active',
		PMD_TEXTFIELD_OUTLINE: 'pmd-textfield-outline',
		PMD_TEXTFIELD_FILLED: 'pmd-textfield-filled',
		PMD_TEXTFIELD_OUTLINE_WRAPPER: 'pmd-textfield-outline-wrapper',
		PMD_TEXTFIELD_LABEL_WRAPPER: 'pmd-textfield-label-wrapper',
		PMD_TEXTFIELD_OUTLINE_MIDDLE: 'pmd-textfield-outline-middle',
		PMD_TEXTFIELD_FILLED_WRAPPER: 'pmd-textfield-filled-wrapper'
	};

	Template = {
		OUTLINE_WRAPPER: '<div class="pmd-textfield-outline-wrapper"></div>',
		OUTLINE_LABEL_WRAPPER: '<div class="pmd-textfield-label-wrapper"><div class="pmd-textfield-outline-middle"></div></div>',
		OUTLINE_LEFT_BORDER: '<div class="pmd-textfield-outline-left"></div>',
		OUTLINE_RIGHT_BORDER: '<div class="pmd-textfield-outline-right"></div>',
		FILLED_WRAPPER: '<div class="pmd-textfield-filled-wrapper"></div>',
	};

	invalid_input_types = [
		'button',
		'checkbox',
		'file',
		'hidden',
		'image',
		'radio',
		'range',
		'reset',
		'submit'
	];

	span = this._renderer.createElement('span');
	_element: any;
	_parentElement: any;
	_elementType: any;

	label_wrapper = this._renderer.createElement('div');
	outline_middle = this._renderer.createElement('div');
	outline_wrapper = this._renderer.createElement('div');
	outline_wrapper_select = this._renderer.createElement('div');
	filled_wrapper = this._renderer.createElement('div');

	constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
		this._element = this._elementRef.nativeElement;
		this._elementType = this._element.type;
	}

	/**
	* ------------------------------------------------------------------------
	* Initialization
	* ------------------------------------------------------------------------
	*/

	ngOnInit() {
		for (const attr of INPUT_HOST_ATTRIBUTES) {
			if (this._hasHostAttributes(attr)) {
				(this._element as HTMLElement).classList.add(attr);
			}
		}
		setTimeout(() => {
			const autofilledInput = document.querySelector('input:-webkit-autofill');
			if (autofilledInput) {
				this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_COMPLETE);
			}
		}, 700);
	}

	insertAfter(el, referenceNode) {
		referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
	}

	ngAfterViewInit() {

		const refChildLabel = this._element.querySelector('label');
		const refChildInput = this._element.querySelector('.form-control');
		const refChildfocused = this._element.querySelector('.pmd-textfield-focused')
		const refChildSelect = this._element.querySelector('.form-select')

		const isInvalid = this._element.querySelector('.is-invalid');
		const isValid = this._element.querySelector('.is-valid');
		const formControl = this._element.classList.contains('form-control')

		this._parentElement = this._element.parentElement
		//	this._renderer.appendChild(this._parentElement, this.span);

		if (formControl) {
			this.insertAfter(this.span, this._element);
			this._renderer.addClass(this.span, this.ClassName.FOCUS);
		}

		if (this._element.value !== "") {
			this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_COMPLETE);
		}

		for (const attr of INPUT_HOST_ATTRIBUTES) {
			if (this._hasHostAttributes(attr)) {
				if (attr === ('pmd-textfield-outline')) {
					let refLabel = this._getHostElement().querySelector('label')
					let refInput = this._getHostElement().querySelector('.form-control')
					let refSelect = this._getHostElement().querySelector('.form-select')
					let reffocused = this._getHostElement().querySelector('.pmd-textfield-focused')
					if (reffocused) {
						this._renderer.removeChild(this._getHostElement(), refChildfocused);
					}
					if (refLabel) {
						refChildLabel.parentNode.insertBefore(this.label_wrapper, refChildLabel);
						this.label_wrapper.appendChild(refChildLabel);
						this._renderer.addClass(this.label_wrapper, this.ClassName.PMD_TEXTFIELD_LABEL_WRAPPER);
						refChildLabel.insertAdjacentHTML('beforebegin', this.Template.OUTLINE_LEFT_BORDER);
						refChildLabel.insertAdjacentHTML('afterend', this.Template.OUTLINE_RIGHT_BORDER);
						refChildLabel.parentNode.insertBefore(this.outline_middle, refChildLabel);
						this.outline_middle.appendChild(refChildLabel);
						this._renderer.addClass(this.outline_middle, this.ClassName.PMD_TEXTFIELD_OUTLINE_MIDDLE);
						let labelWrapper = this._element.querySelector('.pmd-textfield-label-wrapper');
						if (refInput) {
							refChildInput.parentNode.insertBefore(this.outline_wrapper, refChildInput);
							this.outline_wrapper.appendChild(labelWrapper);
							this.outline_wrapper.appendChild(refChildInput);
							this._renderer.addClass(this.outline_wrapper, this.ClassName.PMD_TEXTFIELD_OUTLINE_WRAPPER);
						}
						if (refSelect) {
							refChildSelect.parentNode.insertBefore(this.outline_wrapper_select, refChildSelect);
							this.outline_wrapper_select.appendChild(labelWrapper);
							this.outline_wrapper_select.appendChild(refChildSelect);
							this._renderer.addClass(this.outline_wrapper_select, this.ClassName.PMD_TEXTFIELD_OUTLINE_WRAPPER);
						}
					}
				}
				if (attr === ('pmd-textfield-filled')) {
					let refLabel = this._getHostElement().querySelector('label')
					let refInput = this._getHostElement().querySelector('.form-control')
					let refSelect = this._getHostElement().querySelector('.form-select')
					let reffocused = this._getHostElement().querySelector('.pmd-textfield-focused')
					if (reffocused) {
						this._renderer.removeChild(this._getHostElement(), refChildfocused);
					}
					if (refLabel) {
						if (refInput) {
							refChildInput.parentNode.insertBefore(this.filled_wrapper, refChildInput);
							this.filled_wrapper.appendChild(refChildLabel);
							this.filled_wrapper.appendChild(refChildInput);
							this.filled_wrapper.appendChild(this.span);
							this._renderer.addClass(this.span, this.ClassName.FOCUS);
						}
						if (refSelect) {
							refChildSelect.parentNode.insertBefore(this.filled_wrapper, refChildSelect);
							this.filled_wrapper.appendChild(refChildLabel);
							this.filled_wrapper.appendChild(refChildSelect);
							this.filled_wrapper.appendChild(this.span);
							this._renderer.addClass(this.span, this.ClassName.FOCUS);
						}
						this._renderer.addClass(this.filled_wrapper, this.ClassName.PMD_TEXTFIELD_FILLED_WRAPPER);
					} else {
						this._getHostElement().appendChild(this.span);
						this._renderer.addClass(this.span, this.ClassName.FOCUS);
					}
				}
			}
		}

		if (isInvalid) {
			this._element.classList.add("pmd-textfield-is-invalid");
		}

		if (isValid) {
			this._element.classList.add("pmd-textfield-is-valid");
		}

	}

	/**
	* ------------------------------------------------------------------------
	* Event Functions
	* ------------------------------------------------------------------------
	*/

	checkForValidType() {
		if (this.invalid_input_types.indexOf(this._elementType) > -1) {
			return false;
		}
		return true;
	}

	setInputFocus(): void {
		if (this.checkForValidType() && this._parentElement.classList.contains(this.ClassName.PMD_TEXTFIELD)) {
			this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_ACTIVE);
			this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_COMPLETE);
		}
	}

	setChange(): void {
		if (this._element.value === "" && this.checkForValidType()) {
			// this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_ACTIVE);
			this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_COMPLETE);
		}
	}

	setInput(): void {
		if (this._element.value !== "" && this.checkForValidType()) {
			// this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_ACTIVE);
			this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_COMPLETE);
		}
	}

	setOnFocusOut(): void {
		if (this._element.value === "" && this.checkForValidType()) {
			this._renderer.removeClass(this._parentElement, this.ClassName.FLOATING_COMPLETE);
		}
		this._renderer.removeClass(this._parentElement, this.ClassName.FLOATING_ACTIVE);
	}

	_hasHostAttributes(...attributes: string[]) {
		return attributes.some(attribute => this._getHostElement().hasAttribute(attribute));
	}

	_getHostElement() {
		return this._element;
	}

}