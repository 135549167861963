import { Component } from '@angular/core';

import { AppGlobals } from '@app/app.global';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  homePath = this.appGlobals.homePath;

  constructor(private appGlobals: AppGlobals) { }
}