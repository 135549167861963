import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PmdSidebar, PmdMultiSidebar} from './sidebar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PmdSidebar,
    PmdMultiSidebar 
  ],
  exports: [
    PmdSidebar,
    PmdMultiSidebar
  ],
  providers: []
})

export class PmdSidebarModule {}
