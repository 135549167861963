import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { PmdInput } from './input.component';
import { 
	     PmdFormGroup,
	     PmdFloatingLabel,
		 PmdInputGroup,
		 PmdInputGroupOutline,
		 PmdInputGroupFilled,
	     PmdTextfield,
		 PmdInputGroupPrepend,
		 PmdInputGroupAppend,
		 PmdInputGroupIcon,
		 PmdInputGroupText} from './form-group.component';
import { PmdTextfieldOutline } from './pmd-textfield-outline';
import { PmdTextfieldFilled } from './pmd-textfield-filled';

@NgModule({
  imports: [
	  CommonModule
  ],
  declarations: [
	  PmdInput,
	  PmdFormGroup,
	  PmdFloatingLabel,
	  PmdInputGroup,
	  PmdInputGroupOutline,
	  PmdInputGroupFilled,
	  PmdTextfield,
	  PmdTextfieldOutline,
	  PmdTextfieldFilled,
	  PmdInputGroupPrepend,
	  PmdInputGroupAppend,
	  PmdInputGroupIcon,
	  PmdInputGroupText
  ],
  exports: [
	  PmdInput,
	  PmdFormGroup,
	  PmdInputGroupOutline,
	  PmdInputGroupFilled,
	  PmdFloatingLabel,
	  PmdInputGroup,
	  PmdTextfield,
	  PmdTextfieldOutline,
	  PmdTextfieldFilled,
	  PmdInputGroupPrepend,
	  PmdInputGroupAppend,
	  PmdInputGroupIcon,
	  PmdInputGroupText
  ]
})
export class PmdFormGroupModule {
  static forRoot(): ModuleWithProviders<PmdFormGroupModule> {
    return { ngModule: PmdFormGroupModule };
  }
}
