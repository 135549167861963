import { PmdNavbarService } from './navbar.service';

import { 
  Component, 
  ElementRef, 
  ViewChild, 
  Input, 
  Renderer2, 
  AfterViewInit, 
  HostListener, 
  OnInit } from '@angular/core';

import { 
  Subscription } from 'rxjs';

@Component({
  selector: 'pmd-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['./pmd-navbar.css'],
  host: {
    'class': 'navbar pmd-navbar'

  }
})

export class PmdNavbar implements AfterViewInit, OnInit {

  @Input() iconBackground: string | string[];
  @Input() SideClass: string;
  @Input() containerInside = true;

  subscription: Subscription;
  navbarLinkClicks: any;
  shown = false;

  public doubleNav: boolean;
  public height: number;
  public duration = 350; // ms

  public collapse = false;
  public showClass = false;
  public collapsing = false;

  @ViewChild('navbar', {static: true}) el: ElementRef;
  @ViewChild('mobile', {static: true}) mobile: ElementRef;
  @ViewChild('nav', {static: true}) navbar: ElementRef;
  @ViewChild('container', {static: true}) container: ElementRef;
  @ViewChild('toggler', {static: true}) toggler: ElementRef;

  constructor(public renderer: Renderer2, private _navbarService: PmdNavbarService, private _elementRef: ElementRef) {
    // tslint:disable-next-line:max-line-length
    this.subscription = this._navbarService.getNavbarLinkClicks().subscribe(navbarLinkClicks => { this.closeNavbarOnClick(navbarLinkClicks); });
  }

  closeNavbarOnClick(navbarLinkClicks: any) {
    this.navbarLinkClicks = navbarLinkClicks;
    if (this.showClass) {
      this.hide();
    }
  }

  addTogglerIconClasses() {
    if (this.iconBackground) {
      if (Array.isArray(this.iconBackground)) {
        this.iconBackground.forEach((iconClass) => {
          this.renderer.addClass(this.toggler.nativeElement, iconClass);
        });
      } else {
        this.renderer.addClass(this.toggler.nativeElement, this.iconBackground);
      }
    }
  }

  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.height = this.el.nativeElement.scrollHeight;
      this.collapse = true;
      if (!this.containerInside) {
          const childrens = Array.from(this.container.nativeElement.children);
          childrens.forEach(child => {
              // this.navbar.nativeElement.append(child);
              this.renderer.appendChild(this.navbar.nativeElement, child);
              this.container.nativeElement.remove();
          });
      }
      if (this.el.nativeElement.children.length === 0) {
          this.el.nativeElement.remove();
      }
    });
    this.addTogglerIconClasses();
  }

  overlaytoggle(event: any){
    this.hide();
  }

  toggle(event: any) {
    event.preventDefault();
    if (!this.collapsing) {
      if (this.shown) {
        this.hide();
      } else {
        this.show();
      }
    }
  }

  show() {

    this.shown = true;
    this.collapse = false;
    this.collapsing = true;

    const body = document.getElementsByTagName('body')[0];
    body.classList.add("pmd-body-open")

    let allDropdown = this._elementRef.nativeElement.querySelectorAll('.pmd-dropdown')
    allDropdown.forEach(function(MobileDevice:any) {
      MobileDevice.classList.add("pmd-dropdown-collapse")
    });

    setTimeout(() => {
      this.renderer.setStyle(this.el.nativeElement, 'height', this.height + 'px');
    }, 10);
    setTimeout(() => {
      this.collapsing = false;
      this.collapse = true;
    }, this.duration);
    this.showClass = true;
  }

  hide() {
    this.shown = false;
    this.collapse = false;
    this.showClass = false;
    this.collapsing = true;
    
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove("pmd-body-open");

    let allDropdown = this._elementRef.nativeElement.querySelectorAll('.pmd-dropdown')
    allDropdown.forEach(function(MobileDevice:any) {
      MobileDevice.classList.remove("pmd-dropdown-collapse");
    });

    setTimeout(() => {
      this.renderer.setStyle(this.el.nativeElement, 'height', '0px');
    }, 10);

    setTimeout(() => {
      this.collapsing = false;
      this.collapse = true;
    }, this.duration);
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    if (!this.collapsing) {
      if (this.shown) {
        this.hide();
      }
    }
  }
}
