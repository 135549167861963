import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { AppGlobals } from '@app/app.global';

@Component({
  selector: 'app-invoice-pagination',
  templateUrl: './invoice-pagination.component.html',
  styleUrls: ['./invoice-pagination.component.scss']
})
export class InvoicePaginationComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() pageSize: number;
  @Input() page: number;
  @Input() count: number;
  @Input() pageName?: string = "Invoice"
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  pageSizes: number[];

  leftArrow = '<i class="material-icons pmd-xs">chevron_left</i>';
  rightArrow = '<i class="material-icons pmd-xs">chevron_right</i>';

  constructor(private appGlobals: AppGlobals) { }

  ngOnInit(): void {
    this.pageSizes = this.appGlobals.pageSizes;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.count?.currentValue !== changes.count?.previousValue) {
      if (this.count <= (this.page - 1) * this.pageSize) {
        this.page = 1;
        this.pageChange.emit(1);
      }
    }
  }

  onPageChange(event) {
    this.pageChange.emit(event.page);
  }

  onPageSizeChange(event) {
    if (this.count <= (this.page - 1) * this.pageSize) {
      this.page = 1;
      this.pageChange.emit(1);
    }

    this.pageSizeChange.emit(event);
  }
}