import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AppGlobals } from '@app/app.global';
import { OEntity } from '../entities';
import { IEntity } from '../interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  constructor(private http: HttpClient, private appGlobals: AppGlobals) { }

  getEntities(): Observable<OEntity[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.metadata}entity/list`).pipe(
      map((entities: IEntity[]) => entities.map((entity: IEntity) => new OEntity(entity)))
    );
  }

  changeEntityStatus(filters: HttpParams | { [params: string]: string | string[] } = {}) {
    return this.http.get<any>(`${this.appGlobals.apiUrl.metadata}entity/change-status`, { params: filters });
  }

  addEntity(entity) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.metadata}entity/add`, entity).pipe(
      map(res => ({ ...res, entity: new OEntity(res.entity) }))
    );
  }

  updateEntity(entity) {
    return this.http.post<any>(`${this.appGlobals.apiUrl.metadata}entity/update`, entity).pipe(
      map(res => ({ ...res, entity: new OEntity(res.entity) }))
    );
  }
}