import { Injectable } from '@angular/core';

@Injectable()
export class PmdAlertConfig {
  /** default alert type */
  type = 'warning';

  /** is alerts are dismiss by default */
  dismiss = false;

  /** default time before alert will dismiss */
  delay?: number = undefined;
}
