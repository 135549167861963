import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { PmdTooltipContainer } from './tooltip-container.component';
import { PmdTooltip } from './tooltip.directive';
import { PmdTooltipConfig } from './tooltip.config';
import { ComponentLoaderFactory } from '../component-loader';
import { PositioningService } from '../positioning';

@NgModule({
    imports: [CommonModule],
    declarations: [PmdTooltip, PmdTooltipContainer],
    exports: [PmdTooltip]
})

export class PmdTooltipModule {
  static forRoot(): ModuleWithProviders<PmdTooltipModule> {
    return {
      ngModule: PmdTooltipModule,
      providers: [PmdTooltipConfig, ComponentLoaderFactory, PositioningService]
    };
  }
}
