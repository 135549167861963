<nav aria-label="breadcrumb"
    class="breadcrumb mb-0">
    <ol class="breadcrumb pmd-breadcrumb">
        <li [class]="'breadcrumb-item'"
            [ngClass]="{'active': isLast}"
            aria-current="page"
            [attr.tabindex]="isLast ? 0 : null"
            *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast;">
            <a [routerLink]="breadcrumb.url"
                *ngIf="!isLast">
                {{ breadcrumb.label }}
            </a>
            <ng-container *ngIf="isLast">{{ breadcrumb.label }}</ng-container>
        </li>
    </ol>
</nav>