  <input 
    #input
    type="checkbox"
    class="form-check-input"
    [ngClass]="{ 
      'filled-in': filledIn || rounded}"
    [id]="id"
    [checked]="state == 'checked'"
    [disabled]="state == 'disabled'"
    [required]="required"
    [indeterminate]="indeterminate"
    [attr.name]="name"
    [attr.value]="value"
    [attr.tabindex]="tabIndex"
    (click)="onCheckboxClick($event)"
    (change)="onCheckboxChange($event)"
    [attr.aria-label]="ariaLabel"
  >
  <label
      class="pmd-checkbox form-check-label"
    [ngClass]="{
      'pmd-checkbox-ripple-effect':pmdClass,
      'label-before': checkboxPosition === 'right', 
      'checkbox-rounded': rounded,
      'disabled': disabled }"
    [attr.for]="id">
    <ng-content></ng-content>
  </label>