/**
* ------------------------------------------------------------------------
* Propeller Input Component
* ------------------------------------------------------------------------
*/

import {
  Component,
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Directive({
  selector: 'pmd-textfield-outline, [pmd-textfield-outline], [pmdTextfieldOutline]',
  exportAs: 'pmdTextfieldOutline',
  host: {
    'class': 'pmd-textfield pmd-textfield-outline'
  }
})

export class PmdTextfieldOutline implements OnInit {

  _element: any;
  _parentElement: any;
  _elementType: any;

  ClassName = {
		PMD_TEXTFIELD: 'pmd-textfield',
		FOCUS: 'pmd-textfield-focused',
		FLOATING_COMPLETE: 'pmd-textfield-floating-label-completed',
		FLOATING_ACTIVE: 'pmd-textfield-floating-label-active',
		PMD_TEXTFIELD_OUTLINE: 'pmd-textfield-outline',
		PMD_TEXTFIELD_FILLED: 'pmd-textfield-filled',
		PMD_TEXTFIELD_OUTLINE_WRAPPER: 'pmd-textfield-outline-wrapper',
		PMD_TEXTFIELD_LABEL_WRAPPER: 'pmd-textfield-label-wrapper',
		PMD_TEXTFIELD_OUTLINE_MIDDLE: 'pmd-textfield-outline-middle',
		PMD_TEXTFIELD_FILLED_WRAPPER: 'pmd-textfield-filled-wrapper'
	};

  Template = {
		OUTLINE_WRAPPER: '<div class="pmd-textfield-outline-wrapper"></div>',
		OUTLINE_LABEL_WRAPPER: '<div class="pmd-textfield-label-wrapper"><div class="pmd-textfield-outline-middle"></div></div>',
		OUTLINE_LEFT_BORDER: '<div class="pmd-textfield-outline-left"></div>',
		OUTLINE_RIGHT_BORDER: '<div class="pmd-textfield-outline-right"></div>',
		FILLED_WRAPPER: '<div class="pmd-textfield-filled-wrapper"></div>',
	};

  label_wrapper = this._renderer.createElement('div');
	outline_middle = this._renderer.createElement('div');
	outline_wrapper = this._renderer.createElement('div');
	outline_wrapper_select = this._renderer.createElement('div');
	filled_wrapper = this._renderer.createElement('div');

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
		this._element = this._elementRef.nativeElement;
		this._elementType = this._element.type;
	}

  ngOnInit() {
    setTimeout(()=>{
      const autofilledInput = document.querySelector('input:-webkit-autofill');
      if(autofilledInput) {
          this._renderer.addClass(this._parentElement, this.ClassName.FLOATING_COMPLETE);
      }
    }, 600);
  }

  ngAfterViewInit(){

		const refChildLabel = this._element.querySelector('label');
		const refChildInput = this._element.querySelector('.form-control');
		const refChildfocused = this._element.querySelector('.pmd-textfield-focused')
		const refChildSelect = this._element.querySelector('.form-select')
		const isInvalid = this._element.querySelector('.is-invalid');
		const isValid = this._element.querySelector('.is-valid');
    
		this._parentElement = this._element.parentElement
        if(refChildfocused){
          this._renderer.removeChild(this._element, refChildfocused);
        }
        if(refChildLabel){
          refChildLabel.parentNode.insertBefore(this.label_wrapper, refChildLabel);
          this.label_wrapper.appendChild(refChildLabel);
          this._renderer.addClass(this.label_wrapper, this.ClassName.PMD_TEXTFIELD_LABEL_WRAPPER);
          refChildLabel.insertAdjacentHTML('beforebegin', this.Template.OUTLINE_LEFT_BORDER);
          refChildLabel.insertAdjacentHTML('afterend', this.Template.OUTLINE_RIGHT_BORDER);
          refChildLabel.parentNode.insertBefore(this.outline_middle, refChildLabel);
          this.outline_middle.appendChild(refChildLabel);
          this._renderer.addClass(this.outline_middle, this.ClassName.PMD_TEXTFIELD_OUTLINE_MIDDLE);
          let labelWrapper = this._element.querySelector('.pmd-textfield-label-wrapper');
          if(refChildInput){
            refChildInput.parentNode.insertBefore(this.outline_wrapper, refChildInput);
            this.outline_wrapper.appendChild(labelWrapper);
            this.outline_wrapper.appendChild(refChildInput);
            this._renderer.addClass(this.outline_wrapper, this.ClassName.PMD_TEXTFIELD_OUTLINE_WRAPPER);
          }
          if(refChildSelect){
            refChildSelect.parentNode.insertBefore(this.outline_wrapper_select, refChildSelect);
            this.outline_wrapper_select.appendChild(labelWrapper);
            this.outline_wrapper_select.appendChild(refChildSelect);
            this._renderer.addClass(this.outline_wrapper_select, this.ClassName.PMD_TEXTFIELD_OUTLINE_WRAPPER);
          }	
        }
        if (isInvalid){
          this._element.classList.add("pmd-textfield-is-invalid");
        }
        if (isValid){
          this._element.classList.add("pmd-textfield-is-valid");
        }
	}

}
