<div class="form-check form-switch pmd-switch">
  <input 
    #input
    class="form-check-input" 
    type="checkbox"
    role="switch"
    [id]="id"
    [checked]="checked"
    [disabled]="disabled"
    [required]="required"
    [indeterminate]="indeterminate"
    [attr.name]="name"
    [attr.value]="value"
    [tabIndex]="tabIndex"
    (click)="onSwitchClick($event)"
    (change)="onSwitchChange($event)"
    [attr.aria-label]="ariaLabel"
  >
  <span class="pmd-switch-off switch-label-caption">No</span>
  <span class="pmd-switch-on switch-label-caption">Yes</span>
  <label class="form-check-label" [for]="id"><ng-content></ng-content></label>
</div>