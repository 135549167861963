import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  isArray(a: any) {
    return Array.isArray(a);
  }

  isObject(o: any) {
    return o === Object(o) && !this.isArray(o) && typeof o !== 'function';
  }

  toCamelCase(s: string) {
    return s.replace(/^[A-Z]/g, $1 => $1.toLowerCase())
      .replace(/(?<=[a-z]+)([\-_\s][a-z])/ig, $1 => $1.toUpperCase().replace('-', '').replace('_', '').replace(' ', ''));
  }

  toSnakeCase(s: string) {
    return s.replace(/^[A-Z]/g, $1 => $1.toLowerCase())
      .replace(/(?<=[a-z])[A-Z]/g, $1 => '_' + $1.toLowerCase());
  }

  toCamelCaseKeys(o: any) {
    if (this.isObject(o)) {
      const n = {};

      Object.keys(o).forEach(k => n[this.toCamelCase(k)] = this.toCamelCaseKeys(o[k]));

      return n;
    }

    if (this.isArray(o)) {
      return o.map((i: any) => this.toCamelCaseKeys(i));
    }

    return o;
  };

  toSnakeCaseKeys(o: any) {
    if (o instanceof HttpParams) {
      if (o.keys().length) {
        let params = new HttpParams();

        o.keys().forEach(param =>
          o.getAll(param).forEach(paramValue =>
            params = params.append(this.toSnakeCase(param), param === 'ordering' ? this.toSnakeCase(paramValue) : paramValue))
        );

        return params;
      }

      return o;
    }
    if (this.isObject(o)) {
      const n = {};

      Object.keys(o).forEach(k => n[this.toSnakeCase(k)] = this.toSnakeCaseKeys(o[k]));

      return n;
    }
    if (this.isArray(o)) {
      return o.map((i: any) => this.toSnakeCaseKeys(i));
    }

    return o;
  };
}