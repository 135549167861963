import { ICompany } from "../interfaces/company.interface";
import { Entity } from "./entity";

export class Company extends Entity<ICompany> {
    get id() {
        return this.props.id;
    }

    get name() {
        return this.props.name;
    }

    get gst() {
        return this.props.gst;
    }

    get pan() {
        return this.props.pan;
    }

    get domain() {
        return this.props.domain;
    }

    get otherDomain() {
        return this.props.otherDomain;
    }

    get outlets() {
        return this.props.outlets ?? [];
    }

    get maxInvoicePageCount() {
        return this.props.maxInvoicePageCount;
    }
}