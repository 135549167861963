// tslint:disable:deprecation
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { PmdTooltipContainer } from './tooltip-container.component';
import { PmdTooltipConfig } from './tooltip.config';
import { ComponentLoader, ComponentLoaderFactory } from '../component-loader';
import { OnChange, warnOnce, parseTriggers } from '../utils';
import { timer, Subscription } from 'rxjs';
import Popper from 'popper.js';
// import { createPopper } from '@popperjs/core';

let id = 0;

@Directive({
  selector: '[pmdTooltip], [tooltipHtml]',
  exportAs: 'pmdTooltip'
})
export class PmdTooltip implements OnInit, OnDestroy {
  tooltipId = id++;
  private popper: Popper;

  @Input() eventsEnabled = true;

  @Input() positionFixed = false;
  // @Input() modifiers: Popper.Modifiers;

  @Input() arrow: boolean = false;

  /**
   * Content to be displayed as tooltip.
   */
  @OnChange()
  @Input()
  pmdTooltip: string | TemplateRef<any>;
  /** Fired when tooltip content changes */
  @Output()
  tooltipChange: EventEmitter<string | TemplateRef<any>> = new EventEmitter();

  /**
   * Placement of a tooltip. Accepts: "top", "bottom", "left", "right"
   */
  // @Input() placement: string;

  @Input() modifiers: Popper.Modifiers;

  @Input() placement: Popper.Placement = 'bottom';

  // @Input() pmdPlacement: Popper.Placement;

  @Input() isPlacement: Popper.Placement;

  @Input() pmdBoundary: Popper.Boundary = "scrollParent";

  // @Input() pmdPlacement: string;

  /**
   * Specifies events that should trigger. Supports a space separated list of
   * event names.
   */
  @Input() triggers: string;
  /**
   * A selector specifying the element the tooltip should be appended to.
   * Currently only supports "body".
   */
  @Input() container: string;
  /**
   * Css class for tooltip container
   */
  @Input() containerClass = '';
  /**
   * Returns whether or not the tooltip is currently being shown
   */

  @Input()
  get isOpen(): boolean {
    return this._tooltip.isShown;
  }

  set isOpen(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }
  }

  /**
   * Allows to disable tooltip
   */
  @Input() disabled: boolean;

  /**
   * Delay before showing the tooltip
   */
  @Input() delay: number;

  /**
   * Emits an event when the tooltip is shown
   */
  @Output() onShown: EventEmitter<any>;

  /**
   * Emits an event when the tooltip is hidden
   */
  @Output() onHidden: EventEmitter<any>;

  /** @deprecated - please use `tooltip` instead */
  @Input('tooltipHtml')
  set htmlContent(value: string | TemplateRef<any>) {
    warnOnce('tooltipHtml was deprecated, please use `tooltip` instead');
    this.pmdTooltip = value;
  }

  /** @deprecated - please use `placement` instead */
  @Input('tooltipPlacement')
  set _placement(value: string) {
    warnOnce('tooltipPlacement was deprecated, please use `placement` instead');
    // this.pmdPlacement = value;
  }

  /** @deprecated - please use `isOpen` instead*/
  @Input('tooltipIsOpen')
  set _isOpen(value: boolean) {
    warnOnce('tooltipIsOpen was deprecated, please use `isOpen` instead');
    this.isOpen = value;
  }

  get _isOpen(): boolean {
    warnOnce('tooltipIsOpen was deprecated, please use `isOpen` instead');
    return this.isOpen;
  }

  /** @deprecated - please use `isDisabled` instead */
  @Input('tooltipEnable')
  set _enable(value: boolean) {
    warnOnce('tooltipEnable was deprecated, please use `isDisabled` instead');
    this.disabled = value;
  }

  get _enable(): boolean {
    warnOnce('tooltipEnable was deprecated, please use `isDisabled` instead');
    return this.disabled;
  }

  /** @deprecated - please use `container="body"` instead */
  @Input('tooltipAppendToBody')
  set _appendToBody(value: boolean) {
    warnOnce(
      'tooltipAppendToBody was deprecated, please use `container="body"` instead'
    );
    this.container = value ? 'body' : this.container;
  }

  get _appendToBody(): boolean {
    warnOnce(
      'tooltipAppendToBody was deprecated, please use `container="body"` instead'
    );
    return this.container === 'body';
  }

  /** @deprecated - removed, will be added to configuration */
  @Input('tooltipAnimation') _animation = true;

  /** @deprecated - will replaced with customClass */
  @Input('tooltipClass')
  set _popupClass(value: string) {
    warnOnce('tooltipClass deprecated');
  }

  /** @deprecated - removed */
  @Input('tooltipContext')
  set _tooltipContext(value: any) {
    warnOnce('tooltipContext deprecated');
  }

  /** @deprecated */
  @Input('tooltipPopupDelay')
  set _tooltipPopupDelay(value: number) {
    warnOnce('tooltipPopupDelay is deprecated, use `delay` instead');
    this.delay = value;
  }

  /** @deprecated */
  @Input('tooltipFadeDuration') _fadeDuration = 150;

  /** @deprecated -  please use `triggers` instead */
  @Input('tooltipTrigger')
  get _tooltipTrigger(): string | string[] {
    warnOnce('tooltipTrigger was deprecated, please use `triggers` instead');
    return this.triggers;
  }

  set _tooltipTrigger(value: string | string[]) {
    warnOnce('tooltipTrigger was deprecated, please use `triggers` instead');
    this.triggers = (value || '').toString();
  }

  /** @deprecated */
  @Output()
  tooltipStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected _delayTimeoutId: number | any;
  protected _tooltipCancelShowFn: Function;

  private _tooltip: ComponentLoader<PmdTooltipContainer>;

  private _delaySubscription: Subscription;
  private _ariaDescribedby: string;

  constructor(_viewContainerRef: ViewContainerRef,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    cis: ComponentLoaderFactory,
    config: PmdTooltipConfig) {
    this._tooltip = cis
      .createLoader<PmdTooltipContainer>(
        this._elementRef,
        _viewContainerRef,
        this._renderer
      )
      .provide({ provide: PmdTooltipConfig, useValue: config });

    Object.assign(this, config);
    this.onShown = this._tooltip.onShown;
    this.onHidden = this._tooltip.onHidden;
  }

  ngOnInit(): void {
    this._tooltip.listen({
      triggers: this.triggers,
      show: () => this.show()
    });
    this.tooltipChange.subscribe((value: any) => {
      if (!value) {
        this._tooltip.hide();
      }
    });
    this.onShown.subscribe(() => {
      this.setAriaDescribedBy();
    });

    this.onHidden.subscribe(() => {
      this.setAriaDescribedBy();
    });
  }

  setAriaDescribedBy(): void {
    this._ariaDescribedby = this.isOpen ? `tooltip-${this.tooltipId}` : null;
    if (this._ariaDescribedby) {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'aria-describedby', this._ariaDescribedby);
    } else {
      this._renderer.removeAttribute(this._elementRef.nativeElement, 'aria-describedby');
    }
  }

  /**
   * Toggles an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  toggle(): void {
    if (this.isOpen) {
      return this.hide();
    }
    this.show();
  }

  /**
   * Opens an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  show(): void {
    if (
      this.isOpen ||
      this.disabled ||
      this._delayTimeoutId ||
      !this.pmdTooltip
    ) {
      return;
    }

    const showTooltip = () => {
      if (this._delayTimeoutId) {
        this._delayTimeoutId = undefined;
      }
      if (this.container) {
        this._tooltip
          .attach(PmdTooltipContainer)
          .to(this.container)
          .show({
            content: this.pmdTooltip,
            placement: this.placement,
            containerClass: this.containerClass,
            arrow: this.arrow,
            id: `tooltip-${this.tooltipId}`
          });
      } else {
        this._tooltip
          .attach(PmdTooltipContainer)
          .show({
            content: this.pmdTooltip,
            placement: this.placement,
            containerClass: this.containerClass,
            arrow: this.arrow
          });
      }
    };

    const cancelDelayedTooltipShowing = () => {
      if (this._tooltipCancelShowFn) {
        this._tooltipCancelShowFn();
      }
    };

    if (this.delay) {

      if (this._delaySubscription) {
        this._delaySubscription.unsubscribe();
      }

      this._delaySubscription = timer(this.delay).subscribe(() => {
        showTooltip();
        cancelDelayedTooltipShowing();
      });

      if (this.triggers) {
        const triggers = parseTriggers(this.triggers);
        this._tooltipCancelShowFn = this._renderer.listen(this._elementRef.nativeElement, triggers[0].close, () => {
          this._delaySubscription.unsubscribe();
          cancelDelayedTooltipShowing();
        });
      }

    } else {
      showTooltip();
    }
    this.create();
  }

  create() {
    this.placement = this.placement;
    var { placement, modifiers } = this;
    let refNode = this._elementRef.nativeElement.parentNode.querySelector('.bs-tooltip-' + placement);
    if (!refNode) {
      refNode = document.body.querySelector('.bs-tooltip-' + placement)
      this.popper = new Popper(
        this._elementRef.nativeElement,
        refNode,
        {
          placement,
          modifiers: {
            preventOverflow: {
              boundariesElement: this.pmdBoundary,
              padding: 20
            },
            arrow: {
              element: `.tooltip-arrow`
            }
          },
          onUpdate: (data) => {
            this._handlePopperPlacementChange(data, refNode);
          },
          onCreate: (data) => {
            if (data.originalPlacement !== data.placement) {
              this._handlePopperPlacementChange(data, refNode);
            }
            this._handlePopperPlacementChange(data, refNode);
          },
        }
      );
    } else {
      this.popper = new Popper(
        this._elementRef.nativeElement,
        refNode,
        {
          placement,
          modifiers: {
            preventOverflow: {
              boundariesElement: this.pmdBoundary,
              padding: 20
            },
            arrow: {
              element: `.tooltip-arrow`
            }
          },
          onUpdate: (data) => {
            this._handlePopperPlacementChange(data, refNode);
          },
          onCreate: (data) => {
            if (data.originalPlacement !== data.placement) {
              this._handlePopperPlacementChange(data, refNode);
            }
            this._handlePopperPlacementChange(data, refNode);
          },
        }
      );
    }
  }

  _handlePopperPlacementChange(data: any, refNode: any) {
    let elem = refNode;
    this.isPlacement = data.placement;
    let popperInstance = data.instance;
    let popperInstanceDiv = popperInstance.popper;
    // console.log(popperInstanceDiv, "popperInstanceDiv")
    if (popperInstanceDiv) {
      let classlist = popperInstanceDiv.classList;
      classlist.forEach((cl: any) => {
        if (cl.indexOf("bs-tooltip") != -1) {
          popperInstanceDiv.classList.remove(cl);
        }
        if (cl.indexOf(data.placement) != -1) {
          popperInstanceDiv.classList.remove(cl);
        }
      });
      popperInstanceDiv.classList.remove('bs-tooltip-' + this.isPlacement);
      if (this.isPlacement == "right") {
        elem.className += ' ' + 'bs-tooltip-end';
      } else if (this.isPlacement == "left") {
        elem.className += ' ' + 'bs-tooltip-start';
      } else {
        elem.className += ' ' + 'bs-tooltip-' + this.isPlacement;
      }
    }
  }

  /**
   * Closes an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  hide(): void {
    if (this._delayTimeoutId) {
      clearTimeout(this._delayTimeoutId);
      this._delayTimeoutId = undefined;
    }

    if (!this._tooltip.isShown) {
      return;
    }

    this._tooltip.instance.classMap.in = false;
    setTimeout(() => {
      this._tooltip.hide();
    }, this._fadeDuration);
  }

  ngOnDestroy(): void {
    this._tooltip.dispose();
    this.popper = null;
    this.tooltipChange.unsubscribe();
    if (this._delaySubscription) {
      this._delaySubscription.unsubscribe();
    }
    this.onShown.unsubscribe();
    this.onHidden.unsubscribe();
  }
}
