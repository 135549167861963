import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  BsDatepickerViewMode,
  BsNavigationDirection,
  DaysCalendarViewModel
} from '../../models';

@Component({
  selector: 'pmd-datepicker-navigation-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pmd-datepicker-controls'
  },
  template: `

    <button class="current" id="current" (click)="view('year')" type="button"  [attr.aria-label]="calendar.monthTitle + ' ' +calendar.yearTitle">
      <span>{{ calendar.monthTitle }} {{ calendar.yearTitle }}</span>
    </button>

    <div class="pmd-datepicker-spacer"></div>

    <button class="previous"
            id="previous"
            aria-label="Previous"
            [disabled]="calendar.disableLeftArrow"
            [style.visibility]="calendar.hideLeftArrow ? 'hidden' : 'visible'"
            type="button"
            (keydown)="keyDownEvent($event, true)"
            (click)="navTo(true)">
      <span></span>
    </button>
    <button class="next"
            id="next"
            aria-label="Next"
            [disabled]="calendar.disableRightArrow"
            [style.visibility]="calendar.hideRightArrow ? 'hidden' : 'visible'"
            type="button"
            (keydown)="keyDownEvent($event, false)"
            (click)="navTo(false)"><span></span>
    </button>
  `
})
export class BsDatepickerNavigationViewComponent {
  @Input() calendar: DaysCalendarViewModel;

  @Output() onNavigate = new EventEmitter<BsNavigationDirection>();
  @Output() onViewMode = new EventEmitter<BsDatepickerViewMode>();

  navTo(down: boolean): void {
    this.onNavigate.emit(
      down ? BsNavigationDirection.DOWN : BsNavigationDirection.UP
    );
    setTimeout(() => {
      let el = document.getElementById(down ? 'previous' : 'next')
      el.focus({ preventScroll:true })
    }, 0)
  }

  view(viewMode: BsDatepickerViewMode): void {
    this.onViewMode.emit(viewMode);
    setTimeout(() => {
      let el = document.getElementById('0-0-grid')
      el.focus({ preventScroll:true })
    }, 0)
  }

  keyDownEvent(event: any, type: any){
    if(event.key == 'Enter' || event.keyCode == 13){
      this.navTo(type)
      event.preventDefault()
    }
  }
}
