<div class="pmd-footer">
   <div class="container-fluid p-0">
      <div class="d-flex align-items-center justify-content-center">
         <div class="pmd-site-info text-md-right">
            <p class=""
               tabindex="0"> &copy; {{currentYear}}
               Invoice
               by <b> Petpooja</b>
            </p>
         </div>
         <ul class="pmd-footer-nav ms-2">
            <li>
               <a href="javascript: void(0);"
                  target="_blank"
                  routerLink="/terms"
                  aria-label="Press enter to open terms of use page">
                  Terms of Use
               </a>
            </li>
            <span>|</span>
            <li>
               <a href="javascript: void(0);"
                  target="_blank"
                  routerLink="/refund-policy"
                  aria-label="Press enter to open Refund Policy page">
                  Refund
               </a>
            </li>
         </ul>
      </div>
   </div>
</div>