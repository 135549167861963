import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppGlobals } from '@app/app.global';
import { environment } from '@env/environment';
import { AuthService, EntityService } from '../services';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(private appGlobals: AppGlobals, private entityService: EntityService, private authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = this.appGlobals.headers;

    if (request.body instanceof FormData) {
      headers = headers.delete('Content-Type');
    }

    if (request.headers.keys().length) {
      request.headers.keys().forEach(
        header => headers = headers.set(header, request.headers.getAll(header))
      );
    }

    if (request.url.startsWith(environment.authentication.url) && environment.authentication?.['xApiKey']) {
      headers = headers.set('x-api-key', environment.authentication['xApiKey']);
    }

    if (request.url.startsWith(environment.dashboard.url) && environment.dashboard?.['xApiKey']) {
      headers = headers.set('x-api-key', environment.dashboard['xApiKey']);
    }

    if (request.url.startsWith(environment.invoice.url) && environment.invoice?.['xApiKey']) {
      headers = headers.set('x-api-key', environment.invoice['xApiKey']);
    }

    if (request.url.startsWith(environment.metadata.url) && environment.metadata?.['xApiKey']) {
      headers = headers.set('x-api-key', environment.metadata['xApiKey']);
    }
    if (this.authService.user?.isOwner && this.entityService.getSelectedEntities()?.length) {
      const entities: any[] = this.entityService.getSelectedEntities().filter((entity) => entity.id !== '0').map((entity) => entity.id);
      if (entities.length) {
        headers = headers.set('x-entity-ids', entities.toString());
      }
    }

    return next.handle(request.clone({ headers }));
  }
}