import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OEntity } from '@app/modules/entity/entities';
import { IEntity } from '@app/modules/entity/interfaces';
import { map, tap } from 'rxjs/operators';
import { AppGlobals } from '@app/app.global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EntityService implements OnInit {
  entitiesSubject: BehaviorSubject<{ id: string, name: string }[]> = new BehaviorSubject<{ id: string, name: string }[]>([]);
  entitiesSubject$ = this.entitiesSubject.asObservable();
  selectedEntities: BehaviorSubject<{ id: string, name: string }[]> = new BehaviorSubject<{ id: string, name: string }[]>([]);
  selectedEntities$ = this.selectedEntities.asObservable();
  entitiesChanges: BehaviorSubject<{ isEntityChanged: boolean }> = new BehaviorSubject<{ isEntityChanged: boolean }>({ isEntityChanged: false });
  entitiesChanges$ = this.entitiesChanges.asObservable();

  private dataSubject = new BehaviorSubject<any[]>([]);
  public companies$: Observable<any[]> = this.dataSubject.asObservable();

  constructor(
    private appGlobals: AppGlobals, private http: HttpClient) { }

  ngOnInit(): void {
    if (localStorage.getItem('entities')) {
      this.entitiesSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('entities')));
    } else {
      this.entitiesSubject = new BehaviorSubject([]);
    }
  }

  setInitialEntities(entities, user) {
    let entityList = [];
    entityList = [
      {
        id: '0',
        name: 'All'
      },
      {
        id: user?.company.id,
        group: 'Parent Entity',
        name: user?.company.name,

      },
      ...(entities.map((entity) => {
        return {
          id: entity.id,
          name: entity.shortName,
          group: 'Child Entity'
        }
      }))
    ];
    this.setEntities(entityList);
    this.setSelectedEntities(entityList);
    return;
  }

  setEntities(entities) {
    localStorage.setItem('entities', JSON.stringify(entities));
    this.entitiesSubject?.next(entities);
  }

  getEntities(): any {
    if (this.entitiesSubject?.value?.length) {
      return this.entitiesSubject.value;
    }

    if (localStorage.getItem('entities')) {
      return JSON.parse(localStorage.getItem('entities'));
    }

    return [];
  }

  setSelectedEntities(entities) {
    localStorage.setItem('selectedEntities', JSON.stringify(entities));
    this.selectedEntities.next(entities);
  }

  getSelectedEntities() {
    if (this.selectedEntities.value.length) {
      return this.selectedEntities.value;
    } else if (localStorage.getItem('selectedEntities')) {
      return JSON.parse(localStorage.getItem('selectedEntities'));
    }
    return [];
  }

  setLastSelectedEntities(entities) {
    localStorage.setItem('lastSelectedEntities', JSON.stringify(entities));
  }

  getLastSelectedEntities() {
    return JSON.parse(localStorage.getItem('lastSelectedEntities'));
  }

  setLastSelectedCompany(company) {
    localStorage.setItem('lastSelectedCompany', JSON.stringify(company));
  }

  getLastSelectedCompany() {
    return JSON.parse(localStorage.getItem('lastSelectedCompany'));
  }

  setEntitiesChanges(entityChange) {
    this.entitiesChanges.next(entityChange);
  }

  fetchEntities(): Observable<OEntity[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.metadata}entity/list`).pipe(
      map((entities: IEntity[]) => entities.map((entity: IEntity) => new OEntity(entity)))
    );
  }

  companyUser(): Observable<any[]> {
    return this.http.get<any>(`${this.appGlobals.apiUrl.metadata}company/user`);
  }

  setCompanies(): Observable<any[]> {
    return this.http.get<any[]>(`${this.appGlobals.apiUrl.metadata}company/user`).pipe(tap((data) => this.dataSubject.next(data)));
  }

  getCompanies(): any[] {
    return this.dataSubject.value;
  }
}
