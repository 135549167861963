import { ChangeDetectionStrategy, Input, Component, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { PmdPopoverConfig } from './popover.config';
import { isBs3 } from '../utils';

@Component({
  selector: 'popover-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]':
      '"popover fade popover-" + placement + " " + "bs-popover-" + placement + " " + placement + " " + containerClass',
    role: 'tooltip',
    '[attr.id]': 'popoverId',
  },
  templateUrl: './popover-container.component.html'
})

export class PmdPopoverContainer implements AfterViewInit {
  @Input() placement: string;
  @Input() title: string;
  containerClass: string;
  popoverId: string;

  get isBs3(): boolean {
    return isBs3();
  }

  constructor(config: PmdPopoverConfig,private _renderer: Renderer2, private _elementRef: ElementRef) {
    Object.assign(this, config);
  }

  ngAfterViewInit(): void {
    setTimeout( () =>{
      this._elementRef.nativeElement.classList.add('show');
    },0)
  }
  
}
