import {
  Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output,
  Renderer2, TemplateRef, ViewContainerRef, Attribute, AfterViewInit, OnChanges, SimpleChanges
} from '@angular/core';
import { PmdPopoverConfig } from './popover.config';
import { ComponentLoader, ComponentLoaderFactory } from '../component-loader';
import { PmdPopoverContainer } from './popover-container.component';
import { timer } from 'rxjs';
import Popper from 'popper.js';

let id = 0;

/**
 * A lightweight, extensible directive for fancy popover creation.
 */
@Directive({
  selector: '[pmdPopover]',
  exportAs: 'pmd-popover'
})

export class PmdPopover implements OnInit, AfterViewInit, OnDestroy {

  private popper: Popper;
  popoverId = id++;
  private myPlacemet: string;
  private myReferenceWidth: boolean;
  private myReferenceHeight: boolean;

  @Input() eventsEnabled = true;
  @Input() positionFixed = false;

  /**
   * Content to be displayed as popover.
   */
  @Input() pmdPopover: string | TemplateRef<any>;
  /**
   * Context to be used if popover is a template.
   */
  @Input() popoverContext: any;
  /**
   * Title of a popover.
   */
  @Input() pmdPopoverTitle: string;
  /**
   * Placement of a popover. Accepts: "top", "bottom", "left", "right"
   */
  @Input() isPlacement: Popper.Placement = "bottom-start";

  @Input() placement: Popper.Placement;

  @Input() modifiers: Popper.Modifiers;

  @Input() pmdPlacement: Popper.Placement;

  @Input() pmdBoundary: Popper.Boundary = "scrollParent";

  /**
   * Close popover on outside click
   */
  @Input() outsideClick = false;
  /**
   * Specifies events that should trigger. Supports a space separated list of
   * event names.
   */
  @Input() triggers: string;
  /**
   * A selector specifying the element the popover should be appended to.
   * Currently only supports "body".
   */
  @Input() container: string;


  /**
   * Css class for popover container
   */
  @Input() containerClass = '';

  /**
   * Returns whether or not the popover is currently being shown
   */
  @Input()
  get isOpen(): boolean {
    return this._popover.isShown;
  }

  set isOpen(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }
  }

  /**
   * Emits an event when the popover is shown
   */
  @Output() onShown: EventEmitter<any>;
  /**
   * Emits an event when the popover is hidden
   */
  @Output() onHidden: EventEmitter<any>;

  private _popover: ComponentLoader<PmdPopoverContainer>;
  private _isInited = false;
  private _ariaDescribedby: string;
  
  constructor(private _elementRef: ElementRef,
    private _renderer: Renderer2,
    _viewContainerRef: ViewContainerRef,
    _config: PmdPopoverConfig,
    cis: ComponentLoaderFactory) {
    this._popover = cis
      .createLoader<PmdPopoverContainer>(
        _elementRef,
        _viewContainerRef,
        _renderer
      )
      .provide({ provide: PmdPopoverConfig, useValue: _config });
    Object.assign(this, _config);
    this.onShown = this._popover.onShown;
    this.onHidden = this._popover.onHidden;

    // fix: no focus on button on Mac OS #1795
    if (typeof window !== 'undefined') {
      _elementRef.nativeElement.addEventListener('click', function () {
        try {
          _elementRef.nativeElement.focus();
        } catch (err) {
          return;
        }
      });
    }
  }

  /**
   * Set attribute aria-describedBy for element directive and
   * set id for the popover
   */
  setAriaDescribedBy(): void {
    this._ariaDescribedby = this.isOpen ? `ngx-popover-${this.popoverId}` : null;
    if (this._ariaDescribedby) {
      this._popover.instance.popoverId = this._ariaDescribedby;
      this._renderer.setAttribute(this._elementRef.nativeElement, 'aria-describedby', this._ariaDescribedby);
    } else {
      this._renderer.removeAttribute(this._elementRef.nativeElement, 'aria-describedby');
    }
  }

  /**
   * Opens an element’s popover. This is considered a “manual” triggering of
   * the popover.
   */
  show(): void {
    if (this._popover.isShown || !this.pmdPopover) {
      return;
    }
    if (this.container) {
      this._popover
        .attach(PmdPopoverContainer)
        .to(this.container)
        .show({
          content: this.pmdPopover,
          context: this.popoverContext,
          placement: this.placement,
          title: this.pmdPopoverTitle,
          containerClass: this.containerClass
        });
    } else {
      this._popover
        .attach(PmdPopoverContainer)
        .show({
          content: this.pmdPopover,
          context: this.popoverContext,
          placement: this.placement,
          title: this.pmdPopoverTitle,
          containerClass: this.containerClass
        });
    }

    
    if (this.popoverContext){
      setTimeout( () => this.create(), 0 )
    } else{
      this.create()
    }
    this.isOpen = true;
    this.setAriaDescribedBy();
  }

  /**
   * Closes an element’s popover. This is considered a “manual” triggering of
   * the popover.
   */
  hide(): void {
    if (this.isOpen) {
      this._popover.hide();
      this.setAriaDescribedBy();
      this.isOpen = false;
    }
  }

  create() {
    this.placement = this.placement;
    var { placement, modifiers } = this;
    let refNode = this._elementRef.nativeElement.parentNode.querySelector('.bs-popover-' + placement);
    if (!refNode) {
      refNode = document.body.querySelector('.bs-popover-' + placement);
      this.popper = new Popper(
        this._elementRef.nativeElement,
        refNode,
        {
          placement,
          modifiers:{
            offset: {
              offset:0
            },
            flip: {
              behavior: 'flip'
            },
            preventOverflow: {
              boundariesElement: this.pmdBoundary,
              padding:20
            },
            arrow: {
              element: `.popover-arrow`
            }
          },
          onCreate: (data) => {
            if (data.originalPlacement !== data.placement) {
              this._handlePopperPlacementChange(data, refNode);
            }
          },
          onUpdate: (data) => {
            this._handlePopperPlacementChange(data, refNode);
          },
        }
      );
    } else {
      this.popper = new Popper(
        this._elementRef.nativeElement,
        refNode,
        {
          placement,
          modifiers:{
            offset: {
              offset:0
            },
            flip: {
              behavior: 'flip'
            },
            preventOverflow: {
              boundariesElement: this.pmdBoundary,
              padding:20
            },
            arrow: {
              element: `.popover-arrow`
            }
          },
          onCreate: (data) => {
            if (data.originalPlacement !== data.placement) {
              this._handlePopperPlacementChange(data, refNode);
            }
          },
          onUpdate: (data) => {
            this._handlePopperPlacementChange(data, refNode);
          },
        }
      );
    }
  }

  _handlePopperPlacementChange(data: any, refNode: any) {
    let elem = refNode;
    this.isPlacement = data.placement;
    let popperInstance = data.instance;
    let popperInstanceDiv = popperInstance.popper;
    if (popperInstanceDiv) {
      let classlist = popperInstanceDiv.classList;
      classlist.forEach((cl: any) => {
        if (cl.indexOf("bs-popover") != -1) {
          popperInstanceDiv.classList.remove(cl);
        }
        if (cl.indexOf(data.placement) != -1) {
          popperInstanceDiv.classList.remove(cl);
        }
      });
      popperInstanceDiv.classList.remove('bs-popover-' + this.isPlacement);
      elem.className += ' ' + 'bs-popover-' + this.isPlacement;
    }
  }

  destroy() {
    if (this.popper) {
      this._popover.dispose();
      this.popper = null;
    }
  }

  /**
   * Toggles an element’s popover. This is considered a “manual” triggering of
   * the popover.
   */
  toggle(): void {
    if (this.isOpen) {
      return this.hide();
    }
    this.show();
  }

  ngOnInit(): any {
    // fix: seems there are an issue with `routerLinkActive`
    // which result in duplicated call ngOnInit without call to ngOnDestroy
    // read more: https://github.com/valor-software/pmd-angular-bootstrap/issues/1885
    if (this._isInited) {
      return;
    }
    this._isInited = true;
    this._popover.listen({
      triggers: this.triggers,
      outsideClick: this.outsideClick,
      show: () => this.show()
    });
  }

  ngAfterViewInit(): any {
    //    this.create();
  }

  ngOnDestroy(): any {
    this.destroy();
  }

  isEmpty(obj: any) {
    if (typeof obj !== undefined) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key))
          return false;
      }
      return true;
    }
    return false;
  }

}

