import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@core/services';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private authService: AuthService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.user.isAdmin;
    }
}