import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import {
  BsDatepickerViewMode,
  BsNavigationDirection,
  BsNavigationEvent,
  CellHoverEvent,
  DatepickerRenderOptions,
  DaysCalendarViewModel,
  DayViewModel, WeekViewModel
} from '../../models';

import { BsDatepickerConfig } from '../../pmd-datepicker.config';

@Component({
  selector: 'pmd-days-calendar-view',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pmd-days-calendar'
  },
  template: `
    <pmd-calendar-layout>
      <pmd-datepicker-navigation-view
        [calendar]="calendar"
        (onNavigate)="navigateTo($event, '0-0-grid')"
        (onViewMode)="changeViewMode($event)"
      ></pmd-datepicker-navigation-view>

      <!--days matrix-->
      <div>
      <table role="grid" class="days weeks">
        <thead>
        <tr>
          <!--if show weeks-->
          <th *ngIf="options.showWeekNumbers"></th>
          <th *ngFor="let weekday of calendar.weekdays; let i = index"
              aria-label="weekday">{{ calendar.weekdays[i] }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let week of calendar.weeks; let i = index">
          <td tabindex="0" class="week" [class.active-week]="isWeekHovered" *ngIf="options.showWeekNumbers" [attr.aria-label]="calendar.weekNumbers[i]" (keydown)="keyDownEvent($event, 'week', week)">
            <span *ngIf="isiOS" (click)="selectWeek(week)">{{ calendar.weekNumbers[i] }}</span>
            <span *ngIf="!isiOS"
                (click)="selectWeek(week)"
                (mouseenter)="weekHoverHandler(week, true)"
                (mouseleave)="weekHoverHandler(week, false)">{{ calendar.weekNumbers[i] }}</span>
          </td>
          <td *ngFor="let day of week.days; let j = index" role="gridcell" tabindex="0" [id]=" i + '-' + j + '-' + 'grid'" [attr.aria-label]="day.date | date : 'longDate'"  (keydown)="keyDownEvent($event, 'day', day)">
          <span *ngIf="!isiOS" bsDatepickerDayDecorator
                [day]="day"
                (click)="selectDay(day)"
                (mouseenter)="hoverDay(day, true)"
                (mouseleave)="hoverDay(day, false)">{{ day.label }}</span>
            <span *ngIf="isiOS" bsDatepickerDayDecorator
                  [day]="day"
                  (click)="selectDay(day)">{{ day.label }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    </pmd-calendar-layout>
  `
})
export class BsDaysCalendarViewComponent  {
  @Input() calendar: DaysCalendarViewModel;
  @Input() options: DatepickerRenderOptions;

  @Output() onNavigate = new EventEmitter<BsNavigationEvent>();
  @Output() onViewMode = new EventEmitter<BsDatepickerViewMode>();

  @Output() onSelect = new EventEmitter<DayViewModel>();
  @Output() onHover = new EventEmitter<CellHoverEvent>();
  @Output() onHoverWeek = new EventEmitter<WeekViewModel>();

  isWeekHovered: boolean;
  isiOS: boolean;

  constructor(private _config: BsDatepickerConfig) {
    this.isiOS = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  navigateTo(event: BsNavigationDirection, elStr: any): void {
    const step = BsNavigationDirection.DOWN === event ? -1 : 1;
    this.onNavigate.emit({ step: { month: step } });
    setTimeout(() => {
      let firstDay = document.getElementById(elStr);
      firstDay.focus({ preventScroll:true });
    }, 0)
  }

  changeViewMode(event: BsDatepickerViewMode): void {
    this.onViewMode.emit(event);
  }

  selectDay(event: DayViewModel): void {
    this.onSelect.emit(event);
  }

  selectWeek(week: WeekViewModel): void {
    if (!this._config.selectWeek && !this._config.selectWeekDateRange) {
      return;
    }

    if (week.days.length === 0) {
      return;
    }

    if (this._config.selectWeek && week.days[0]
        && !week.days[0].isDisabled
        && this._config.selectFromOtherMonth) {

        this.onSelect.emit(week.days[0]);

        return;
    }

    const selectedDay = week.days.find((day: DayViewModel) => {
      return this._config.selectFromOtherMonth
        ? !day.isDisabled
        : !day.isOtherMonth && !day.isDisabled;
    });

    this.onSelect.emit(selectedDay);

    if (this._config.selectWeekDateRange) {
      const days = week.days.slice(0);
      const lastDayOfRange = days.reverse().find((day: DayViewModel) => {
        return this._config.selectFromOtherMonth
          ? !day.isDisabled
          : !day.isOtherMonth && !day.isDisabled;
      });

      this.onSelect.emit(lastDayOfRange);
    }
  }

  weekHoverHandler(cell: WeekViewModel, isHovered: boolean): void {
    if (!this._config.selectWeek && !this._config.selectWeekDateRange) {
      return;
    }

    const hasActiveDays = cell.days.find((day: DayViewModel) => {
      return this._config.selectFromOtherMonth
        ? !day.isDisabled
        : !day.isOtherMonth && !day.isDisabled;
    });

    if (hasActiveDays) {
      cell.isHovered = isHovered;
      this.isWeekHovered = isHovered;
      this.onHoverWeek.emit(cell);
    }
  }

  hoverDay(cell: DayViewModel, isHovered: boolean): void {
    if (this._config.selectFromOtherMonth && cell.isOtherMonth) {
      cell.isOtherMonthHovered = isHovered;
    }

    this.onHover.emit({ cell, isHovered });
  }

  keyDownEvent(event: any, type: any, value: any){
    let maxRow = 5, maxCol = 6;
    if(event.key == 'Enter' || event.keyCode == 13){
      if(type == 'day') this.selectDay(value)
      if(type == 'week') this.selectWeek(value)
    }
    if(event.keyCode == 9){
      // console.log('=========tab pressed=======')
     
      setTimeout(() => {
          let firstElementFocus : any = document.getElementById('current');  
          firstElementFocus.focus({ preventScroll:true });
      }, 0)
    }
    if(document.activeElement.id == maxRow + '-' + maxCol + '-grid' && (event.keyCode == 39)) {
      this.navigateTo(0, '0-0-grid')
    }
    if(document.activeElement.id == '0-0-grid' && (event.keyCode == 37)) {
      this.navigateTo(1, maxRow + '-' + maxCol + '-grid')
    }
    if(document.activeElement.id != maxRow + '-' + maxCol + '-grid' && event.keyCode == 39){
      let row = +document.activeElement.id.split('-')[0]
      let col = +document.activeElement.id.split('-')[1]
      // console.log('========================rightArrow', event, document.activeElement.id, row, col)
      let elStr = ''
      if(col == maxCol){
        elStr = (row + 1) + '-0-grid'
      } else {
        elStr = row + '-' + (col + 1) + '-grid'
      }
      // console.log('====================elStr', elStr)
      let el = document.getElementById(elStr)
      // console.log('========================el', el)
      el.focus({ preventScroll:true })
    }
    if(document.activeElement.id != '0-0-grid' && event.keyCode == 37){
      let row = +document.activeElement.id.split('-')[0]
      let col = +document.activeElement.id.split('-')[1]
      // console.log('========================leftArrow', event, document.activeElement.id, row, col)
      let elStr = ''
      if(col == 0){
        elStr = (row - 1) + '-' + maxCol +'-grid'
      } else {
        elStr = row + '-' + (col - 1) + '-grid'
      }
      // console.log('====================elStr', elStr)
      let el = document.getElementById(elStr)
      // console.log('========================el', el)
      el.focus({ preventScroll:true })
    }
    if(event.keyCode == 40){
      let row = +document.activeElement.id.split('-')[0]
      let col = +document.activeElement.id.split('-')[1]
      // console.log('========================downArrow', event, document.activeElement.id, row, col)
      let elStr = ''
      if(row == maxRow) {
        this.navigateTo(0, 0 + '-' + col + '-grid')
      } else {
        elStr = (row + 1) + '-' + col + '-grid';
        // console.log('====================elStr', elStr)
        let el = document.getElementById(elStr)
        // console.log('========================el', el)
        el.focus({ preventScroll:true })
      }
    }
    if(event.keyCode == 38){
      let row = +document.activeElement.id.split('-')[0]
      let col = +document.activeElement.id.split('-')[1]
      // console.log('========================upArrow', event, document.activeElement.id, row, col)
      let elStr = ''
      if(row == 0) {
        this.navigateTo(1,  maxRow + '-' + col + '-grid' )
      } else {
        elStr = (row - 1) + '-' + col + '-grid';
        // console.log('====================elStr', elStr)
        let el = document.getElementById(elStr)
        // console.log('========================el', el)
        el.focus({ preventScroll:true })
      }
    }
    event.stopPropagation();
    event.preventDefault();
  }
}
