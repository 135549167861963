import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ComponentLoaderFactory } from '../component-loader';
import { PositioningService } from '../positioning';

import { BsDatepickerInputDirective } from './pmd-datepicker-input.directive';
import { BsDatepickerDirective } from './pmd-datepicker.component';
import { BsDatepickerConfig } from './pmd-datepicker.config';
import { BsDaterangepickerInputDirective } from './pmd-daterangepicker-input.directive';
import { BsDaterangepickerDirective } from './pmd-daterangepicker.component';
import { PmdDaterangepickerConfig } from './pmd-daterangepicker.config';

import { BsDatepickerInlineDirective } from './pmd-datepicker-inline.component';
import { PmdDatepickerInlineConfig } from './pmd-datepicker-inline.config';

import { BsLocaleService } from './pmd-locale.service';
import { BsDatepickerActions } from './reducer/pmd-datepicker.actions';
import { BsDatepickerEffects } from './reducer/pmd-datepicker.effects';
import { BsDatepickerStore } from './reducer/pmd-datepicker.store';
import { BsDatepickerContainerComponent } from './themes/bs/pmd-datepicker-container.component';
import { BsDaterangepickerContainerComponent } from './themes/bs/pmd-daterangepicker-container.component';

import { BsDatepickerInlineContainerComponent } from './themes/bs/pmd-datepicker-inline-container.component';
import { BsDaterangepickerInlineContainerComponent } from './themes/bs/pmd-daterangepicker-inline-container.component';

import { BsDaterangepickerInlineDirective } from './pmd-daterangepicker-inline.component';
import { BsDaterangepickerInlineConfig } from './pmd-daterangepicker-inline.config';

import { BsCalendarLayoutComponent } from './themes/bs/pmd-calendar-layout.component';
import { BsCurrentDateViewComponent } from './themes/bs/pmd-current-date-view.component';
import { BsCustomDatesViewComponent } from './themes/bs/pmd-custom-dates-view.component';
import { BsDatepickerDayDecoratorComponent } from './themes/bs/pmd-datepicker-day-decorator.directive';
import { BsDatepickerNavigationViewComponent } from './themes/bs/pmd-datepicker-navigation-view.component';
import { BsDaysCalendarViewComponent } from './themes/bs/pmd-days-calendar-view.component';
import { BsMonthCalendarViewComponent } from './themes/bs/pmd-months-calendar-view.component';
import { BsTimepickerViewComponent } from './themes/bs/pmd-timepicker-view.component';
import { BsYearsCalendarViewComponent } from './themes/bs/pmd-years-calendar-view.component';
import { PmdFooterButtons } from './themes/bs/pmd-footer-buttons';
import { DatePickerFocusTrap } from './datepicker-focus-trap.directive'

@NgModule({
    imports: [CommonModule],
    declarations: [
        BsCalendarLayoutComponent,
        PmdFooterButtons,
        BsCurrentDateViewComponent,
        BsCustomDatesViewComponent,
        BsDatepickerDayDecoratorComponent,
        BsDatepickerNavigationViewComponent,
        BsDaysCalendarViewComponent,
        BsMonthCalendarViewComponent,
        BsTimepickerViewComponent,
        BsYearsCalendarViewComponent,
        BsDatepickerContainerComponent,
        BsDatepickerDirective,
        BsDatepickerInlineContainerComponent,
        BsDatepickerInlineDirective,
        BsDatepickerInputDirective,
        BsDaterangepickerContainerComponent,
        BsDaterangepickerDirective,
        BsDaterangepickerInlineContainerComponent,
        BsDaterangepickerInlineDirective,
        BsDaterangepickerInputDirective,
        DatePickerFocusTrap
    ],
    exports: [
        BsDatepickerContainerComponent,
        BsDatepickerDirective,
        BsDatepickerInlineContainerComponent,
        BsDatepickerInlineDirective,
        BsDatepickerInputDirective,
        BsDaterangepickerContainerComponent,
        BsDaterangepickerDirective,
        BsDaterangepickerInlineContainerComponent,
        BsDaterangepickerInlineDirective,
        BsDaterangepickerInputDirective,
        PmdFooterButtons,
        DatePickerFocusTrap
    ]
})
export class BsDatepickerModule {
  static forRoot(): ModuleWithProviders<BsDatepickerModule> {
    return {
      ngModule: BsDatepickerModule,
      providers: [
        ComponentLoaderFactory,
        PositioningService,
        BsDatepickerStore,
        BsDatepickerActions,
        BsDatepickerConfig,
        PmdDaterangepickerConfig,
        PmdDatepickerInlineConfig,
        BsDaterangepickerInlineConfig,
        BsDatepickerEffects,
        BsLocaleService
      ]
    };
  }
}
