import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';

import { PmdDropdownState } from './dropdown.state';

@Directive({
  selector: '[pmdDropdownToggle],[pmdDropdownToggle]',
  exportAs: 'pmdDropdownToggle',
  host: {
    '[attr.aria-haspopup]': 'true'
  }
})

export class PmdDropdownToggle implements OnDestroy {

  @HostBinding('attr.disabled') isDisabled: boolean = null;

  // @HostBinding('class.active')
  @HostBinding('attr.aria-expanded') isOpen: boolean;

  private _subscriptions: Subscription[] = [];

  constructor(private _state: PmdDropdownState, private _element: ElementRef) {
    // sync is open value with state
    this._subscriptions.push(
      this._state.onChange.subscribe(
        (value: boolean) => (this.isOpen = value)
      )
    );
    // populate disabled state
    this._subscriptions.push(
      this._state.isDisabledChange.subscribe(
        (value: boolean) => (this.isDisabled = value || null)
      )
    );
  }

  @HostListener('click', [])
  onClick(): void {
    this._state.counts = 0;
    if (this.isDisabled) {
      return;
    }
    this._state.toggleClick.emit(true);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any): void {
    if (
      this._state.autoClose &&
      event.button !== 2 &&
      !this._element.nativeElement.contains(event.target)
    ) {
      this._state.toggleClick.emit(false);
    }
  }

  @HostListener('keyup.esc')
  onEsc(): void {
    if (this._state.autoClose) {
      this._state.toggleClick.emit(false);
    }
  }

  ngOnDestroy(): void {
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
  }
  
}
