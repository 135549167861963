// tslint:disable:max-file-line-count
import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  AfterViewInit,
  OnInit,
  OnChanges,
  Output,
  Renderer2,
  ViewContainerRef,
  SimpleChanges,
  HostListener,
  DoCheck
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ComponentLoader, ComponentLoaderFactory, pmdComponentRef } from '../component-loader';

import { PmdDropdownConfig } from './dropdown.config';
import { PmdDropdownContainer } from './dropdown-container.component';
import { PmdDropdownState } from './dropdown.state';
import { PmdDropdownMenu } from './';
import { isBs3 } from '../utils';

import Popper from "popper.js";

@Directive({
  selector: '[pmdDropdown]',
  exportAs: 'pmd-dropdown',
  providers: [PmdDropdownState],
  host: {
	'class': 'pmd-dropdown',
  '[class.dropup]': 'dropup',
  '[class.dropright]': 'dropright',
  '[class.dropleft]': 'dropleft',
  '[class.open]': 'isOpen',
  '[class.show]': 'isOpen && isBs4'
  }
})

export class PmdDropdown implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  private popper: Popper;
	
  private myPlacemet:string;
  private myReferenceWidth:boolean;
  private myReferenceHeight:boolean;

  public collapse = false;
  public showClass = false;
  public collapsing = false;
  private flipPlacemet = true
  private preventOverflow = true;
  private modifiersHide = true;
  
  shown = false;

  public height: number;
	
  @Input() positionFixed = false;
  
  @Input() eventsEnabled = true;
  @Input() target: string | Element;

  @Input() dataSidebar = false;

  @Input() placement: Popper.Placement = "bottom-start";

  @Input() modifiers: Popper.Modifiers;

  @Input() dropup: boolean;

  @Input() dropright: boolean;

  @Input() dropleft: boolean;

  /**
   * Placement of a popover. Accepts: "top", "bottom", "left", "right"
   */
  @Input() pmdPlacement: string;
  /**
   * Specifies events that should trigger. Supports a space separated list of
   * event names.
   */
  @Input() triggers: string;
  /**
   * A selector specifying the element the popover should be appended to.
   * Currently only supports "body".
   */
  @Input() container: string;

  /**
   * This attribute indicates that the dropdown should be opened upwards
   */

  /**
   * Indicates that dropdown will be closed on item or document click,
   * and after pressing ESC
   */
  @Input()
  set autoClose(value: boolean) {
    this._state.autoClose = value;
  }

  get autoClose(): boolean {
    return this._state.autoClose;
  }

  /**
   * Disables dropdown toggle and hides dropdown menu if opened
   */
  @Input()
  set disabled(value: boolean) {
    this._isDisabled = value;
    this._state.isDisabledChange.emit(value);
    if (value) {
      this.hide();
    }
  }

  get disabled(): boolean {
    return this._isDisabled;
  }

  /**
   * Returns whether or not the popover is currently being shown
   */
  @Input()
  get isOpen(): boolean {
    if (this._showInline) {
      return this._isInlineOpen;
    }

    return this._dropdown.isShown;
  }

  set isOpen(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }
  }

  /**
   * Emits an event when isOpen change
   */
  @Output() onChange: EventEmitter<any>;

  /**
   * Emits an event when the popover is shown
   */
  @Output() onShown: EventEmitter<any>;

  /**
   * Emits an event when the popover is hidden
   */
  @Output() onHidden: EventEmitter<any>;

  @Output() updatePos: EventEmitter<any>;

  get isBs4(): boolean {
    return !isBs3();
  }

  // todo: move to component loader
  private _isInlineOpen = false;

  private get _showInline(): boolean {
    return !this.container;
  }

  private _inlinedMenu: EmbeddedViewRef<PmdDropdownMenu>;

  private _isDisabled: boolean;
  private _dropdown: ComponentLoader<PmdDropdownContainer>;
  private _subscriptions: Subscription[] = [];
  private _isInited = false;

  constructor(private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef,
    private _cis: ComponentLoaderFactory,
    private _config: PmdDropdownConfig,
    private _state: PmdDropdownState) {

    // set initial dropdown state from config
    this._state.autoClose = this._config.autoClose;

    // create dropdown component loader
    this._dropdown = this._cis
      .createLoader<PmdDropdownContainer>(
        this._elementRef,
        this._viewContainerRef,
        this._renderer
      )
      .provide({ provide: PmdDropdownState, useValue: this._state });

    this.onShown = this._dropdown.onShown;
    this.onHidden = this._dropdown.onHidden;
    this.onChange = this._state.onChange;
  }

  getDropup(){
    if(this.placement == "top"){
      return true;
    }
    return false;
  }

  ngAfterViewInit() {
      
  }

  ngOnInit(): void {
    if (this._isInited) {
      return;
    }
    this._isInited = true;

    // attach DOM listeners
    this._dropdown.listen({
      // because of dropdown inline mode
      outsideClick: false,
      triggers: this.triggers,
      show: () => this.show()
    });

    // toggle visibility on toggle element click
    this._subscriptions.push(
      this._state.toggleClick.subscribe((value: boolean) => this.toggle(value))
    );

    // hide dropdown if set disabled while opened
    this._subscriptions.push(
      this._state.isDisabledChange
        .pipe(
          filter((value: boolean) => value)
        )
        .subscribe((value: boolean) => this.hide())
    );

    if (!this._inlinedMenu) {
      this._state.dropdownMenu.then(
        (dropdownMenu: pmdComponentRef<PmdDropdownMenu>) => {
          this._dropdown.attachInline(
            dropdownMenu.viewContainer,
            dropdownMenu.templateRef
          );
          this._inlinedMenu = this._dropdown._inlineViewRef;
        }
      ).catch();
    }
    
    if(this.positionFixed){
      this.flipPlacemet = false;  
      this.preventOverflow = false;
      this.modifiersHide = false;
    }
 }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.target && !changes.target.firstChange ||
      changes.placement && !changes.placement.firstChange ||
      changes.positionFixed && !changes.positionFixed.firstChange ||
      changes.eventsEnabled && !changes.eventsEnabled.firstChange
    ) {
      this.create();
    }
  }

  destroy() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  }
	
  createhide() {
    if(this.dropup){
      if(this._elementRef.nativeElement.querySelector('.dropdown-menu-right')){
        this.placement = "top-end";
      } else{
        this.placement = "top-start";
      }
    } else {
      if(this._elementRef.nativeElement.querySelector('.dropdown-menu-right')){
        this.placement = "bottom-end";
      }
    }
    if(this.dropright){
      this.placement = "right-start";
    }
    if(this.dropleft){
      this.placement = "left-start";
    }
    

    if(this.pmdPlacement == "left"){
      this.placement = "left";
    }else if(this.pmdPlacement == "right"){
      this.placement = "right"
    }else if(this.pmdPlacement == "top"){
      this.placement = "top";
    }else if(this.pmdPlacement == "bottom-start"){
      this.placement = "bottom-start";
    }else if(this.pmdPlacement == "bottom-end"){
      this.placement = "bottom-end";
    }else if(this.pmdPlacement == "top-end"){
      this.placement = "top-end";
    }else if(this.pmdPlacement == "top-start"){
      this.placement = "top-start";
    }else if(this.pmdPlacement == "right-end"){
      this.placement = "right-end";
    }else if(this.pmdPlacement == "right-start"){
      this.placement = "right-start";
    }else if(this.pmdPlacement == "left-end"){
      this.placement = "left-end";
    }else if(this.pmdPlacement == "left-start"){
      this.placement = "left-start";
    }
  }
	
  create() {
	 const offsetModifier = (data: any, options: any) => {
		  console.log(data.flipped, data.placement, data.offsets.popper.top);
		  let ReferenceWidth = data.offsets.popper.width + 2;
		  let ReferenceHeight = data.offsets.popper.height + 2;
		  data.styles.clip = 'rect(-2px '+ReferenceWidth+'px '+ReferenceHeight+'px -2px)';
		  return data;
	 };
	  this.createhide();
	  const { placement, modifiers } = this;
	  var dropdownmenu = this._elementRef.nativeElement.querySelector('.dropdown-menu');
	  this.popper = new Popper(
      this._elementRef.nativeElement.querySelector('.dropdown-toggle'),
      this._elementRef.nativeElement.querySelector('.dropdown-menu'),
      {
        placement,
        modifiers:{
          offset: {},
          flip: { 
            enabled: this.flipPlacemet 
          },
          preventOverflow: { 
            enabled: this.preventOverflow
          },
          hide: {
            enabled: this.modifiersHide
          } 
        },	
        onCreate: (data: any) => {
          this.myPlacemet = data.placement;
          this.myReferenceWidth = data.offsets.popper.width + 2;
          this.myReferenceHeight = data.offsets.popper.height + 2;
          let ReferenceWidth = data.offsets.popper.width + 2;
          let ReferenceHeight = data.offsets.popper.height + 2;
          if (this.myPlacemet == "bottom-end"){
            console.log(this.myPlacemet  + "hide");
            this._renderer.setStyle(dropdownmenu, "clip", "rect(0 " + ReferenceWidth + "px 0 " + ReferenceWidth + "px)");
          } else if(this.myPlacemet == "top-start"){
            console.log(this.myPlacemet  + "hide");
            this._renderer.setStyle(dropdownmenu, "clip", "rect(" + ReferenceHeight + "px 0 " + ReferenceHeight + "px 0)");		
          }  else if(this.myPlacemet == "top-end"){
            console.log(this.myPlacemet  + "hide");
            this._renderer.setStyle(dropdownmenu, 'clip', 'rect('+ ReferenceHeight +'px, '+ ReferenceWidth +'px, '+ ReferenceHeight +'px, '+ ReferenceWidth +'px)');		  
          } else if(this.myPlacemet == "left-start"){
            console.log(this.myPlacemet  + "hide");
            this._renderer.setStyle(dropdownmenu, 'clip', 'rect(0 ' + ReferenceWidth + 'px 0 ' + ReferenceWidth + 'px)');		  
          } else {
            console.log(this.myPlacemet  + "hide");
            this._renderer.setStyle(dropdownmenu, "clip", "rect(0 0 0 0)");
          } 
        },
        onUpdate: (data) => {
          let ReferenceWidth = data.offsets.popper.width + 2;
          let ReferenceHeight = data.offsets.popper.height + 2;
          data.styles.clip = 'rect(-2px '+ReferenceWidth+'px '+ReferenceHeight+'px -2px)';
        }
      }
    );
  }

  updatePlacement(): void {
    setTimeout(() => {
      this.popper.scheduleUpdate();
    }, 200)
  }

  /**
   * Opens an element’s popover. This is considered a “manual” triggering of
   * the popover.
   */
  show(): void {

    if (this.isOpen || this.disabled) {
      return;
    }

    if(this._elementRef.nativeElement.classList.contains('pmd-dropdown-collapse')){

      if(this.dataSidebar){
        var dropdownmenu = this._elementRef.nativeElement.querySelector('.dropdown-menu');
        dropdownmenu.classList.remove("collapse");
        this.height = dropdownmenu.scrollHeight + 16;
        dropdownmenu.className += " collapsing";
        setTimeout(() => {
          this._renderer.setStyle(dropdownmenu, 'height', this.height + 'px');
        }, 10);
        setTimeout(() => {
            dropdownmenu.classList.remove("collapsing");
            dropdownmenu.className += " show collapse";
        }, 200);
        this._isInlineOpen = true;
        this.onShown.emit(true);
        this._state.onChange.emit(true);
        return;
      }
    }
    
    var dropdownmenu = this._elementRef.nativeElement.querySelector('.dropdown-menu');  
    this.create();  
    if (this._showInline) {
      this.addBs4Polyfills();
      this._isInlineOpen = true;
      this.onShown.emit(true);
      this._state.onChange.emit(true);
      setTimeout(() => {	
        this._renderer.setStyle(dropdownmenu, 'clip', 'rect(-2px '+ this.myReferenceWidth +'px '+ this.myReferenceHeight +'px -2px)');	
      }, 250);
      setTimeout(() => {	
        this._renderer.setStyle(dropdownmenu, 'clip', 'inherit');	
      }, 600);
      return;
    }
    this._state.dropdownMenu.then(dropdownMenu => {
        this._dropdown
          .attach(PmdDropdownContainer)
          .to(this.container)
          .show({
            content: dropdownMenu.templateRef
          });
        this._state.onChange.emit(true);
    })
    // swallow error
    .catch();

  }

  /**
   * Closes an element’s popover. This is considered a “manual” triggering of
   * the popover.
   */
  hide(): void {
   
    if (!this.isOpen) {
      return;
    }
	  var dropdownmenu = this._elementRef.nativeElement.querySelector('.dropdown-menu');

    if(this._elementRef.nativeElement.classList.contains('pmd-dropdown-collapse')){
      if(this.dataSidebar){
        var dropdownmenu = this._elementRef.nativeElement.querySelector('.dropdown-menu');
        this.height = 0;
        dropdownmenu.classList.remove("show");
        dropdownmenu.classList.remove("collapse");
        dropdownmenu.className += " collapsing";
        setTimeout(() => {
            this._renderer.setStyle(dropdownmenu, 'height', this.height + 'px');
        }, 10);
        setTimeout(() => {
            dropdownmenu.classList.remove("collapsing");
            dropdownmenu.className += " collapse";
        }, 200);
        this._isInlineOpen = false;
        this.onHidden.emit(true);
        return;
      }
    }
	  
    if (this._showInline) {
      this.removeShowClass();
      // this.removeDropupStyles();
      this._isInlineOpen = false;
      this.onHidden.emit(true);
    } else {
      this._dropdown.hide();
	}
	  
	console.log(this.myPlacemet)
	if (this.myPlacemet == "bottom-end"){
		console.log(this.myPlacemet  + "hide");
		this._renderer.setStyle(dropdownmenu, "clip", "rect(0 " + this.myReferenceWidth + "px 0 " + this.myReferenceWidth + "px)");
	} else if(this.myPlacemet == "top-start"){
		console.log(this.myPlacemet  + "hide");
		this._renderer.setStyle(dropdownmenu, "clip", "rect(" + this.myReferenceHeight + "px 0 " + this.myReferenceHeight + "px 0)");		
	} else if(this.myPlacemet == "top-end"){
		console.log(this.myPlacemet  + "hide");
		this._renderer.setStyle(dropdownmenu, 'clip', 'rect('+ this.myReferenceHeight +'px, '+ this.myReferenceWidth +'px, '+ this.myReferenceHeight +'px, '+ this.myReferenceWidth +'px)');		  
	} else {
		console.log(this.myPlacemet  + "hide");
		this._renderer.setStyle(dropdownmenu, "clip", "rect(0 0 0 0)");
	}   
    this._state.onChange.emit(false);
  }

  /**
   * Toggles an element’s popover. This is considered a “manual” triggering of
   * the popover. With parameter <code>true</code> allows toggling, with parameter <code>false</code>
   * only hides opened dropdown. Parameter usage will be removed in pmd-angular-bootstrap v3
   */
  toggle(value?: boolean): void {
    if (this.isOpen || !value) {
      return this.hide();
    }
    return this.show();
  }

  /** @internal */
  // _contains(event: any): boolean {
  //   return this._elementRef.nativeElement.contains(event.target) ||
  //     (this._dropdown.instance && this._dropdown.instance._contains(event.target));
  // }

  @HostListener('keydown.arrowDown', ['$event'])
  @HostListener('keydown.arrowUp', ['$event'])
  navigationClick(event: any): void {
    const ref = this._elementRef.nativeElement.querySelector('.dropdown-menu');
    const allRef = ref.querySelectorAll('.dropdown-item');
    const firtsActive = this._elementRef.nativeElement.ownerDocument.activeElement;
    switch (event.keyCode) {
      case 38:
      console.log(this._state.counts, "this._state.counts");
        if (this._state.counts > 0) {
          allRef[--this._state.counts].focus();
        }
        break;
      case 40:
        if (this._state.counts + 1 < allRef.length) {
          if (firtsActive.classList !== allRef[this._state.counts].classList) {
            allRef[this._state.counts].focus();
          } else {
            allRef[++this._state.counts].focus();
          }
        }
        break;
      default:
    }
    event.preventDefault();
  }

  ngOnDestroy(): void {
    // clean up subscriptions and destroy dropdown
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
    this._dropdown.dispose();
    this.destroy();
  }

  private addBs4Polyfills(): void {
    if (!isBs3()) {
      this.addShowClass();
    }
  }

  private addShowClass(): void {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      this._renderer.addClass(this._inlinedMenu.rootNodes[0], 'show');
    }
  }

  private removeShowClass(): void {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      this._renderer.removeClass(this._inlinedMenu.rootNodes[0], 'show');
    }
  }

  private checkRightAlignment(): void {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      const isRightAligned = this._inlinedMenu.rootNodes[0].classList.contains(
        'dropdown-menu-right'
      );
      this._renderer.setStyle(
        this._inlinedMenu.rootNodes[0],
        'left',
        isRightAligned ? 'auto' : '0'
      );
      this._renderer.setStyle(
        this._inlinedMenu.rootNodes[0],
        'right',
        isRightAligned ? '0' : 'auto'
      );
    }
  }

  private removeDropupStyles(): void {
    if (this._inlinedMenu && this._inlinedMenu.rootNodes[0]) {
      this._renderer.removeStyle(this._inlinedMenu.rootNodes[0], 'top');
      this._renderer.removeStyle(this._inlinedMenu.rootNodes[0], 'transform');
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.hide();
    var dropdownmenu = this._elementRef.nativeElement.querySelector('.dropdown-menu');
    setTimeout(() => {
        this._renderer.removeStyle(dropdownmenu, 'position');
        this._renderer.removeStyle(dropdownmenu, 'will-change');
        this._renderer.removeStyle(dropdownmenu, 'clip');
        this._renderer.removeStyle(dropdownmenu, 'transform');
        this._renderer.removeStyle(dropdownmenu, 'height');
    }, 10);
  }

}
