import {
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  OnInit,
  OnDestroy,
  ElementRef,
  Renderer2,
  AfterViewInit
} from '@angular/core';
import { PmdTabGroup } from './tab-group.component';

/**
 * This directive should be used to wrap tab titles that need to contain HTML markup or other directives.
 */
@Directive({ 
  selector: 'pmd-tab, [pmd-tab]',
  exportAs: 'pmdTab' 
})

export class PmdTab implements OnInit, OnDestroy {

  /** tab header text */
  @Input() label: string;
  /** tab id. The same id with suffix '-link' will be added to the corresponding &lt;li&gt; element  */
  @HostBinding('attr.id')
  @Input() id: string;
  /** if true tab can not be activated */
  @Input() disabled: boolean;
  /** if true tab can be removable, additional button will appear */
  @Input() removable: boolean;
  	
  /** if set, will be added to the tab's class attribute. Multiple classes are supported. */
  @Input()
  get customClass(): string {
    return this._customClass;
  }

  set customClass(customClass: string) {
    if (this.customClass) {
      this.customClass.split(' ').forEach((cssClass: string) => {
        this.renderer.removeClass(this.elementRef.nativeElement, cssClass);
      });
    }
    this._customClass = customClass ? customClass.trim() : null;
    if (this.customClass) {
      this.customClass.split(' ').forEach((cssClass: string) => {
        this.renderer.addClass(this.elementRef.nativeElement, cssClass);
      });
    }
  }

  /** tab active state toggle */
  @HostBinding('class.active')
  @Input()
  get active(): boolean {
    return this._active;
  }

  set active(active: boolean) {
   
    if (this._active === active) {
      return;
    }
    if ((this.disabled && active) || !active) {
      if (this._active && !active) {
        this.deselect.emit(this);
        this._active = active;
        this.tabset.sliderLoad();
        setTimeout(()=>{
            this.childSliderLoad();
        });
      }
      return;
    }
    this._active = active;
    this.select.emit(this);
    this.tabset.tabs.forEach((tab: PmdTab) => {
      if (tab !== this) {
        tab.active = false;
      }
    });
  }

  /** fired when tab became active, $event:Tab equals to selected instance of Tab component */
  @Output() select: EventEmitter<PmdTab> = new EventEmitter();
  /** fired when tab became inactive, $event:Tab equals to deselected instance of Tab component */
  @Output() deselect: EventEmitter<PmdTab> = new EventEmitter();
  /** fired before tab will be removed, $event:Tab equals to instance of removed tab */
  @Output() removed: EventEmitter<PmdTab> = new EventEmitter();

  @HostBinding('class.tab-pane') addClass = true;
  @HostBinding('attr.role') role = 'tabpanel';
  @HostBinding('attr.aria-labelledby') get ariaLabelledby(): string {
    return this.id ? `${this.id}-link` : '';
  }

  headingRef: TemplateRef<any>;
  tabset: PmdTabGroup;
  protected _active: boolean;
  protected _customClass: string;

  constructor(
    tabset: PmdTabGroup,
    public elementRef: ElementRef,
    public renderer: Renderer2
  ) {
    this.tabset = tabset;
    this.tabset.addTab(this);
  }

  childSlider = false;

  childSliderLoad() {
    this.childSlider = true;
    let UL_LI_ACTIVE_Class = this.tabset.elementRef.nativeElement.querySelector('.tab-content .nav-tabs .nav-item.active')
    let NAV_Class = this.tabset.elementRef.nativeElement.querySelector('.tab-content .nav-tabs');
    let slider = this.tabset.elementRef.nativeElement.querySelector('.tab-content .pmd-tab-active-bar');
    let renderer = this.renderer;
    let sliderActive = UL_LI_ACTIVE_Class;
    if(sliderActive){
      setTimeout(()=>{
          let isX = sliderActive.offsetLeft;
          let navX = NAV_Class.offsetLeft;
          let wrapperLeft = slider.parentElement.offsetLeft;
          let sliderLeft = isX - wrapperLeft;
          let finalPossition = wrapperLeft - navX + isX - wrapperLeft;
        if (navX < wrapperLeft) {
          renderer.setStyle(slider, "width", sliderActive.offsetWidth + "px");
          renderer.setStyle(slider, "left", finalPossition + "px");
        } else {
          let ActiveTabWidth = sliderActive.closest('li').offsetWidth;
          renderer.setStyle(slider, "left", sliderLeft + "px");
          renderer.setStyle(slider, "width", ActiveTabWidth + "px");
        }
      })
    }
  }

  ngOnInit(): void {
    this.removable = this.removable;
  }

  ngOnDestroy(): void {
    this.tabset.removeTab(this, { reselect: false, emit: false });
  }
}
