import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { PmdAlert } from './alert.component';
import { PmdAlertConfig } from './alert.config';

@NgModule({
  imports: [CommonModule],
  declarations: [PmdAlert],
  exports: [PmdAlert],
  entryComponents: [PmdAlert]
})

export class PmdAlertModule {
  static forRoot(): ModuleWithProviders<PmdAlertModule> {
    return { ngModule: PmdAlertModule, providers: [PmdAlertConfig] };
  }
}
