import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BsDatepickerViewMode,
  BsNavigationDirection,
  BsNavigationEvent,
  CellHoverEvent,
  MonthsCalendarViewModel,
  CalendarCellViewModel
} from '../../models';

@Component({
  selector: 'pmd-month-calendar-view',
  template: `
    <pmd-calendar-layout>
      <pmd-datepicker-navigation-view
        [calendar]="calendar"
        (onNavigate)="navigateTo($event, '0-0-grid')"
        (onViewMode)="changeViewMode($event)"
      ></pmd-datepicker-navigation-view>
      <div>
      <table role="grid" class="months">
        <tbody>
        <tr *ngFor="let row of calendar.months; let i = index">
          <td *ngFor="let month of row; let j = index" role="gridcell" tabindex="0"  [attr.aria-label]="month.label + ' ' + ( month.date | date : 'y')"
              [id]=" i + '-' + j + '-' + 'grid'"
              (click)="viewMonth(month)"
              (mouseenter)="hoverMonth(month, true)"
              (mouseleave)="hoverMonth(month, false)"
              (keydown)="onKeydownEvent($event, month)"
              [class.disabled]="month.isDisabled"
              [class.is-highlighted]="month.isHovered">
            <span [class.selected]="month.isSelected">{{ month.label }}</span>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
    </pmd-calendar-layout>
  `
})
export class BsMonthCalendarViewComponent {
  @Input() calendar: MonthsCalendarViewModel;

  @Output() onNavigate = new EventEmitter<BsNavigationEvent>();
  @Output() onViewMode = new EventEmitter<BsDatepickerViewMode>();

  @Output() onSelect = new EventEmitter<CalendarCellViewModel>();
  @Output() onHover = new EventEmitter<CellHoverEvent>();

  navigateTo(event: BsNavigationDirection, elStr: any): void {
    const step = BsNavigationDirection.DOWN === event ? -1 : 1;
    this.onNavigate.emit({ step: { year: step } });
    setTimeout(() => {
      let firstDay = document.getElementById(elStr);
      firstDay.focus({ preventScroll:true });
    }, 0)
  }

  viewMonth(month: CalendarCellViewModel) {
    this.onSelect.emit(month);
  }

  hoverMonth(cell: CalendarCellViewModel, isHovered: boolean) {
    this.onHover.emit({ cell, isHovered });
  }

  changeViewMode(event: BsDatepickerViewMode): void {
    this.onViewMode.emit(event);
  }

  onKeydownEvent(event: any, month: any){
    let maxRow = 3, maxCol = 2;
    if(event.key == 'Enter' || event.keyCode == 13){
      this.onSelect.emit(month);
      setTimeout(() => {
        let firstElementFocus: any = document.getElementById('0-0-grid');
        firstElementFocus.focus({ preventScroll:true });
      }, 0)
    }
    if(event.keyCode == 9){
      setTimeout(() => {
        let firstElementFocus: any = document.getElementById('current');
        firstElementFocus.focus({ preventScroll:true });
      }, 0)
    }
    if (document.activeElement.id == maxRow + '-' + maxCol + '-grid' && (event.keyCode == 39)) {
      this.navigateTo(0, '0-0-grid')
    }
    if (document.activeElement.id == '0-0-grid' && (event.keyCode == 37)) {
      this.navigateTo(1, maxRow + '-' + maxCol + '-grid')
    }
    if (document.activeElement.id != maxRow + '-' + maxCol + '-grid' && event.keyCode == 39) {
      let row = +document.activeElement.id.split('-')[0]
      let col = +document.activeElement.id.split('-')[1]
      let elStr = ''
      if (col == maxCol) {
        elStr = (row + 1) + '-0-grid'
      } else {
        elStr = row + '-' + (col + 1) + '-grid'
      }
      let el = document.getElementById(elStr)
      el.focus({ preventScroll:true })
    }
    if (document.activeElement.id != '0-0-grid' && event.keyCode == 37) {
      let row = +document.activeElement.id.split('-')[0]
      let col = +document.activeElement.id.split('-')[1]
      let elStr = ''
      if (col == 0) {
        elStr = (row - 1) + '-' + maxCol +'-grid'
      } else {
        elStr = row + '-' + (col - 1) + '-grid'
      }
      // console.log('===========up, ', elStr)
      let el = document.getElementById(elStr)
      el.focus({ preventScroll:true })
    }
    if (event.keyCode == 40) {
      let row = +document.activeElement.id.split('-')[0]
      let col = +document.activeElement.id.split('-')[1]
      let elStr = ''
      if (row == maxRow) {
        this.navigateTo(0, 0 + '-' + col + '-grid')
      } else {
        elStr = (row + 1) + '-' + col + '-grid';
        let el = document.getElementById(elStr)
        el.focus({ preventScroll:true })
      }
    }
    if (event.keyCode == 38) {
      let row = +document.activeElement.id.split('-')[0]
      let col = +document.activeElement.id.split('-')[1]
      let elStr = ''
      if (row == 0) {
        this.navigateTo(1, maxRow + '-' + col + '-grid')
      } else {
        elStr = (row - 1) + '-' + col + '-grid';
        let el = document.getElementById(elStr)
        el.focus({ preventScroll:true })
      }
    }
    event.stopPropagation();
    event.preventDefault();
  }
}
