import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

import { BlankLayoutComponent, LoginLayoutComponent, HomeLayoutComponent, EmptyLayoutComponent } from '@app/layout';
import { NotFoundComponent } from '@app/shared';
import { AuthGuard, NoAuthGuard, OwnerGuard, AdminGuard, OwnerEntityGuard, LeadAdminProhibitedGuard, LeadAdminGuard, AdminProhibitedGuard } from '@app/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LoginLayoutComponent,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('@modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, LeadAdminProhibitedGuard],
    data: { title: 'Dashboard', breadcrumb: 'Dashboard' },
    loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'invoice',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'Invoice', breadcrumb: 'Invoice List' },
    loadChildren: () => import('@modules/invoice/invoice.module').then(m => m.InvoiceModule)
  },
  {
    path: 'banking',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, OwnerEntityGuard],
    data: { title: 'Banking', breadcrumb: 'Banking' },
    loadChildren: () => import('@modules/banking/banking.module').then(m => m.BankingModule)
  },
  {
    path: 'tag',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'Tags', breadcrumb: 'Tags' },
    loadChildren: () => import('@modules/tag/tag.module').then(m => m.TagModule)
  },
  {
    path: 'tally-config',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'Tally', breadcrumb: 'Invoice purchase Configuration' },
    loadChildren: () => import('@modules/tally/tally.module').then(m => m.TallyModule)
  },
  {
    path: 'payments',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'Payments', breadcrumb: 'Payments List' },
    loadChildren: () => import('@modules/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'compare-invoice',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'Compare Invoices', breadcrumb: 'Compare Invoices' },
    loadChildren: () => import('@modules/compare-invoice/compare-invoice.module').then(m => m.CompareInvoiceModule)
  },
  {
    path: 'entity',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, OwnerGuard],
    data: { title: 'Entity', breadcrumb: 'Entity' },
    loadChildren: () => import('@modules/entity/entity.module').then(m => m.EntityModule)
  },
  {
    path: 'profile',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, OwnerEntityGuard],
    data: { title: 'Profile', breadcrumb: 'Profile' },
    loadChildren: () => import('@modules/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'voucher',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, OwnerEntityGuard],
    data: { title: 'Voucher', breadcrumb: 'Voucher' },
    loadChildren: () => import('@modules/voucher/voucher.module').then(m => m.VoucherModule)
  },
  {
    path: 'petty-expense',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, OwnerEntityGuard],
    data: { title: 'Petty Expense', breadcrumb: 'Petty Expense' },
    loadChildren: () => import('@modules/petty-expense/petty-expense.module').then(m => m.PettyExpenseModule)
  },
  {
    path: 'push-to-petpooja',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'Push To Petpooja', breadcrumb: 'Push To Petpooja' },
    loadChildren: () => import('@modules/push-to-petpooja/push-to-petpooja.module').then(m => m.PushToPetpoojaModule)
  },
  {
    path: 'invoice-review-summary',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, LeadAdminGuard],
    data: { title: 'Invoice Review Summary', breadcrumb: 'Invoice Review Summary' },
    loadChildren: () => import('@modules/invoice-review-summary/invoice-review-summary.module').then(m => m.InvoiceReviewSummaryModule)
  },
  {
    path: 'report',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, AdminProhibitedGuard],
    data: { title: 'Reports', breadcrumb: 'Reports' },
    loadChildren: () => import('@modules/report/report.module').then(m => m.ReportsModule)
  },
  {
    path: 'buyer',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, LeadAdminGuard],
    data: { title: 'Buyer', breadcrumb: 'Buyer' },
    loadChildren: () => import('@modules/buyer/buyer.module').then(m => m.BuyerModule)
  },
  {
    path: 'supplier',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, LeadAdminGuard],
    data: { title: 'Supplier', breadcrumb: 'Supplier' },
    loadChildren: () => import('@modules/supplier/supplier.module').then(m => m.SupplierModule)
  },
  {
    path: '',
    component: BlankLayoutComponent,
    data: { title: 'Terms', breadcrumb: 'terms' },
    loadChildren: () => import('@modules/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: '',
    component: BlankLayoutComponent,
    data: { title: 'Refund Policy', breadcrumb: 'refund-policy' },
    loadChildren: () => import('@modules/refund-policy/refund-policy.module').then(m => m.RefundPolicyModule)
  },
  {
    path: '',
    component: BlankLayoutComponent,
    data: { title: 'Privacy', breadcrumb: 'privacy' },
    loadChildren: () => import('@modules/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  // {
  //   path: '',
  //   component: EmptyLayoutComponent,
  //   data: { title: 'Invoice Image', breadcrumb: 'Invoice' },
  //   loadChildren: () => import('@modules/invoice/invoice.module').then(m => m.InvoiceModule)
  // },
  {
    path: '**',
    data: { title: 'Not Found' },
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: QuicklinkStrategy, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }